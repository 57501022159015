import React, { useEffect, useState } from "react";
import logo from "../Assets/white_cowboy.svg";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useHistory } from 'react-router-dom';
import { auth } from "../firebase"
import default_image from "../Assets/avatar.svg";

import UserSingleton from "../UserSingleton"


import home_icon from "../Assets/home_white.svg"
import play_icon from "../Assets/play_white.svg"
import profile_icon from "../Assets/user_white.svg"
import friends_icon from "../Assets/friends.svg"
import settings_icon from "../Assets/settings_white.svg"
import {
    DesktopNav,
    DesktopNavLogo,
    DesktopNavLink,
    DesktopNavMenu,
    DesktopNavBtn,
    DesktopNavProfile,
    DesktopNavBtnLink,
    NavigationFrame,
    MobileNav,
    MobileNavLogo,
    MobileNavLink,
    MobileNavMenu,
    MobileNavBtn,
    MobileNavBtnLink,
    MobileNavBtnContainer,
    PonyLogo
    
} from "./NavbarElements";





function Navbar(){

    const history = useHistory();
    const [user, setUser] = useState(undefined)
    var [userData, setUserData ]= useState(undefined)

    useEffect(async () => {
        
        if (!user) {
            // maybe trigger a loading screen
            return;
        }
        
        
        
//        if(userData === undefined){
//            
//            let data = await instance.getUser(user.uid)
//            setUserData(data)
//        }
//
//        
//        let imageurl = userData.image
//        console.log("Image URL: ", imageurl)
//        setImage(imageurl)
        
        
        
    }, [user]);

    
    
    const logout = () =>{

        auth.signOut().then(() => {
            // Sign-out successful.
            console.log("Logout Successful")
            history.replace("/");
            
        }).catch((error) => {
            // An error happened.
            console.log(error)
        });
    }
    
    
    
    
    const desktopMenuItems = () => {

        return ( 
            
            <>
                <DesktopNavLink to="/home" activestyle="false">
                    Home
                </DesktopNavLink>
            
                <DesktopNavLink to="/play" activestyle="false">
                   Play Now
                </DesktopNavLink>
            
                 <DesktopNavLink to="/profile" activestyle="false">
                    Profile
                </DesktopNavLink>
            
                 <DesktopNavLink to="/friends" activestyle="false">
                    Friends
                </DesktopNavLink>
                <DesktopNavLink to="/account" activestyle="false">
                    Account
                </DesktopNavLink>
          </>
        )
    }
    
    
    
    const mobileMenuItems = () => {

        return ( 
            
            <MobileNavMenu>
                <MobileNavBtnLink to="/home" activestyle="false">
                    <MobileNavBtnContainer>
                        <img width={30} height={30} src={home_icon}></img>
                    </MobileNavBtnContainer>
                    Home
                </MobileNavBtnLink>
            
                <MobileNavBtnLink to="/play" activestyle="false">
                     <MobileNavBtnContainer>
                        <img width={30} height={30} src={play_icon}></img>
                    </MobileNavBtnContainer>
                    Play
                </MobileNavBtnLink>
            
                 <MobileNavBtnLink  to="/profile"  activestyle="false">
                    <MobileNavBtnContainer>
                        <img width={30} height={30} src={profile_icon}></img>
                    </MobileNavBtnContainer>
                    Profile
                </MobileNavBtnLink>
            
                 <MobileNavBtnLink to="/friends" activestyle="false">
                    <MobileNavBtnContainer >
                        <img  width={30} height={30} src={friends_icon}></img>
                    </MobileNavBtnContainer>
                    Friends
                </MobileNavBtnLink>
            
            
                <MobileNavBtnLink to="/account" activestyle="false">
                    <MobileNavBtnContainer >
                        <img  width={30} height={30} src={settings_icon}></img>
                    </MobileNavBtnContainer>
                    Account
                </MobileNavBtnLink>

            </MobileNavMenu>
        )
    }
    

    const outputDesktopNavbar = () =>{

         return (
             
             <>
                <DesktopNavMenu>
                    {desktopMenuItems()}
                </DesktopNavMenu> 
                 
            </>
        )
    }
    

    return (
        
        <NavigationFrame>
                      
            <DesktopNav>
          
                <DesktopNavLogo to="/">
                    <img className="pony__logo" src={logo}/>         
                    Pony Up
                </DesktopNavLogo>
    
                {outputDesktopNavbar()}
    
            </DesktopNav>

            <MobileNav>
                
                {mobileMenuItems()}
                
            </MobileNav>

        </NavigationFrame>

        
    );
};





export default Navbar;