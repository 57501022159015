import React, { useEffect, useState } from "react";


import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
        
import { auth, db, logout, friendRequestPath, gamesWithTurnTimers, submitChallenge, getFriends, addFriendRequest, removeFriend, declineFriendRequest, acceptFriendRequest, gameUrlPathForType, currentPlayableGameTypes} from "./firebase";
import {where, getDocs, collection, query} from 'firebase/firestore'
import  FriendsList from "./Components/FriendsList"
import  RequestsList from "./Components/RequestsList"
import  ProfileView from "./Components/ProfileView"
import  ChallengeView from "./Components/ChallengeView"
import  FriendRequest from "./Models/FriendRequest"
import SetBetAmountPopup from "./Components/SetBetAmountPopup"
import SetTurnTimerPopup from "./Components/SetTurnTimerPopup"
import ConfirmationPopup from "./Components/ConfirmationPopup"

import SearchBar from "./Components/SearchBar"

import UserModel from "./Models/UserModel"
import UserSingleton from "./UserSingleton"
import "./CSS/Friends.css";
import "./CSS/Theme.css";

function Friends(){

    const [user, loading, error] = useAuthState(auth);
    
    const [friends, setFriends] = useState(undefined)
    const [requests, setRequests] = useState(undefined)
    
    const [viewingProfile, setViewingProfile] = useState(undefined)
    const [viewingProfileRecentGames, setViewingProfileRecentGames] = useState(undefined)
    const [showProfileModal, setShowProfileModal] = useState(false)

    const [userData, setUserData] = useState(undefined)
    const [account, setAccount] = useState(undefined)
    
    const [requestsSelected, setRequestsSelected] = useState(false)

    /**************************************
    *
    * START
    *    --CHALLENGE POPUP STATE VARIABLES
    *************************************/

    
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showChallengeView, setShowChallengeView] = useState(false)
    
    const [confirmationTitle, setConfirmationTitle] = useState("Success")
    const [confirmationBody, setConfirmationBody] = useState("Challenge Sent")
    
    
    
    const [showGetReadyPopup, setShowGetReadyPopup] = useState(false)
    const [getReadyTitle, setGetReadyTitle] = useState("Challenge Sent!")
    const [getReadyBody, setGetReadyBody] = useState("You can play your turn while waiting for your opponent to accept the challenge")
    const [selectedChallenge, setSelectedChallenge] = useState(undefined)
    
    const {getFirestore, query, getDocs, limit, orderBy, collection, doc, where} = require('firebase/firestore')
    
    
    /**************************************
    *
    * END
    *    --CHALLENGE POPUP STATE VARIABLES
    *************************************/
    
    
    
    const history = useHistory();
    const instance = UserSingleton.getInstance()
    
    useEffect(() => {
        if(loading)return;

        if (!user) return history.replace("/welcome");
        
        if(userData === undefined){
            return fetchUserData()
        }
        
        if(friends === undefined){
            return fetchFriends()
        }
        
        if(requests === undefined){
           return fetchRequests()  
        }
        
        async function fetchUserData(){
            if(user !== undefined){
                let data = await instance.getUser(user.uid)
                let accountData = await instance.getAccount(user.uid)
                setAccount(accountData)
                setUserData(data) 
            }
        }
        
        
        async function fetchFriends(){

            let friends = await getFriends(userData)
            setFriends(friends)       
        }
        
        async function fetchRequests(){

            let response = await getAllRequests()
            
            setRequests(response)
            
        }

    }, [user, loading, userData, requests]);


    
    
    
    
    async function getAllRequests(){
        
        console.log("GET REQUESTS")
        const friendRequestRef = collection(db, friendRequestPath);
    
        const requesteeQuery = query(friendRequestRef, where("requestee", '==', userData.uid),where("accepted", '==', false));
        let requesteeSnapshot = await getDocs(requesteeQuery)
        
        
            
        const requestorQuery = query(friendRequestRef, where("requestor", '==', userData.uid),where("accepted", '==', false));
        let requestorSnapshot = await getDocs(requesteeQuery)
        

        let allRequests = []
        
        requesteeSnapshot.forEach(doc =>{
            
            if(doc !== undefined && doc.data() !== undefined){
                allRequests.push(doc.data())
            }
        })   

        requestorSnapshot.forEach(doc =>{

            if(doc !== undefined && doc.data() !== undefined){
                allRequests.push(doc.data())   
            }
        })
        
        
        var tempRequests = []
        for(const req of allRequests){
            
            let requestee = req.requestee
            let requestor = req.requestor

            var uidToGet = ""
            
            if(requestee === userData.uid){
                console.log("REQUESTOR", requestor)
                uidToGet = req.requestor
            }else{
                console.log("REQUESTEE", requestee)
                
                 uidToGet = req.requestee
            }
            
            var user = await instance.getUserDataFor(uidToGet)
            req.user = user
            
            tempRequests.push(req)
        }
        
        console.log("ALL REQUESTS:", tempRequests)

        return tempRequests
    }
        

    
    function replaceOrAppendRequest(new_request){
        
        let tempRequests = new_request
        const reqIndex = tempRequests.findIndex(
            (request) => request.gameId === new_request.gameId
        )
        if(reqIndex === -1){
            //append the game
            tempRequests.push(new_request)
        }else{
            tempRequests[reqIndex] = new_request
        }
        return tempRequests
    }
    
    
    
    async function handleFriendsSelected(){
        setRequestsSelected(false)
        
        let friends = await getFriends(userData)
        console.log("FRIENDS: ", friends)
        
        setFriends(friends)
    }
   
    
    
    async function handleRequestsSelected(){
        
        setRequestsSelected(true)
        let req = await getAllRequests()
        
        setRequests(req)
    }
    
    
    
    
    function inviteAction(){


    }

    
    
        
    const showSelectedProfile = async(handle) =>{
        
        console.log("THIS IS THE SELECTED HANDLE: ", handle)
        let user = await instance.getUserDataFor(handle.uid)
        let recentData = await instance.queryRecentGames(handle.uid, 5)
        
        setViewingProfileRecentGames(recentData)
        setViewingProfile(user)
        setShowProfileModal(true)
    }
    
    
    
    
    
    /**************************************
    *
    * START
    *
    *
    *  --CHALLENGE POPUP CALLBACK METHODS
    *
    *************************************/

    
    //Handles creating challenge and writing it to the database
    const handleSendChallenge = async(challenge) =>{

                
        if(challenge.betAmount < account.balance){
            setConfirmationTitle("Oops")
            setConfirmationBody("You do not have enough funds for this challenge, Add funds from your Settings or Profile")
            
            setShowConfirmation(true)
            return
        }

        let res = await submitChallenge(challenge)
        
        setSelectedChallenge(res.challenge)
        setShowChallengeView(false)
        
        if(!res.success){
            setConfirmationTitle("Oops")
            setConfirmationBody("Something went wrong, try again")
            
            setShowConfirmation(true)
        }

        
        
        let type = res.challenge.gameType
        
        let current = currentPlayableGameTypes()
        
        if(current.includes(Number(type))){
        
            setGetReadyTitle("Success! Ready to start?")

            setGetReadyBody("You can play your round before someone accepts the challenge")
            setShowGetReadyPopup(true)
        
        
        }
    }
    
    
    
        
    const handleGetReadySelected = () =>{
        
        
        setShowGetReadyPopup(false)
        
        if(!selectedChallenge){
           
            setConfirmationTitle("Oops")
            setConfirmationBody("Something went wrong, try again")
            
            setShowConfirmation(true)
            
            return
        }
        
        
        
        let gameId = selectedChallenge.startedGameId
        let type = selectedChallenge.gameType
        let path = gameUrlPathForType(type, gameId)
        
        history.push(path)
        
    
    
    }
    
    
    //handles challenge chosen from the selected users profile
    const handleChallenge = (user)=>{
        setShowProfileModal(false)
        setShowChallengeView(true)
    }  
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    const declineRequest = async(request) =>{
        
        let declined = await declineFriendRequest(request);
        
        
        if(declined){
            const filteredRequests = requests.filter(req => {
                return (req.id != request.id);
            });
            
            setRequests(filteredRequests)
            
            setConfirmationTitle("Success")
            setConfirmationBody("Removed ")
            setShowConfirmation(true)
        
        }else{
            
            setConfirmationTitle("Oops")
            setConfirmationBody("Something went wrong")
            setShowConfirmation(true)
            
        }
    }
    
    
    const acceptRequest = async(request) =>{
        
        let accepted = await acceptFriendRequest(request);
        
        if(accepted){
            
            const filteredRequests = requests.filter(req => {
                return (req.id != request.id);
            });
            
            
            setRequests(filteredRequests)
            
            let newUserData = userData
            let newFriends = newUserData.friends
            
            newFriends.push(request.requestor)
            
            newUserData.friends = newFriends
            
            setUserData(newUserData)
            
            setConfirmationTitle("Success")
            setConfirmationBody("Friend Request Accepted")
            setShowConfirmation(true)
            

        }else{
            
            setConfirmationTitle("Oops")
            setConfirmationBody("Something went wrong")
            setShowConfirmation(true)
            
        }    
    }
    
    
    const showSelectedRequestProfile = async(user) =>{
        
        let recentData = await instance.queryRecentGames(user.uid, 5)
        
        setViewingProfileRecentGames(recentData)
        setViewingProfile(user)
        setShowProfileModal(true)
    }
    
    
    
    /**************************************
    *
    * END
    *
    *
    *    --CHALLENGE POPUP CALLBACK METHODS
    *
    *************************************/
    
    

    const handleRemovingFriend = async (uid) =>{

        let user = await removeFriend(uid, userData)
        setUserData(user)
        
        
        setShowProfileModal(false)
        
        let friends = await getFriends(userData)
        console.log("FRIENDS: ", friends)
        setFriends(friends)
                    

        setConfirmationTitle("Success")
        setConfirmationBody("Friend Removed")
        setShowConfirmation(true)
        
    }
    
    
    const handleSearch = (ss) =>{

        console.log("SEARCHING: ", ss)
        if(friends === undefined){
            return
        }
        console.log("FRIENDS: ", friends)
        
        
        const filteredFriends = friends.filter(friend => {
            return (friend.name.includes(ss) || friend.handle.includes(ss));
        });

        setFriends(filteredFriends)
    }

    

    return (

        <div className="friends">

            <div className="friends__container">
        
                <div className="top__view">
        
                    <div className="title__yellow">Friends</div>
                    <div>
                        <button className="white__background__theme__button" onClick={inviteAction}>Invite</button>
                    </div>
                </div>
    
                        
                <div className="switch__button__container__friends">
                
                    <button className={requestsSelected === false ? "button__selected__friends" : "button__not__selected__friends"} onClick={handleFriendsSelected}>Friends</button>
                    <button className={requestsSelected === true ? "button__selected__friends" : "button__not__selected__friends"} onClick={handleRequestsSelected}>Requests</button>
                                                                                                                                
                </div>
                <div className="search__friends__container">
                
                    {(!requestsSelected && friends !== undefined) ? <SearchBar placeholder={"Search by Name, Handle"} handleChange={(s) => handleSearch(s)}/> : ""}
                </div>
                
                      
                <div className="all__friends__container">
    
                        
                    <div className={(requestsSelected === true && (requests === undefined || requests.length === 0)) ? "empty__list__text" : "hidden"}>No Friend Requests</div>
             
                    {
                        (requestsSelected === true && requests !== [] && requests !== undefined) &&
                        
                        <RequestsList uid={userData !== undefined ? userData.uid : ""} data={requests !== undefined ? requests : []} handleSelected={(user) => showSelectedRequestProfile(user)} handleAccept={(req) => acceptRequest(req)} handleDecline={(req) => declineRequest(req)}/>
                    }
                        

                    <div className={(requestsSelected === false && (friends === undefined || friends.length === 0)) ? "empty__list__text" : "hidden"}>Add friends to see them Here!</div>   

                    {
                        (requestsSelected === false && friends !== [] && friends !== undefined) && 
                        <FriendsList data={friends !== undefined ? friends : []}  handleSelected={(user) => showSelectedProfile(user)}/>
                    }
                        
                        
                        
         </div>

            </div>

                    
            {showProfileModal && 
                <ProfileView 
                    theirProfile={viewingProfile}
                    myProfile={userData}
                    profileRecentGameData={viewingProfileRecentGames}
                    handleChallenge={(user) => handleChallenge(user)}
                    handleRemoveFriend={(uid) => handleRemovingFriend(uid)}
                    handleHidden={() => setShowProfileModal(false)}/>
            }
                    
            {showChallengeView &&
                <ChallengeView 
                    handleHidden={() => setShowChallengeView(false)}
                    myProfile={userData}
                    theirProfile={viewingProfile} 
                    directChallenge={true}
                    allowOpponentToggle={false}
                    showTurnTimer={false}
                    showScrollbox={true}
                    handleChallenge={(challenge) => handleSendChallenge(challenge)}/>
            }
                
            {showConfirmation &&
                <ConfirmationPopup 
                    handleClose={() => setShowConfirmation(false)}
                    positive="Close"
                    title={confirmationTitle}
                    body={confirmationBody}
                />
            }
            {showGetReadyPopup && 
                <ConfirmationPopup
                    handleClose={handleGetReadySelected}
                    positive="I'm Ready!"
                    title={getReadyTitle}
                    body={getReadyBody}/>
            }

        </div>

    );
}



export default Friends;