import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import "./CSS/History.css";
import "./CSS/Theme.css";
import Switch from "./Components/Switch";
import {getFCMToken, archivesPath, auth, db, logout, usernameAvailable, getDocIdAndUpdateUserHandle, allGameTypes, gamesWithTurnTimers, submitChallenge, getFriends, removeFriend, addFriendRequest, gameUrlPathForType, gameUrlArchivePathForType, currentPlayableGameTypes } from "./firebase";

import { DropdownList } from "./Components/DropdownList"
import {Dropdown, Option} from "./Components/Dropdown"
import TextInput from "./Components/TextInput"
import RecentGamesList from "./Components/RecentGamesList"
import UserSingleton from "./UserSingleton"

import FriendSelectView from "./Components/FriendSelectView"

import GameCard from "./Components/GameCard"
import ProfileView from "./Components/ProfileView"
import ChallengeView from "./Components/ChallengeView"

import ConfirmationPopup from "./Components/ConfirmationPopup"
import SetTurnTimerPopup from "./Components/SetTurnTimerPopup"

import styled from "styled-components";





function Home() {
    
    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserData] = useState(undefined);
    const [account, setAccount] = useState(undefined);

    const [showProfileModal, setShowProfileModal] = useState(false);

    const history = useHistory();
                       
    const [viewingProfile, setViewingProfile] = useState(undefined);
    const [viewingProfileRecentGames, setViewingProfileRecentGames] = useState(undefined);
     
    const [recentGames, setRecentGames] = useState(undefined);         
    
    
    

    /**************************************
    *
    * START
    *    --CHALLENGE POPUP STATE VARIABLES
    *************************************/
    
    const [showChallengeModal, setShowChallengeModal] = useState(false);
    const [directUserChallenge, setDirectUserChallenge] = useState(false)
    const [startShowScrollbox, setStartShowScrollbox] = useState(false)
    const [showTurnTimer, setShowTurnTimer] = useState(false)
    const [allowOpponentChange, setAllowOpponentChange] = useState(false)
    const [gameTypeSelected, setGameTypeSelected] = useState(false)
    
    
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [confirmationTitle, setConfirmationTitle] = useState("Success")
    const [confirmationBody, setConfirmationBody] = useState("Challenge Sent")
    
    const [showGetReadyPopup, setShowGetReadyPopup] = useState(false)
    const [getReadyTitle, setGetReadyTitle] = useState("Challenge Sent!")
    const [getReadyBody, setGetReadyBody] = useState("You can play your turn while waiting for your opponent to accept the challenge")
    const [selectedChallenge, setSelectedChallenge] = useState(undefined)
    
    const {query, limit, collection, onSnapshot, orderBy, getDocs, where} = require('firebase/firestore')
    
    /**************************************
    *
    * END
    *    --CHALLENGE POPUP STATE VARIABLES
    *************************************/
    
    const instance = UserSingleton.getInstance()
  
    
    
    useEffect(() => {

        if(loading)return;
        if (!user) return history.replace("/welcome");
        
        
        if (userData === undefined){
            console.log("Fetching User Data")
            return fetchMyUserData()   
        }
        
        if(recentGames === undefined){

            return fetchRecentGameData()
        }
        

        
        async function fetchMyUserData(){
            
            let data = await instance.getUser(user.uid)
            let accountData = await instance.getAccount(user.uid)
            let token = await getFCMToken()
            
            if(token === 'request'){
                Notification.requestPermission()
            }else if(token !== 'error'){

                instance.updateToken(token)
            }
            
            
            setAccount(accountData)
            setUserData(data)
        }
        
        
        async function fetchRecentGameData(){

            if (userData !== undefined) {


                let games = await startQuery()
                setRecentGames(games)
            } 
        }
        


    }, [user, loading, userData, recentGames]);
  
    



    
    
    
    /*
        LOAD Games
    */
    const startQuery = async () => {
        
        
        const recentGamesRef = collection(db, archivesPath);
    
        const recentQuery = query(recentGamesRef, where('players', 'array-contains', userData.uid) ,orderBy('gameOverTimestamp', 'desc'), limit(50));
    
        
        let querySnapshot = await getDocs(recentQuery)
            
        

        var recentGames = []
        
        querySnapshot.forEach(doc => {
                
            let data = doc.data()
            let id = doc.id

                
            if(userData !== undefined && userData.friends !== undefined && (userData.friends.includes(data.challengerUid) || userData.friends.includes(data.opponentUid))){

                recentGames.push(data)   
            }

            
        }, err => {
            console.log(`Recent Games Query: ${err}`);
        });

        return recentGames
        
    }

    
    
    

    
    const handleFriendRequest = async(uid)=>{
        
        await addFriendRequest(uid, userData)
        
        setShowProfileModal(false)
        
        setConfirmationTitle("Success")
        setConfirmationBody("Friend Request Sent")
        setShowConfirmation(true)
    }
    
    
    
    const handleRemovingFriend = async(uid)=>{

        let user = await removeFriend(uid, userData)
        setUserData(user)
        
        setShowProfileModal(false)
        
        setConfirmationTitle("Success")
        setConfirmationBody("Friend Removed")
        setShowConfirmation(true)
    }
    
    
    
    //Handles showing the Profile popup for the selected user
    const profileSelected = async(uid) =>{
        
        let user = await instance.getUserDataFor(uid)
        let recentData = await instance.queryRecentGames(uid, 5)

        setViewingProfileRecentGames(recentData)
        setViewingProfile(user)
        
        setShowProfileModal(true)
    }
    
    
    
    
    
    
    
    
    
    

    /**************************************
    *
    *START
    *
    * 
    *    --CHALLENGE POPUP CALLBACKS
    *************************************/

    //handles challenge chosen from the selected users profile
    const handleChallenge = (user)=>{
        setShowProfileModal(false)
        setDirectUserChallenge(true)
        setStartShowScrollbox(true)
        setAllowOpponentChange(false)
        setShowTurnTimer(false)
        setShowChallengeModal(true)  
    
    }
        
    const handleRematch = (user)=>{
        setShowProfileModal(false)
        setDirectUserChallenge(true)
        setStartShowScrollbox(false)
        setAllowOpponentChange(false)
        setShowTurnTimer(false)
        setShowChallengeModal(true)  
    
    }
    
    //Handles game selected from scrollbox
    const gameSelected = (type) => { 
        
        console.log(type)
        
        if(type !== '7'){
            setConfirmationBody("This game is not yet available")
            setConfirmationTitle("Coming Soon!")
            setShowConfirmation(true)
            return
        }
        

        setDirectUserChallenge(false)
        setGameTypeSelected(type)
        setAllowOpponentChange(true)     
        setStartShowScrollbox(false)
        
        let turnTimerGames = gamesWithTurnTimers()
        
        setShowTurnTimer(turnTimerGames.includes(type))
        
        
        
        setShowChallengeModal(true)
        
    }
    

        
    //Handles creating challenge and writing it to the database
    const handleSendChallenge = async(challenge) =>{


        if(challenge.betAmount < account.balance){
            setConfirmationTitle("Oops")
            setConfirmationBody("You do not have enough funds for this challenge, Add funds from your Settings or Profile")
            
            setShowConfirmation(true)
            return
        }

        let res = await submitChallenge(challenge)
        
        setSelectedChallenge(res.challenge)
        setShowChallengeModal(false)
        
        if(!res.success){
            setConfirmationTitle("Oops")
            setConfirmationBody("Something went wrong, try again")
            
            setShowConfirmation(true)
        }

        
        
        let type = res.challenge.gameType
        
        console.log(res)
        
        let current = currentPlayableGameTypes()
        
        if(current.includes(Number(type))){
        
            setGetReadyTitle("Success! Ready to start?")

            setGetReadyBody("You can play your round before someone accepts the challenge")
            setShowGetReadyPopup(true)
        
        
        }
        
    }
    
    
    

    
    
    const handleGetReadySelected = () =>{
        
        
        setShowGetReadyPopup(false)
        
        if(!selectedChallenge){
           
            setConfirmationTitle("Oops")
            setConfirmationBody("Something went wrong, try again")
            
            setShowConfirmation(true)
            
            return
        }
        
        
        
        let gameId = selectedChallenge.startedGameId
        let type = selectedChallenge.gameType
        
        let path = gameUrlPathForType(type, gameId)
        history.push(path)
        
    
    }
    

    
    
    const cellSelected = (game) =>{

        
        let type = game.type
        let gameId = game.gameId
        
        console.log("Cell Selected: ", game)
        let path = gameUrlArchivePathForType(type, gameId)
        history.push(path)

    }
    
    
    
    

    /**************************************
    *
    *END
    *
    * 
    *    --CHALLENGE POPUP CALLBACKS
    *************************************/

  return (
    <div className="history">
                                 
      <div className="history__container">
      
        <h1 className="title__yellow">History</h1>   


        <div className="history__recent__games__container">

             <div className={(recentGames === undefined || recentGames === []) ? "empty__list__text" : "hidden"}>No Recent Games</div>

            {(recentGames !== undefined && recentGames !== []) &&
             <RecentGamesList handleProfileSelected={(uid) => profileSelected(uid)} data={recentGames} handleCellSelected={(game) => cellSelected(game)} />}
            
            
        </div>

      </div>
             

        {showChallengeModal &&
            <ChallengeView 
                handleHidden={() => setShowChallengeModal(false)}
                myProfile={userData}
                theirProfile={viewingProfile}
                gameType={gameTypeSelected}
                showScrollbox={startShowScrollbox}
                showTurnTimer={showTurnTimer}
                directChallenge={directUserChallenge}
                allowOpponentToggle={setAllowOpponentChange}
                handleChallenge={(challenge) => handleSendChallenge(challenge)} />
        }
                
    
            
        {showProfileModal &&
       <ProfileView 
            hideChallengeButton={false}
            hideFriendRequestButton={false}
            myProfile={userData}
            theirProfile={viewingProfile} 
            profileRecentGameData={viewingProfileRecentGames}
            handleChallenge={(user) => handleChallenge(user)}
            handleRequest={(uid) => handleFriendRequest(uid)}
            handleRemoveFriend={(uid) => handleRemovingFriend(uid)}
            handleHidden={() => setShowProfileModal(false)}/>
        }
            
        
        {showConfirmation && 
         <ConfirmationPopup 
            handleClose={() => setShowConfirmation(false)}
            positive="Close"
            title={confirmationTitle}
            body={confirmationBody}/>
        }
        {showGetReadyPopup && 
         <ConfirmationPopup
            handleClose={handleGetReadySelected}
            positive="I'm Ready!"
            title={getReadyTitle}
            body={getReadyBody}/>
        }          
    </div>
  );
}

export default Home;
