import styled from "styled-components";
import Modal from 'react-overlays/Modal';
import React,{ Component} from 'react'

import "../CSS/Theme.css"
import "../CSS/ChallengePopupView.css"
import user_image from "../Assets/avatar.svg";
import Bet from "../Models/Bet"
//import React, { useEffect, useState } from "react";
import ScrollBox from "./ScrollBox"
import GAMETYPES from "../Models/game__types.json"

import Switch from "./Switch"
import ConfirmationPopup from "./ConfirmationPopup"
import SetTurnTimerPopup from "./SetTurnTimerPopup"
import SetBetAmountPopup from "./SetBetAmountPopup"
import FriendSelectView from "./FriendSelectView"

import GameCard from "./GameCard"
import Chess from '../Assets/chess.png'
import Checkers from '../Assets/checkers.png'
import Trivia from '../Assets/trivia.png'
import WordUp from '../Assets/wordup.png'
import Match4 from '../Assets/connect4.png'
import Snowman from '../Assets/snowman.png'
import XsOs from '../Assets/tictactoe.png'
import {gamesWithTurnTimers, getFriends, acceptedStates, currentPlayableGameTypes} from '../firebase'
import UserSingleton from '../UserSingleton'
import Loading from './Loading'
const axios = require('axios')

const ChallengeBackdrop = styled("div")`
            position: fixed;
            z-index: 1040;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000;
            opacity: 0.5;
        `;


const ChallengeViewModal = styled(Modal)`
                    position: fixed;
                    width: 40vw;
                    max-height:90%;
                    z-index: 1040;
                    top: ${() => 5}%;
                    left: ${() => 30}%;
                    border-radius: 15px;
                    background-color: var(--main);
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
                    padding-top:10px;
                    padding-bottom:30px;
                    padding-left:2.5vw;
                    padding-right:2.5vw;
                    overflow-y:auto;

                    @media only screen and (max-width: 768px) {
                        position: fixed;
                        width: 90vw;
                        max-height:99%;

                        z-index: 1040;
                        top: ${() => 1}%;
                        left: 2.5vw;
                        padding-top:20px;
                        padding-bottom:20px;
                        border-radius: 15px;
                        background-color: var(--main);
                        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
                        overflow-y:auto;

                }
            `;
const renderBackdrop = (things) => <ChallengeBackdrop {...things} />;


export default class ChallengeView extends Component {

    constructor(props){
        super(props)

        this.handleHidden = props.handleHidden
        this.handleChallenge = props.handleChallenge


        this.instance = UserSingleton.getInstance()

        this.state = {
            directChallenge: props.directChallenge,
            theirProfile:props.theirProfile,
            myProfile:props.myProfile ,
            showScrollbox:props.showScrollbox,
            showTurnTimer:props.showTurnTimer,
            betAmount:1,
            turnTimer:60,
            gameType:props.gameType,
            allowOpponentToggle:props.allowOpponentToggle,
            friends:[],
            showFriendSelectionModal:false,
            showTurnTimerPopup:false,
            showBetAmountPopup:false,
            showConfirmation:false,
            confirmationTitle:'Success',
            confirmationBody:'Success',
            sending:false,
            locationGranted:false,
            stateAvailable:false,
            region:""
        }
    }


    async getLocation(){

      console.log("THIS")
      const res = await axios.get('https://geolocation-db.com/json/')
      console.log(res.data);

      var state = res.data.state
      var ipAddress = res.data.IPv4

      if(res.data == undefined || res.data == null){
          return ""
      }

      if(state === null){
        var url = 'https://ipapi.co/' + ipAddress + '/json/'
        const new_res = await axios.get(url)
        let data = new_res.data
        state = data.region
        console.log(data.region)
      }

      return state

    }

    componentDidMount() {

      const locationAllowed = async() =>{


        let state = await this.getLocation()

        console.log("State: ", state)

        if(!acceptedStates.includes(state)){

            let text = "Local policy doesn't allow Pony Up to support money challenges in the state of " + state + "."

            this.setState({confirmationTitle:"Location Permissions", confirmationBody:text, confirmationButton:"Close", showConfirmation:true, locationGranted:false, stateAvailable:false})

        }else{

          this.setState({locationGranted:true, region:state, stateAvailable:true})
        }
      }

      const locationDisallowed = (test) =>{
        let text = "You will not be able to play money challenges with location disabled. Enable location from settings to do so."

        this.setState({confirmationTitle:"Location Permissions", confirmationBody:text, confirmationButton:"Close", showConfirmation:true, locationGranted:false,region:"", stateAvailable:false})
      }

      navigator.geolocation.getCurrentPosition(locationAllowed, locationDisallowed);
    }


    render(){


        function imageForType(type){

            var image;
            switch (type) {
                case "0":
                    image =  "None"
                    break
                case "1":
                    image =  Snowman
                    break
                case "2":
                    image =  XsOs
                    break
                case "3":
                    image =  Match4
                    break
                case "4":
                    image =  WordUp
                    break
                case "5":
                    image =  Checkers
                    break
                case "6":
                    image =  Chess
                    break
                case "7":
                    image =  Trivia
                    break
                default:
                    image =  "None"
                    break
            }
            return image
        }

        function nameForType(type){

            var image;
            switch (type) {
                case "0":
                    image =  "None"
                    break

                case "1":
                    image =  "Snowman"
                    break

                case "2":
                    image =  "X's O's"
                    break

                case "3":
                    image =  "Match 4"
                    break

                case "4":
                    image = "Word Up"
                    break

                case "5":
                    image =  "Checkers"
                    break

                case "6":
                    image =  "Chess"
                    break

                case "7":
                    image =  "Trivia"
                    break

                default:
                    image =  "None"
                    break
            }

            return image

          }






        //Handles hiding/showing views and setting the new game type when a new game is selected in the challenge popup
        const challengeGameSelected = (type) =>{

            let gamesWithTimer = gamesWithTurnTimers()

            var updates = {}

            if(gamesWithTimer.includes(type)){

                updates.showTurnTimer = true
            }else{
                updates.showTurnTimer = false
            }

            // Allowed Games So far
            //
            // 1 - Snowman
            // 2 - Xs Os
            // 3 - Match 4
            // 4 - Word Up
            // 5 - Checkers
            // 6 - Chess
            // 7 - Trivia

            let current = currentPlayableGameTypes()

            if(current.includes(Number(type))){
                updates.gameType = type
                updates.showScrollbox = false
                this.setState(updates)

            }

        }



        //Handles hiding/showing views when change game is selected in the challenge popup
        const changeChallengeGameType = (type) =>{

            this.setState({gameType:undefined, showScrollbox:true, showTurnTimer:false})
        }



        //Handles switching between direct or random opponent challenge
        const handleSelectFriend = async() =>{

            var updates = {}
            updates.theirProfile = undefined
            updates.showFriendSelectionModal = true

            const users = await getFriends(this.state.myProfile)
            updates.friends = users

            console.log(updates)
            this.setState(updates)
        }




        const removeSelectedFriend = () => {

            var updates = {}

            updates.theirProfile = undefined
            updates.showFriendSelectionModal = false
            updates.directChallenge = false
            this.setState(updates)
        }

        const opponentSelected = (handle) =>{

            this.setState({theirProfile:handle, showFriendSelectionModal:false, directChallenge:true})

        }






        //Handles Update pressed on the Update Bet Amount Popup

        const updateBetAmount = (a) =>{

            let amount = a.replace(/,/g, '');

            console.log('Amount: ', amount)
            if(isNaN(amount) || (amount > 0 && amount < 1)){

                this.setState({confirmationTitle:"Oops",confirmationBody:"Please enter a valid number greater than $1",showBetAmountPopup:false,showConfirmation:true})

                return
            }

            if(amount > 9999){


                this.setState({confirmationTitle:"Oops",confirmationBody:"We do not allow bets greater than $9,999",showBetAmountPopup:false,showConfirmation:true})
                return

            }


            if(amount === undefined){

                this.setState({showBetAmountPopup:false})
                return
            }

            this.setState({showBetAmountPopup:false, betAmount:amount})

        }


        //Handles Update pressed on the Update Turn Timer Popup
        const updateTurnTimer = (time) =>{

             if(isNaN(time) || time > 3600 || time < 1){

                this.setState({confirmationTitle:"Oops", confirmationBody:"Please enter a valid number between 1 and  3600 minutes (1 day)",showTurnTimerPopup:false,showConfirmation:true})

                return
            }


            if(time === undefined){
                this.setState({showTurnTimerPopup:false})

                return
            }


            this.setState({showTurnTimerPopup:false, turnTimer: time})

        }




        var message = '';
        var setMessage = (m) => {
            message = m
        }



        var get24HoursFromNow = () =>{

            var tomorrow = new Date()
            // add 1 day to today
            tomorrow.setDate(new Date().getDate() + 1)

            return tomorrow
        }

        const showTurnTimerPopup = () =>{
            this.setState({showTurnTimerPopup:true})

        }

        const showBetPopup = () =>{
            this.setState({showBetAmountPopup:true})
        }

        const prepareAndSendChallenge = async() =>{

            let account = this.instance.getAccount(this.state.myProfile.uid)

            var bet = new Bet(undefined)
            this.setState({sending:true})

            if(this.state.gameType === undefined || this.state.gameType === 0 || this.state.showScrollbox){

                this.setState({sending:false, confirmationTitle:"Oops",confirmationBody:"Choose a game type",showConfirmation:true})

                 return
            }

            var noWager = true
            if(this.state.betAmount > 0){
                noWager = false
            }
            let timer = this.state.turnTimer * 60

            bet.noWager = noWager
            bet.amount = this.state.betAmount


            console.log("Granted: ", this.state.locationGranted, " region: ", this.state.region, " State Available: ", this.state.stateAvailable)

            if(this.state.locationGranted === false && this.state.region === "" && !noWager){

              await navigator.geolocation.getCurrentPosition(locationAllowed, locationDisallowed);

              return
            }else if(!noWager && !this.state.stateAvailable){

              let text = "Local policy doesn't allow Pony Up to support money challenges in the state of " + this.state.region + "."

              this.setState({sending:false,confirmationTitle:"Location Denied", confirmationBody:text, confirmationButton:"Close", showConfirmation:true, locationGranted:false, stateAvailable:true})
              return
            }


            if(this.state.betAmount > account.balance ){
                this.setState({sending:false,confirmationTitle:"Oops", confirmationBody:"You don't have enough money to accept this challenge", confirmationButton:"Close", showConfirmation:true})
                return

            }


            if(this.state.directChallenge){
                bet.randomOpponent=false
                bet.opponentUid=this.state.theirProfile.uid
                bet.opponentHandle=this.state.theirProfile.handle
                bet.opponentImage=this.state.theirProfile.image
            }else{
                bet.randomOpponent=true
            }

            bet.accepted=false
            bet.challengerUid= this.state.myProfile.uid

            //convert to number for data storage
            bet.gameType=Number(this.state.gameType)
            bet.expires=get24HoursFromNow()
            bet.challengerHandle=this.state.myProfile.handle
            bet.challengerImage=this.state.myProfile.image
            bet.customMessage=message
            bet.shotClock=timer

            this.handleChallenge(bet)
        }



        const hideChallengeView = () =>{

            this.handleHidden()
        }





        const hideConfirmation = () => {this.setState({showConfirmation:false})}
        const hideTurnTimerPopup = () => {this.setState({showTurnTimerPopup:false})}
        const hideBetAmountPopup = () => {this.setState({showBetAmountPopup:false})}
        const hideFriendSelectPopup = () => {this.setState({showFriendSelectionModal:false})}




        const getLocation = async () => {

              const res = await axios.get('https://geolocation-db.com/json/')
              console.log(res.data);

              var state = res.data.state
              var ipAddress = res.data.IPv4

              if(res.data == undefined || res.data == null){
                  return ""
                }

              if(state === null){
                var url = 'https://ipapi.co/' + ipAddress + '/json/'
                const new_res = await axios.get(url)
                let data = new_res.data
                state = data.region
                console.log(data.region)
              }

              return state
        }



        const locationAllowed = async() =>{

          let state = await getLocation()

          console.log("State: ", state)

          if(!acceptedStates.includes(state) && this.state.betAmount > 0){

              let text = "Local policy doesn't allow Pony Up to support money challenges in the state of " + state + "."

              this.setState({sending:false,confirmationTitle:"Location Permissions", confirmationBody:text, confirmationButton:"Close", showConfirmation:true, locationGranted:false, region:state, stateAvailable:false})

          }else{


            let text = "Resubmit your challenge to continue."

            this.setState({sending:false,confirmationTitle:"Success", confirmationBody:text, confirmationButton:"Close", showConfirmation:true, locationGranted:true, region:state, stateAvailable:true })
          }
        }


        const locationDisallowed = (test) =>{
          console.log("DISALLOWED: ", test)

          this.setState({sending:false,confirmationTitle:"Location Permissions", confirmationBody:"You must enable Location to send money challenges.", confirmationButton:"Close", showConfirmation:true, locationGranted:false, region:"", stateAvailable:false})

        }

        return (
                <div>
                <ChallengeViewModal
                    show={true}
                    onHide={hideChallengeView}
                    renderBackdrop={renderBackdrop}
                    aria-labelledby="modal-label"
                    >

                    <div className="center__challenge__content">

                        <div className="top__close__challenge__view">
                            <div className="bold__gray__large__text">Challenge</div>
                            <button className="white__background__theme__button__no__margin" onClick={hideChallengeView}>Close</button>
                        </div>


                        <div className="opponent__container">

                            <div className={this.state.directChallenge ? "horizontal__justify" : "hidden"}>

                                <div className="challenge__popup__image__container">
                                    <img src={(this.state.theirProfile !== undefined && this.state.theirProfile.image !== "") ? this.state.theirProfile.image : user_image} className="challenge__popup__user__image" alt="Profile Image"/>
                                </div>

                                <div className="vertical__challenge__name__handle">
                                    <div className="title__yellow">{this.state.theirProfile !== undefined ? this.state.theirProfile.name : ""}</div>
                                    <div className="medium__text">@{this.state.theirProfile !== undefined ? this.state.theirProfile.handle : ""}</div>
                                </div>

                            </div>

                              <div className={this.state.allowOpponentToggle ? "direct__challenge__container" : "hidden"}>

                                      <div className="medium__text__gray">{this.state.directChallenge ? "Opponent" : "Direct Friend Challenge?"}</div>

                                      <div className="horizontal__flex__center">
                                          <button className="white__background__theme__button" onClick={handleSelectFriend}>{this.state.directChallenge ? "change" : "Select"}</button>
                                          <button className={this.state.theirProfile === undefined ? "hidden" : "theme__background__gray__button"} onClick={removeSelectedFriend}>remove</button>
                                      </div>

                              </div>
                          </div>

                          <div className="top__close__challenge__view">
                              <div className="bold__darkgray__large__text">Game Type</div>
                          </div>

                          <div className={this.state.showScrollbox ? "hidden" : "game__info__view"}>

                                  <div className="challenge__game__image">
                                      <img src={imageForType(this.state.gameType)} className="challenge__game__image__constraint"/>
                                  </div>

                                  <div className="horizontal__flex__center">
                                      <div className="large__yellow">{this.state.gameType === undefined ? "" : nameForType(this.state.gameType)}</div>
                                      <button className="white__background__gray__button__small" onClick={changeChallengeGameType}>change</button>
                                  </div>

                          </div>


                          <ScrollBox
                              show={this.state.showScrollbox}
                              children={GAMETYPES.map(({ name, id }) => (
                                  <GameCard type={id} name={name} handleSelected={(gt) => challengeGameSelected(gt)}/>
                              ))}
                          />



                          <div className={(!this.state.showScrollbox && this.state.showTurnTimer) ? "turn__timer__container" : "hidden"}>
                              <div className="bold__gray__large__text">Turn Timer</div>
                              <button className="white__background__theme__button" onClick={showTurnTimerPopup}>{this.state.turnTimer} minutes</button>
                          </div>


                          <div className="bet__amount__container">

                              <div>
                                  <div className="bold__gray__large__text">Bet Amount</div>
                                  <div className="small__text">We take 3.5% of the final payout</div>
                              </div>
                              <button className="white__background__theme__button" onClick={showBetPopup}>${this.state.betAmount}</button>
                          </div>


                          <div className="spacer__vertical"/>

                          <div className={this.state.directChallenge ? "message__box" : "hidden"}>
                              <div className="bold__gray__large__text">Add a message</div>

                              <input
                                  row_count="1"
                                  length="400"
                                  wrapping='off'
                                  className="input__textbox"
                                  placeholder="Aa"
                                  onChange={(e) => setMessage(e.target.value)}
                              />

                          </div>
                          <div className="horizontal__justify">
                              <div className="spacer__vertical"/>
                              {!this.state.sending && <button className="white__background__theme__button" onClick={prepareAndSendChallenge}>Send Challenge!</button>}

                              {this.state.sending && <Loading text="Sending" size="s"/> }
                          </div>

                        </div>

                    </ChallengeViewModal>

                    {this.state.showConfirmation &&
                        <ConfirmationPopup
                            positive="Close"
                            key={Math.random()}

                            title={this.state.confirmationTitle}
                            body={this.state.confirmationBody}
                            handleClose={hideConfirmation}
                        />
                    }

                    {this.state.showTurnTimerPopup &&
                        <SetTurnTimerPopup
                            key={Math.random()}
                            onHide={hideTurnTimerPopup}
                            turnTimer={this.state.turnTimer}
                            handleUpdate={(data) => updateTurnTimer(data)}/>
                    }

                    {this.state.showBetAmountPopup &&
                        <SetBetAmountPopup
                            key={Math.random()}
                            onHide={hideBetAmountPopup}
                            betAmount={this.state.betAmount}
                            handleUpdate={(data) => updateBetAmount(data)}/>
                    }


                    {this.state.showFriendSelectionModal && <FriendSelectView
                        key={Math.random()}
                        masterFriends={this.state.friends}
                        onHide={hideFriendSelectPopup}
                        myProfile={this.state.myProfile}
                        handleOpponentSelected={(handle) => opponentSelected(handle)}

                     />
                    }

                </div>


            );


        }
}
