import styled from "styled-components";
import React,{ Component} from 'react'
import Modal from 'react-overlays/Modal';
import "../CSS/Theme.css"
import "../CSS/FriendSelectPopupView.css"
import user_image from "../Assets/avatar.svg";
import FriendsList from "../Components/FriendsList"
import SearchBar from "../Components/SearchBar"
import {getFriends} from "../firebase"

const FriendSelectionBackdrop = styled("div")`
            position: fixed;
            z-index: 1040;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000;
            opacity: 0.5;
            `;
        
        
const ViewFriendSelectionModal = styled(Modal)`
            position: fixed;
            width: 40vw;
            z-index: 1040;
            top: ${() => 1}%;
            left: ${() => 30}%;
            border-radius: 15px;
            background-color: var(--main);
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
            padding-top:10px;
            padding-bottom:30px;
            padding-left:2.5vw;
            padding-right:2.5vw;

            @media only screen and (max-width: 768px) {
                position: fixed;
                width: 90vw;
                z-index: 1040;
                top: ${() => 1}%;
                left: 2.5vw;
                padding-top:20px;
                padding-bottom:20px;
                border-radius: 15px;
                background-color: var(--main);
                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        }
    `;
const renderBackdrop = (props) => <FriendSelectionBackdrop {...props} />;



export default class FriendSelectView extends Component {
    
    constructor(props){
        super(props);
    
        this.onHide = props.onHide
        this.handleOpponentSelected = props.handleOpponentSelected
        
        console.log("PROPS : ", props)
        this.state ={
            masterFriends:props.masterFriends,
            searchText:''
        }

        
    }
    
    
    handleSearch = e => {
        
         
        e.preventDefault();
            
        this.setState({searchText:e.target.value})
        
        
    }
    
    
    
    
    render() {
        
        
        const handleListItemSelected = (user) =>{

            console.log("FRIEND SELECTED: ", user)
            this.handleOpponentSelected(user)
    
        }
    
        const handleHidden = () =>{
            this.onHide()

        }
    
    
        const onClear = () => {
            // @ts-ignore (us this comment if typescript raises an error)
            this.setState({friends:this.state.masterFriends, searchText:""})

        };
        
        const filterUsers = () =>{
            
            const filtered = this.state.masterFriends.filter(friend => {
                return (friend.name.includes(this.state.searchText) || friend.handle.includes(this.state.searchText));
            });
        

        
            return filtered;
        }
        
    
        
        return(

            <div>

                <ViewFriendSelectionModal
                    show={true}
                    onHide={handleHidden}
                    renderBackdrop={renderBackdrop}
                    aria-labelledby="modal-label"
                    >

                <div  className="center__friend__select__content">


                    <div key={Math.random()} className="friend__select__top__view">
                        <div className="large__yellow"> Select Friend </div>
                        <button className="white__background__theme__button__no__margin" onClick={handleHidden}>Close</button>
                    </div>

                    <div key={Math.random()} className="search__bar__container">

                        <input 
                            rows={1}
                            placeholder='Search by Name, Handle'
                            className="search__bar"
                            maxLength={70}
                            autoFocus="autoFocus"
                            overflow-x='scroll'
                            resize='none'
                            value={this.state.searchText}
                            onChange={this.handleSearch}
                        />

                        <button className="dark__theme__background__gray__button" onClick={() => onClear()}>clear</button>

                    </div>

                    <div key={Math.random()} className="all__friends__select__container">
                        {(this.state.masterFriends !== undefined) ? <FriendsList key={Math.random()} data={filterUsers()} handleSelected={(user) => handleListItemSelected(user)}/> : ""}

                    </div>
                </div>
                </ViewFriendSelectionModal>
             </div>
            )
    }
}
