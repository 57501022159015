import React from 'react';
import "../CSS/Dropdown.css";

import {
  DropdownWrapper,
  StyledSelect,
  StyledOption,
  StyledLabel,
  StyledButton
} from "./dropdown-styles.js";





export const Dropdown = (props) => {
  return (
    <DropdownWrapper action={props.action} onChange={props.onChange}>
      <StyledLabel htmlFor="services">{props.formLabel}</StyledLabel>
      
      <StyledSelect id="services" name="services">
        {props.children}
      </StyledSelect>
      
    </DropdownWrapper>
  );
}




export const Option = (props) => {
    
  return <StyledOption selected={props.selected}>{props.value}</StyledOption>;
}
