import React, { useEffect, useRef,  useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useHistory } from "react-router-dom";
import logo from "../../Assets/logo.svg";
import UserSingleton from "../../UserSingleton"
import ConfirmationPopup from "../../Components/ConfirmationPopup"
import GameOverPopup from "../../Components/GameOverPopup"

import wrongAnswer from "../../Assets/wrong_answer.svg"
import rightAnswer from "../../Assets/correct_answer.svg"

import GameModel from "../../Models/Game"

import {auth, db, gamePathForType, getArchivedGame} from "../../firebase";

import "./Trivia.css";
import TriviaResults from "./TriviaResults";
import "../../CSS/Theme.css";

function Trivia() {

  const [game, setGame] = useState(undefined);
  const [gameState, setGameState ] = useState(undefined);
  const [gameId, setGameId] = useState(undefined);

  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  const instance = UserSingleton.getInstance()

  var gameListener = undefined
  var timer = undefined

  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showGameOver, setShowGameOver] = useState(false)

  const [confirmationTitle, setConfirmationTitle] = useState("Ready?")
  const [confirmationBody, setConfirmationBody] = useState("You have 90 seconds to answer 10 questions!")
  const [confirmationButton, setConfirmationButton] = useState("Start!")
  const [currentQuestion, setCurrentQuestion] = useState(undefined)
  const [finalResults, setFinalResults] = useState(false)
  const [timeRemaining, setTimeRemaining] = useState(90)

  const {doc, onSnapshot, updateDoc, getDoc} = require('firebase/firestore')



  useEffect(() => {

    if (loading) return;
    if (!user) history.replace("/login");

    const queryParams = new URLSearchParams(window.location.search)
    const paramGameId = queryParams.get("id")
    const archived = queryParams.get("archived")

    if(gameId === undefined && gameListener === undefined && archived === null){

        setGameId(paramGameId)
        attachGameListener(paramGameId)




    }else if (gameId === undefined && archived !== null){

        setGameId(paramGameId)
        getArchive(paramGameId)

    }


     return () => unmountTimer()

  }, [user, loading, gameId, game]);

  function unmountTimer(){
    if(timer !== undefined){
      clearInterval(timer)

    }

  }
//    useEffect(() =>{
//
//        return () => {
//            if(timer !== undefined){
//                clearInterval(timer)
//            }
//        }
//
//    },[]);
//

    async function attachGameListener(gameId){


        if(gameListener !== undefined){
            return
        }

        const gameRef = doc(db, "TriviaGames", gameId);


        gameListener = onSnapshot(gameRef, (doc) => {

            const data = doc.data();

            if(doc !== undefined && data !== undefined){

               let game = new GameModel(data)

               setGame(game)
               setGameState(game.gameData)

               checkAndSetUIState(game)

            }else{

               setGame(undefined)
               setGameState(undefined)

            }
        });
      }




    async function getArchive(gameId){

        console.log("RETRIEVING ARCHIVAL")
        let game = await getArchivedGame(gameId)

        if(game === false){
            return
        }

        setGame(game)
        setGameState(game.gameData)

        checkAndSetUIState(game)
    }



    async function isGameOver(gameData){


        console.log("Checking Game OVer: ", gameData)
        if(gameData === undefined){
            return false
        }


        if(gameData.gameComplete){
            return true
        }

        console.log("IS Game OVer: ", gameData)

        // here we need to check if either user has answered all questions or ran out of time
        let chalUid = gameData.challengerUid
        let oppUid = gameData.opponentUid

        let chal = gameData.gameData[chalUid]
        let opp = gameData.gameData[oppUid]

        if(chal === undefined || opp === undefined){
            return false
        }

        let chalExpires = chal.expires
        let oppExpires = opp.expires

        let chalQ = chal.question
        let oppQ = opp.question

        if(chalExpires === undefined || oppExpires === undefined){
            console.log("Expires is undefined")

            return false
        }

        let chalExpired = isExpired(chalExpires.seconds)
        let oppExpired = isExpired(oppExpires.seconds)

        console.log("Challenger Expired: ", chalExpired)
        console.log("Opponent Expired: ", oppExpired)

        console.log("Challenger Question: ", chalQ)
        console.log("Opponent Question: ", oppQ)

        if((chalQ > 10 || chalExpired) && (oppQ > 10 || oppExpired)){
            if(timer !== undefined){
              clearInterval(timer)
            }
            await setGameOver(chal, opp, gameData)

            return false
        }

        return false

    }


    async function setGameOver(challengerGameState, opponentGameState, gameData){

        let chalScore = challengerGameState.score
        let oppScore = opponentGameState.score

        console.log("ENDING GAME")
        var newwinner = "";

        //determine if winner
        if(chalScore > oppScore){
            newwinner = gameData.challengerUid
        }else if (oppScore > chalScore){
            newwinner = gameData.opponentUid
        }else{
            //its a tie
        }


        let gamePath = gamePathForType(gameData.type)
        const path = doc(db, gamePath, gameData.gameId)
        await updateDoc(path, {winner: newwinner, gameComplete:true})
    }


    async function checkAndSetUIState(gameData){


        let gameOver = await isGameOver(gameData)


        if(gameOver){

            console.log("Game Over, winner is: ", gameData.winner)

            if(gameListener !== undefined){
                gameListener()
            }

            setFinalResults(true)
            setCurrentQuestion(false)
            setShowGameOver(true)
            setTimeRemaining(0)
            return
        }


        let gameState = gameData.gameData
        let gameId = gameData.gameId
        let type = gameData.type
        let gamePath = gamePathForType(type)




        if(gameState === undefined || (gameState[user.uid] === undefined && gameData.gameStarted === "")){
            //we need to setup the game
            let uid = user.uid
            //set expires to 10 second countdown

            let initialUserState = {expires:0, question:0, results:[], score: 0, timerStarted:false}
            var initGameData = {gameStarted:uid}
            initGameData[uid] = initialUserState

            const path = doc(db, gamePath, gameId)
            await updateDoc(path, {gameData:initGameData})


        }else if(gameState[user.uid] === undefined){

            let initialUserState = {expires:0, question:0, results:[], score: 0, timerStarted:false}

            let userPath = 'gameData.' + user.uid
            let updates = {}
            updates[userPath] = initialUserState

            const path = doc(db, gamePath, gameId)
            await updateDoc(path, updates)

        }


        if(gameState[user.uid] !== undefined && gameState[user.uid].timerStarted === false){


            setShowConfirmation(true)
            return
        }


        let userGameState = gameState[user.uid]
        //else update the UI for the ongoing game state


        if(timer === undefined && userGameState !== undefined){
            console.log("Starting Timer...")
            startTimer(userGameState)

        }else if(timer !== undefined && isExpired(userGameState.expires.seconds)){
            console.log("Clearing Timer...")

            clearInterval(timer)
            setTimeRemaining(0)

        }



        if(!userGameState){
           return
        }



        if(userGameState.question > 10){
           setCurrentQuestion(undefined)

           setFinalResults(true)
           return
        }



        if(!currentQuestion){
            //loading the view
            let qNum = userGameState.question - 1
            let question = gameState.questions[qNum]

            console.log("Calling Preparing Question, loading view")

            prepareQuestion(question)


        }else if(currentQuestion && currentQuestion.num > userGameState.question){
            // update curent question
            let qNum = userGameState.question - 1
            let question = gameState.questions[qNum]

            console.log("Calling Preparing Question, next question")

            prepareQuestion(question)
        }
    }



      const startGame = async() => {
        //start the game here
        //set users expire time 90 seconds in the future and timerStarted to true, question to 1
        let type = game.type
        let gamePath = gamePathForType(type)

        var timeObject = new Date();
        timeObject = new Date(timeObject.getTime() + 1000 * 91);
        console.log(timeObject);


        let expiresPath = 'gameData.' + user.uid + '.expires'
        let timerStartedPath = 'gameData.' + user.uid + '.timerStarted'
        let questionPath = 'gameData.' + user.uid + '.question'

        let updates = {}

        updates[expiresPath] = timeObject
        updates[timerStartedPath] = true
        updates[questionPath] = 1
        updates.lastPlayed = user.uid

        const path = doc(db, gamePath, gameId)


        if(game.gameStarted === "" || game.gameStarted === undefined){
            updates.gameStarted = user.uid
        }

        await updateDoc(path, updates)

        setShowConfirmation(false)
      }



    async function checkAnswer(answer){

        let questions = gameState.questions
        let type = game.type
        let gameId = game.gameId
        let gamePath = gamePathForType(type)

        let currentUserData = gameState[user.uid]
        var userUpdates = {}

        let userScorePath = "gameData." + user.uid + ".score"
        let userQuestionPath = "gameData." + user.uid + ".question"
        let userResultsPath = "gameData." + user.uid + ".results"


        let score = currentUserData.score
        let currentIndex = currentUserData.question - 1
        let currentQuestion = questions[currentIndex]
        let correctAnswer = currentQuestion.rightAnswer

        var results = currentUserData.results

        if (correctAnswer == answer) {

            userUpdates[userScorePath] = score + 1
            results.push(1)
            userUpdates[userResultsPath] = results
//            showAnswerOverlay(.green)

        }else{
            results.push(0)
            userUpdates[userResultsPath] = results
        }

        userUpdates[userQuestionPath] = currentUserData.question + 1

        const path = doc(db, gamePath, gameId)

        console.log(userUpdates)

        await updateDoc(path, userUpdates)

    }


  const updateTimer = (gameStateData) =>{
      let now = new Date().getTime() / 1000

      if(gameStateData === undefined){
          console.log("GameStateData undefined")
          return
     }

      let expires = gameStateData.expires.seconds

      let timeRemaining = expires - now

      if(now > expires){

        if(timer !== undefined){
            clearInterval(timer)
        }
        setTimeRemaining(0);
        return
      }
      setTimeRemaining(Math.trunc(timeRemaining))

  }

  const startTimer = (gameStateData) => {

        timer = setInterval(() => {
            updateTimer(gameStateData);
        }, 1000)
    }
  //argument is expire time in seconds
  const isExpired = (expires) =>{

      let now = new Date().getTime() / 1000


      let timeRemaining = expires - now

      if(now > expires){

        return true;
      }

      return false

  }


  const prepareQuestion = (questionData) =>{

      let displayData = {}

      displayData.question = questionData.question
      let answers = [questionData.rightAnswer,questionData.wrongAnswer1, questionData.wrongAnswer2, questionData.wrongAnswer3]

      answers.sort(() => 0.5 - Math.random())
      displayData.answers = answers

      setCurrentQuestion(displayData)
  }


  const oppositeUid = () => {

    var theirUid = ""
    for(const uid of game.players){
        if(uid !== user.uid){
           theirUid = uid
        }

    }

    return theirUid

  }

  const rematch = (opponentUid) => {

      let string = 'home?rematch=true&type=7&opp=' + opponentUid
      history.replace(string)
  }


  return (
    <div className="trivia">

        <div className="trivia__top__info__view">

            <div className="vertical__auto">
                <div className="medium__text">Challenger</div>
                <div className="opponent__info__container">
                    <img className="profile__image" src={game && game.challengerImage} />
                    <div className="handle__text">{game && game.challengerHandle}</div>
                    <div className="spacer__horizontal"></div>
                    <div className="large__yellow">{(gameState && gameState[game.challengerUid] && gameState[game.challengerUid].score) ? gameState[game.challengerUid].score : 0}</div>
                </div>
            </div>

            <div className="vertical__auto">
               <div className="medium__text">Opponent</div>
                <div className="opponent__info__container">
                    <img className="profile__image" src={game && game.opponentImage} />
                    <div className="handle__text">{game && game.opponentHandle}</div>
                    <div className="spacer__horizontal"></div>
                    <div className="large__yellow">{(gameState && gameState[game.opponentUid] && gameState[game.opponentUid].score) ? gameState[game.opponentUid].score : 0}</div>
                </div>
            </div>
        </div>


        <div className="game__info__container">

            <div className="large__text">Question: {(gameState && gameState[user.uid] && gameState[user.uid].question < 11) ?  (gameState[user.uid].question + '/ 10') : ""} </div>
            <div className="small__spacer__vertical"/>

            <div className="horizontal__flex__center">
                <div className="medium__text">Time Remaining:</div>
                <div className="spacer__horizontal"/>
                <div className="large__yellow">{(gameState && gameState[user.uid]) && timeRemaining} seconds</div>
            </div>
        </div>




        {
        currentQuestion && <div className="trivia__game__container">

            <div className="question__container"> {currentQuestion.question} </div>

            <div className="answer__container">
                <div> {currentQuestion.answers[0]} </div>
                <div className="white__background__theme__button" onClick={() => checkAnswer(currentQuestion.answers[0])}> Select </div>
            </div>

            <div className="answer__container">
                <div> {currentQuestion.answers[1]} </div>
                <div className="white__background__theme__button" onClick={() => checkAnswer(currentQuestion.answers[1])}> Select </div>
            </div>
            <div className="answer__container">
                <div> {currentQuestion.answers[2]} </div>
                <div className="white__background__theme__button" onClick={() => checkAnswer(currentQuestion.answers[2])}> Select </div>
            </div>

            <div className="answer__container">
                <div> {currentQuestion.answers[3]} </div>
                <div className="white__background__theme__button" onClick={() => checkAnswer(currentQuestion.answers[3])}> Select </div>
            </div>

        </div>
        }



        {


        finalResults && <TriviaResults game={game} oppositeUid={oppositeUid()} currentUid={user.uid}/>
        }

        {showConfirmation &&
                <ConfirmationPopup
                    handleClose={() => startGame()}
                    positive={confirmationButton}
                    title={confirmationTitle}
                    body={confirmationBody}
                />
            }
        {showGameOver &&
                <GameOverPopup
                    currentUser={user}
                    handleHidden={() => setShowGameOver(false)}
                    handleRematch={(opp) => rematch(opp)}
                    game={game}
                />
            }
    </div>
  );
}

export default Trivia;
