import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import styled from "styled-components";
import Avatar from '../../Assets/avatar.png'

import "./ChessGame.css";
import "../../CSS/Theme.css";

import ConfirmationPopup from "../../Components/ConfirmationPopup"
import AsyncImage from "../../Components/AsyncImage"
import UserSingleton from "../../UserSingleton"


import {Chess} from "chess.js";
import { Chessboard } from "react-chessboard";
import GameModel from "../../Models/Game"
import GameOverPopup from "../../Components/GameOverPopup"

import {auth, db, gamePathForType, getArchivedGame} from "../../firebase";


function ChessGame() {

  const [chessGame, setChessGame] = useState(new Chess());

  const [game, setGame] = useState(undefined);
  const [gameState, setGameState ] = useState(undefined);
  const [gameId, setGameId] = useState(undefined);

  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  const instance = UserSingleton.getInstance()

  var gameListener = undefined

  const [showGameOver, setShowGameOver] = useState(false)

  const [showIsTiePopup , setShowIsTiePopup] = useState(false)
  const [showIsTiePopupAction , setShowIsTiePopupAction] = useState(false)

  const confirmationTitle= "Stalemate!"
  const confirmationBody = "There must be a winner. Positions will be swapped and the game restarted."

  const [currentQuestion, setCurrentQuestion] = useState(undefined)
  const [finalResults, setFinalResults] = useState(false)
  const [timeRemaining, setTimeRemaining] = useState(90)

  const {doc, onSnapshot, updateDoc, getDoc} = require('firebase/firestore')
  const [boardWidth, setBoardWidth] = useState(0)
  const [fen, setFen] = useState(undefined)
  const [turn, setTurn] = useState('w')
  const [orientation, setOrientation] = useState('white')


  useEffect(() => {

    if (loading) return;
    if (!user) history.replace("/welcome");


    if(window.innerWidth < 768){
        let phoneWidth = window.innerWidth
        setBoardWidth(phoneWidth)

    }else{
        let width = window.innerWidth
        let marginLeft = (width - 500) / 2

        setBoardWidth(500)
    }


    const queryParams = new URLSearchParams(window.location.search)
    const paramGameId = queryParams.get("id")
    const archived = queryParams.get("archived")


    if(gameId === undefined && gameListener === undefined && archived === null){

        setGameId(paramGameId)
        console.log("Game Id Set, will attatch listener")
        attachGameListener(paramGameId)

    }else if (gameId === undefined && archived !== null){

        setGameId(paramGameId)
        getArchive(paramGameId)
    }

  },[user, loading, gameId, game])




   async function attachGameListener(gameId){

        if(gameListener !== undefined){
            return
        }

        const gameRef = doc(db, "ChessGames", gameId);

        gameListener = onSnapshot(gameRef, (doc) => {

            const data = doc.data();

            if(doc !== undefined && data !== undefined){
                setGame(data)
                setGameState(data.gameData)
                checkAndSetUIState(data)
            }else{

               setGame(undefined)
               setGameState(undefined)
            }
        });
    }



    async function getArchive(gameId){

        let game = await getArchivedGame(gameId)

        if(game === false){
            return
        }

        checkAndSetUIState(game)
    }




    function isGameOver(gameData){

        if(gameData === undefined){
            return false
        }


        if(gameData.gameComplete === true){
            return true

        }
    }


    function isMyTurn(currState){

        // let color = game.gameData[user.uid]
        // var my = 'w'
        // if(color !== undefined){
        //   my = color.charAt(0)
        // }

        if(game.lastPlayed === user.uid){

            return false
        }

        return true;

    }

   async function checkAndSetUIState(gameData){

        let gameOver = isGameOver(gameData)

        if(gameOver){

            console.log("Game Over, winner is: ", gameData.winner)

            if(gameData.winner === ""){

                //its a stale mate, switch users and start another game.
                if(isMyTurn(gameData)){

                    setShowIsTiePopupAction(true)


                }

                return
            }

            if(gameListener !== undefined){
                gameListener()
            }
            setShowGameOver(true)
        }


        let gameState = gameData.gameData
        let gameId = gameData.gameId
        let betId = gameData.betId
        let type = gameData.type
        let gamePath = gamePathForType(type)
        let uid = user.uid

        console.log('GAME DATA: ', gameData)


        //initialize players
        if(gameState.white === undefined && (gameData.gameStarted === "" || gameData.gameStarted === undefined)){
            //we need to setup the game
            //set expires to 10 second countdown
            console.log('Initiating Board')
            var initGameData = {white:uid, check:false, fen:chessGame.fen()}
            initGameData[uid] = 'white'

            const path = doc(db, gamePath, gameId)
            return updateDoc(path, {gameData:initGameData, gameStarted:uid})


        }else if(gameState.black === undefined && gameState.white !== undefined && gameState.white !== uid){

            let updateGameState = gameState
            console.log('Adding Black Player')

            updateGameState[uid] = 'black'
            updateGameState['black'] = uid

            const path = doc(db, gamePath, gameId)
            return updateDoc(path, {gameData:updateGameState})

        }

        console.log('Updating UI with', gameState)


       let fen = gameState.fen
       if(fen !== undefined){

           chessGame.load(fen)
           let color = gameState[uid]
           if(color === undefined) return;

           setOrientation(color)

           let myColor = color.charAt(0)
           console.log("Turn: ", chessGame.turn())

            setTurn(chessGame.turn())
            setFen(fen)
       }
    }





  function myColor(){

      if(game.gameData === undefined){
          return ''
      }

      let color = game.gameData[user.uid]
      if(color !== undefined){
          return color.charAt(0)

      }
  }



  function safeGameMutate(modify) {
    setChessGame((g) => {
      const update = { ...g };
      modify(update);
      return update;
    });
  }



  async function onDrop(sourceSquare, targetSquare) {

    let move = null;
    console.log("CHESS TURN: ", chessGame.turn(), "PLAYER: ", game.gameData[user.uid].charAt(0))


    if(!game && !game.gameData){
        return false
    }

    if(chessGame.turn() !== game.gameData[user.uid].charAt(0))  {
        console.log("NOT PLAYERS TURN")
        return false
    }

    safeGameMutate((chessGame) => {
      move = chessGame.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q", // always promote to a queen for example simplicity
      });
    });
      console.log('Move', move)
    if (move === null) return false; // illegal move
      console.log(chessGame.pgn().slice(-2).trim())
      await submitMove(chessGame.fen(), chessGame.pgn().slice(-2).trim())


    return true;
 }






 async function submitMove(newBoard, last){

    if(game === undefined) return;
    let gamePath = gamePathForType(game.type)
    const path = doc(db, gamePath, game.gameId)

    console.log("IN CHECK?: ", chessGame.in_check())

    let move = game.gameData
    move.fen = newBoard
    move.last = last
    move.check = chessGame.in_check()
    move.turn = chessGame.turn()

    let updates  = {gameData: move}
    updates.lastPlayed = user.uid

    let overState = getGameOverState()


    if(overState !== 'none'){

        updates.gameComplete = true

        if(overState === 'checkmate'){
            updates.winner = user.uid
        }else{
            setShowIsTiePopup(true)
        }
    }

    await updateDoc(path, updates)

 }
   function getGameOverState(){

        if(chessGame.in_threefold_repetition()){
            return 'threefold'
        }else if(chessGame.insufficient_material()){

            return 'insufficient'
        }else if(chessGame.in_stalemate()){

            return 'stalemate'
        }else if(chessGame.in_draw()){

           return 'draw'

        }else if (chessGame.in_checkmate()){
            return 'checkmate'
        }


        return 'none'

  }



  async function resetBoardSwitchPlayers(gameState){


        if(game === undefined) return;
        let gamePath = gamePathForType(game.type)
        const path = doc(db, gamePath, game.gameId)

        let initWhite = gameState.white
        let initBlack = gameState.black

        gameState[initWhite] = 'black'
        gameState[initBlack] = 'white'

        gameState.white = initBlack
        gameState.black = initWhite

        gameState.fen = chessGame.fen()
        gameState.check = false
        gameState.turn = 'w'


        await updateDoc(path, gameState, {merge:true})
        setShowIsTiePopupAction(false)

    }



  function colorForTurn(){
      let color = chessGame.turn()
      if(color === 'w'){
          return "White's Turn"
      }
      return "Black's Turn"
  }

  const rematch = (opponentUid) => {

      let string = 'home?rematch=true&type=6&opp=' + opponentUid
      history.replace(string)
  }



  const titleColorForPlayer = (uid) =>{
      if(game === undefined){
          return ''
      }
      if(gameState === undefined){
          return ''
      }

      if(gameState[uid] === 'white'){
        return "White"
      }
      return "Black"
  }



  return (
      <div className="chess">

             <div className="top__info__view">

                <div className="vertical__auto">
                    <div className="medium__text">{game && titleColorForPlayer(game.challengerUid)}</div>
                    <div className="opponent__info__container">
                        <AsyncImage className="profile__image" src={ (game && game.challengerImage !== "") ? game.challengerImage : Avatar} />
                        <div className="handle__text">{game && game.challengerHandle}</div>
                        <div className="spacer__horizontal"/>

                    </div>
                </div>


                <div className="vertical__auto">
                   <div className="medium__text">{game && titleColorForPlayer(game.opponentUid)}</div>
                    <div className="opponent__info__container">
                        <AsyncImage className="profile__image" src={(game && game.opponentImage !== "") ? game.opponentImage : Avatar} />
                        <div className="handle__text">{game && game.opponentHandle}</div>
                        <div className="spacer__horizontal"/>

                    </div>
                </div>

            </div>

            <div className="horizontal__flex__center">
                <div className="large__yellow">{colorForTurn()}</div>
                {(game && game.gameData.check) && <div className="large__white"> - Check! </div>}
            </div>

            <div className="board__frame">
                <Chessboard position={chessGame.fen()} onPieceDrop={onDrop} boardWidth={boardWidth} arePiecesDraggable={true} boardOrientation={orientation} />
            </div>



            {(showIsTiePopupAction && game) &&
                <ConfirmationPopup
                    handleClose={() => resetBoardSwitchPlayers()}
                    positive="Reset"
                    title={confirmationTitle}
                    body={confirmationBody}
                />
            }

            {(showIsTiePopup && game) &&
                <ConfirmationPopup
                    handleClose={() => setShowIsTiePopup(false)}
                    positive="Ok"
                    title={confirmationTitle}
                    body={"Your Opponent will see a similar message and reset the board."}
                />
            }

            {(showGameOver && chessGame) &&
                <GameOverPopup
                    currentUser={user}
                    handleHidden={() => setShowGameOver(false)}
                    handleRematch={(opp) => rematch(opp)}
                    game={game}
                />
            }


      </div>

  )


}

export default ChessGame;
