import React from 'react';
import '../CSS/ProfileRecentGameCell.css';
import Game from '../Models/Game';
import default_avatar_image from "../Assets/avatar.svg";

import AsyncImage from "./AsyncImage"

const ProfileRecentGameCell = ({uid, data}) => {
    
    let game = new Game(data)
    
    let betAmount = game.betAmount
    let gameName = game.name
    
    let winner = game.winner
    let image = "";
    let name = "";
    
    
    if(uid === game.opponentUid){
        image = game.challengerImage
        name = game.challengerHandle
        
    }else if(uid === game.challengerUid){
        image = game.opponentImage
        name = game.opponentHandle
    }
    

    var outcome = "Lost $"
    
    if(winner === uid){
        outcome = "Won $"
        
    }
    
    
    let recap = outcome + betAmount + " from " + name + " in " + gameName
    
    return(
        <div className="profile__recent__cell__container">

            <div className="profile__user__info__container">
                <div className="profile__recent__image__profile">
                    <AsyncImage draggable="false" src={image !== "" ? image : default_avatar_image} className="profile__recent__profile__image__constraint" />
                </div>
        
                <div className="profile__recap__text">{recap}</div> 
            </div>
            
                    
        </div>
    
    )
    
}


export default ProfileRecentGameCell;