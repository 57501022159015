import React from "react";
import logo from "../../Assets/logo.svg";

import AsyncImage from "../../Components/AsyncImage"

import wrongAnswer from "../../Assets/wrong_answer.svg"
import rightAnswer from "../../Assets/correct_answer.svg"


import "./TriviaResults.css";
import "../../CSS/Theme.css";

export default class TriviaResults extends React.Component {
    
    constructor(props){
        super(props)
        
        this.game = props.game
        this.gameState = props.game.gameData
        this.oppositeUid = props.oppositeUid
        this.currentUid = props.currentUid
    }
        


    render(){
    
          return (
            <div>

                {
                this.gameState && <div className="trivia__results__container">

                    <div className="result__row">
                        <div className="large__text" > Me </div>
                        <div className="bold__white__large__text"> Question </div>
                        <div className="large__text" > Them </div>

                    </div>

                    <div className="result__row">
                        <AsyncImage className="result__image" src={this.gameState[this.currentUid].results[0] === 0 ? wrongAnswer : rightAnswer}/>
                        <div className="bold__white__large__text">1</div>

                        <AsyncImage className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 0) ? "result__image" : "hidden"} src={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results[0] && this.gameState[this.oppositeUid].results[0]) === 0 ? wrongAnswer : rightAnswer}/>

                        <div className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 0) ? "hidden" : "result__image"}/>

                    </div>


                    <div className="result__row">
                        <AsyncImage className="result__image" src={this.gameState[this.currentUid].results[1] === 0 ? wrongAnswer : rightAnswer}/>
                        <div className="bold__white__large__text">2</div>

                        <AsyncImage className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 0) ? "result__image" : "hidden"} src={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results[1] && this.gameState[this.oppositeUid].results[1]) === 0 ? wrongAnswer : rightAnswer}/>

                        <div className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 1) ? "hidden" : "result__image"}/>

                    </div>




                    <div className="result__row">
                        <AsyncImage className="result__image" src={this.gameState[this.currentUid].results[2] === 0 ? wrongAnswer : rightAnswer}/>
                        <div className="bold__white__large__text">3</div>

                        <AsyncImage className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 0) ? "result__image" : "hidden"} src={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results[2] && this.gameState[this.oppositeUid].results[2]) === 0 ? wrongAnswer : rightAnswer}/>
                        
                        <div className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 2 ) ? "hidden" : "result__image"}/>

                    </div>



                    <div className="result__row">
                        <AsyncImage className="result__image" src={this.gameState[this.currentUid].results[3] === 0 ? wrongAnswer : rightAnswer}/>
                        <div className="bold__white__large__text">4</div>

                        <AsyncImage className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 0) ? "result__image" : "hidden"} src={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results[3] && this.gameState[this.oppositeUid].results[3]) === 0 ? wrongAnswer : rightAnswer}/>

                        <div className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 3) ? "hidden" : "result__image"}/>

                    </div>



                    <div className="result__row">
                        <AsyncImage className="result__image" src={this.gameState[this.currentUid].results[4] === 0 ? wrongAnswer : rightAnswer}/>
                        <div className="bold__white__large__text">5</div>

                        <AsyncImage className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 0) ? "result__image" : "hidden"} src={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results[4] && this.gameState[this.oppositeUid].results[4]) === 0 ? wrongAnswer : rightAnswer}/>

                        <div className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 4 ) ? "hidden" : "result__image"}/>
                    </div>

                    <div className="result__row">
                        <AsyncImage className="result__image" src={this.gameState[this.currentUid].results[5] === 0 ? wrongAnswer : rightAnswer}/>
                        <div className="bold__white__large__text">6</div>

                        <AsyncImage className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 0) ? "result__image" : "hidden"} src={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results[5] && this.gameState[this.oppositeUid].results[5]) === 0 ? wrongAnswer : rightAnswer}/>

                        <div className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 5) ? "hidden" : "result__image"}/>
                    </div>


                    <div className="result__row">
                        <AsyncImage className="result__image" src={this.gameState[this.currentUid].results[6] === 0 ? wrongAnswer : rightAnswer}/>
                        <div className="bold__white__large__text">7</div>

                        <AsyncImage className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 0) ? "result__image" : "hidden"} src={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results[6] && this.gameState[this.oppositeUid].results[6]) === 0 ? wrongAnswer : rightAnswer}/>

                        <div className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 6) ? "hidden" : "result__image"}/>
                    </div>


                    <div className="result__row">
                        <AsyncImage className="result__image" src={this.gameState[this.currentUid].results[7] === 0 ? wrongAnswer : rightAnswer}/>
                        <div className="bold__white__large__text">8</div>

                        <AsyncImage className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 0) ? "result__image" : "hidden"} src={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results[7] && this.gameState[this.oppositeUid].results[7]) === 0 ? wrongAnswer : rightAnswer}/>

                        <div className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 7) ? "hidden" : "result__image"}/>
                    </div>
                    <div className="result__row">

                        <AsyncImage className="result__image" src={this.gameState[this.currentUid].results[8] === 0 ? wrongAnswer : rightAnswer}/>
                        <div className="bold__white__large__text">9</div>

                        <AsyncImage className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 0) ? "result__image" : "hidden"} src={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results[8] && this.gameState[this.oppositeUid].results[8]) === 0 ? wrongAnswer : rightAnswer}/>

                        <div className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 8) ? "hidden" : "result__image"}/>
                    </div>



                    <div className="result__row">
                        <AsyncImage className="result__image" src={this.gameState[this.currentUid].results[9] === 0 ? wrongAnswer : rightAnswer}/>
                        <div className="bold__white__large__text">10</div>

                        <AsyncImage className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 0) ? "result__image" : "hidden"} src={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results[9] && this.gameState[this.oppositeUid].results[9]) === 0 ? wrongAnswer : rightAnswer}/>
                        
                        <div className={(this.gameState[this.oppositeUid] && this.gameState[this.oppositeUid].results && this.gameState[this.oppositeUid].results.length > 9 ) ? "hidden" : "result__image"}/>
                            

                    </div>
                </div>
                }

            </div>
          );
        }
}

