import React, {Component, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import styled from "styled-components";
import Avatar from '../../Assets/avatar.png'

import "../../CSS/Theme.css";

import ConfirmationPopup from "../../Components/ConfirmationPopup"
import AsyncImage from "../../Components/AsyncImage"
import GameOverPopup from "../../Components/GameOverPopup"
import UserSingleton from "../../UserSingleton"

import GameBoard  from "./GameBoard";
import GameModel from "../../Models/Game"


import {auth, db, gamePathForType, getArchivedGame} from "../../firebase";



function CheckersGame() {


  const [game, setGame] = useState(undefined);
  const [gameState, setGameState ] = useState(undefined);
  const [gameId, setGameId] = useState(undefined);

  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  const instance = UserSingleton.getInstance()

  var gameListener = undefined

  const [showGameOver, setShowGameOver] = useState(false)


  const [showIsTiePopup , setShowIsTiePopup] = useState(false)
  const [showIsTiePopupAction , setShowIsTiePopupAction] = useState(false)

  const confirmationTitle= "Tie!"
  const confirmationBody = "There must be a winner. Positions will be swapped and the game restarted."

  const [currentQuestion, setCurrentQuestion] = useState(undefined)
  const [finalResults, setFinalResults] = useState(false)
  const [timeRemaining, setTimeRemaining] = useState(90)


  const {doc, onSnapshot, updateDoc, getDoc} = require('firebase/firestore')
  const [boardWidth, setBoardWidth] = useState(0)
  const [turn, setTurn] = useState('r')
  const [board, setBoard] = useState([
				['b','-','b','-','b','-','b','-'],
				['-','b','-','b','-','b','-','b'],
				['b','-','b','-','b','-','b','-'],
				['-','-','-','-','-','-','-','-'],
				['-','-','-','-','-','-','-','-'],
				['-','r','-','r','-','r','-','r'],
				['r','-','r','-','r','-','r','-'],
				['-','r','-','r','-','r','-','r']
			])
  const [orientation, setOrientation] = useState('b')




  useEffect(() => {

    if (loading) return;
    if (!user) history.replace("/welcome");


    if(window.innerWidth < 768){
        let phoneWidth = window.innerWidth
        setBoardWidth(phoneWidth)

    }else{
        let width = window.innerWidth
        let marginLeft = (width - 500) / 2
        setBoardWidth(500)
    }

    const queryParams = new URLSearchParams(window.location.search)
    const paramGameId = queryParams.get("id")
    const archived = queryParams.get("archived")


    if(gameId === undefined && gameListener === undefined && archived === null){

        setGameId(paramGameId)
        console.log("Game Id Set, will attatch listener")
        attachGameListener(paramGameId)

    }else if (gameId === undefined && archived !== null){

        setGameId(paramGameId)
        getArchive(paramGameId)
    }

  },[user, loading, gameId, game])




   async function attachGameListener(gameId){

        if(gameListener !== undefined){
            return
        }

        const gameRef = doc(db, "CheckersGames", gameId);

        gameListener = onSnapshot(gameRef, (doc) => {

            const data = doc.data();

            if(doc !== undefined && data !== undefined){
                setGame(data)
                setGameState(data.gameData)
                checkAndSetUIState(data)
            }else{

               setGame(undefined)
               setGameState(undefined)
            }
        });
    }


    //we are taking a single array length 64 and converting to 8x8 nested array

    function toNestedArray(single){

        let nested = []
        var working = []
        let pos = 0;



        for(const item of single){

            working.push(item)

            if(pos % 8 === 7){
                nested.push(working)
                working = []
            }
            pos++;
        }

        return nested

    }


    //we are taking an 8x8 nested array and converting to one array
    function toSingleArray(array){


        var singleArr = []
        for(const row of array){

            singleArr = [...singleArr, ...row]

        }

        console.log(singleArr)
        return singleArr
    }

    async function getArchive(gameId){

        let game = await getArchivedGame(gameId)

        if(game === false){
            return
        }

        checkAndSetUIState(game)
    }




    function isGameOver(gameData){

        if(gameData === undefined){
            return false
        }


        if(gameData.gameComplete === true){
            return true

        }
    }




   async function checkAndSetUIState(gameData){

        let gameOver = isGameOver(gameData)

        if(gameOver){

            console.log("Game Over, winner is: ", gameData.winner)

            if(gameData.winner === ""){

                //TODO its a stale mate, switch users and start another game.
                if(isMyTurn(gameData)){
                    setShowIsTiePopupAction(true)
                }

                return
            }

            if(gameListener !== undefined){
                gameListener()
            }

            setShowGameOver(true)
        }


        let gameState = gameData.gameData
        let gameId = gameData.gameId
        let betId = gameData.betId
        let type = gameData.type
        let gamePath = gamePathForType(type)
        let uid = user.uid



        //initialize players
        if(gameState.red === undefined && (gameData.gameStarted === "" || gameData.gameStarted === undefined)){
            //we need to setup the game
            //set expires to 10 second countdown
            console.log('Initializing Board')

            let initialBoard = [
    				['b','-','b','-','b','-','b','-'],
    				['-','b','-','b','-','b','-','b'],
    				['b','-','b','-','b','-','b','-'],
    				['-','-','-','-','-','-','-','-'],
    				['-','-','-','-','-','-','-','-'],
    				['-','r','-','r','-','r','-','r'],
    				['r','-','r','-','r','-','r','-'],
    				['-','r','-','r','-','r','-','r']
            ]


            var singleArr = toSingleArray(initialBoard)

            var initBoard = convertToDBBoard(singleArr, 1)

            var initGameData = {red:uid, board: initBoard, turn:'r'}

            initGameData[uid] = 'red'

            const path = doc(db, gamePath, gameId)

            return updateDoc(path, {gameData:initGameData, gameStarted:uid})


        }else if(gameState.red !== undefined && gameState.blue === undefined && gameState.red !== uid){

            let updateGameState = gameState
            console.log('Adding Blue Player')

            updateGameState[uid] = 'blue'
            updateGameState['blue'] = uid

            const path = doc(db, gamePath, gameId)
            return updateDoc(path, {gameData:updateGameState})

        }




       if(gameState !== undefined){

          let board = gameState.board
          let singleArrLocal = convertToLocalBoard(board)

          var nested = toNestedArray(singleArrLocal)

          console.log('Updating UI with', nested)

          let color = gameState[uid]
          if(color === undefined) return;

          let myColor = color.charAt(0)
          console.log(myColor)
          setOrientation(myColor)




          let turn = gameState.turn
          if(turn === 1){
            setTurn("r")
          }else if(turn === 2){
            setTurn("b")
          }

          setBoard(nested)

       }

    }

    //
    // const startGame = async(betId) => {
    //     //start the game here
    //     //set users expire time 90 seconds in the future and timerStarted to true, question to 1
    //
    //     const betPath = doc(db, "AllChallenges", betId)
    //
    //     await updateDoc(betPath, {gameStarted:user.uid})
    //
    // }



  function myColor(){

      if(game.gameData === undefined){
          return 'r'
      }

      let color = game.gameData[user.uid]
      if(color !== undefined){
          return color.charAt(0)

      }
  }






 async function submitMove(turnData){

    if(game === undefined) return;
    let gamePath = gamePathForType(game.type)
    const path = doc(db, gamePath, game.gameId)


    if(game.gameComplete) return;

    let last_move = game.gameData
    console.log("TURN DATA: ", turnData)

     // the board in turn data is a 2d array. For storage we need to convert to a 1d array.
    let singleArr = toSingleArray(turnData.board)

    var turn = 1
    if(turnData.activePlayer === 'b'){
      turn = 2
    }

    last_move.turn = turn


    var newboard = convertToDBBoard(singleArr, turn)

    last_move.board = newboard
    last_move.jump = false



    let gameOver = turnData.gameOver
    let winner = turnData.winner

    let updates  = {gameData: last_move, lastPlayed:user.uid, turnReminderSent:false}



    if(gameOver !== undefined){

        updates.gameComplete = true

        if(winner !== undefined && winner !== ''){
            updates.winner = user.uid
        }else{

            setShowIsTiePopup(true)

        }
    }

    await updateDoc(path, updates)

 }



  function colorForTurn(){
      if(game === undefined || game.gameData === undefined){
          return "Red's Turn"
      }

      let turn = game.gameData.turn

      if(turn === 1){
          return "Red's Turn"
      }
      return "Black's Turn"
  }


  const userColor = () =>{

    if(game === undefined || game.gameData === undefined){
          return 'red'
      }
      let uid = user.uid
      let color = game.gameData[uid]

      return color
  }


  const rematch = (opponentUid) => {

      let string = 'home?rematch=true&type=5&opp=' + opponentUid
      history.replace(string)
  }


  const titleColorForPlayer = (uid) =>{
      if(game === undefined){
          return ''
      }

      if(gameState === undefined){
          return ''
      }


      if(gameState[uid] === 'red'){
        return "Red"
      }
      return "Black"
  }


  function isMyTurn(currState){

        let color = game.gameData[user.uid]
        var my = 'r'

        if(color !== undefined){
          my = color.charAt(0)
        }

        if(currState.turn === my){

            return true
        }

        return false;

    }



  async function resetBoardSwitchPlayers(gameState){


        if(game === undefined) return;
        let gamePath = gamePathForType(game.type)
        const path = doc(db, gamePath, game.gameId)

        let initRed = gameState.red
        let initBlack = gameState.blue

        gameState[initRed] = 'blue'
        gameState[initBlack] = 'red'

        gameState.red = initBlack
        gameState.blue = initRed


        let initialBoard = [
				['b','-','b','-','b','-','b','-'],
				['-','b','-','b','-','b','-','b'],
				['b','-','b','-','b','-','b','-'],
				['-','-','-','-','-','-','-','-'],
				['-','-','-','-','-','-','-','-'],
				['-','r','-','r','-','r','-','r'],
				['r','-','r','-','r','-','r','-'],
				['-','r','-','r','-','r','-','r']
        ]


        var singleArr = toSingleArray(initialBoard)

        var board = convertToDBBoard(singleArr, 1)

        gameState.board = board
        gameState.turn = 1

        await updateDoc(path, gameState, {merge:true})

        setShowIsTiePopupAction(false)


  }



function convertToDBBoard(board, turn){

  let numPieces = 12
  var player1left = 0
  var player2left = 0
  var newboard = []

  for(const item of board){

    if(item === 'r'){
      player1left += 1
      newboard.push(1)

    }else if (item === 'b'){
      player2left += 1
      newboard.push(2)

    }else if (item === 'r k'){
      player1left += 1
      newboard.push(3)

    }
    else if (item === 'b k'){
      player2left += 1
      newboard.push(4)
    }
    else{
      newboard.push(0)
    }
  }

  let player2score = numPieces - player2left
  let player1score = numPieces - player1left

  let reversed = newboard.reverse()
  console.log("NEW BOARD", newboard.reverse())
  let data = {board: reversed, player1score: player1score, player2score: player2score, turn: turn, last: []}
  return JSON.stringify(data);
}


function convertToLocalBoard(boardString){

  console.log(boardString)
  let brd = JSON.parse(boardString)
  console.log(brd.board)
  var localSingleArrBoard = []

  for(const item of brd.board){

    if(item === 1){
      localSingleArrBoard.push('r')
    }else if (item === 2){
      localSingleArrBoard.push('b')
    }else if (item === 3){
      localSingleArrBoard.push('r k')
    }
    else if (item === 4){

      localSingleArrBoard.push('b k')
    }
    else{
      localSingleArrBoard.push('-')
    }
  }
  console.log(localSingleArrBoard.reverse())

  return localSingleArrBoard.reverse()

}


  return (
      <div className="checkers">

             <div className="top__info__view">

                <div className="vertical__auto">
                    <div className="medium__text">{game && titleColorForPlayer(game.challengerUid)}</div>
                    <div className="opponent__info__container">
                        <AsyncImage className="profile__image" src={ (game && game.challengerImage !== "") ? game.challengerImage : Avatar} />
                        <div className="handle__text">{game && game.challengerHandle}</div>
                        <div className="spacer__horizontal"/>

                    </div>
                </div>


                <div className="vertical__auto">
                   <div className="medium__text">{game && titleColorForPlayer(game.opponentUid)}</div>
                    <div className="opponent__info__container">
                        <AsyncImage className="profile__image" src={(game && game.opponentImage !== "") ? game.opponentImage : Avatar} />
                        <div className="handle__text">{game && game.opponentHandle}</div>
                        <div className="spacer__horizontal"/>

                    </div>
                </div>

            </div>

            <div className="horizontal__flex__center">
                <div className="large__yellow">{colorForTurn()}</div>
            </div>


            <div>
                <GameBoard viewing={orientation} pBoard={board} turn={turn} turnCallback={(turndata) => submitMove(turndata)} currentUser={user} currentPlayerColor={userColor()}/>
            </div>


            {(showIsTiePopupAction && game) &&
                <ConfirmationPopup
                    handleClose={() => resetBoardSwitchPlayers()}
                    positive="Reset"
                    title={confirmationTitle}
                    body={confirmationBody}
                />
            }

            {(showIsTiePopup && game) &&
                <ConfirmationPopup
                    handleClose={() => setShowIsTiePopup(false)}
                    positive="Ok"
                    title={confirmationTitle}
                    body={"Your Opponent will see a similar message and reset the board."}
                />
            }


            {(showGameOver) &&
                    <GameOverPopup
                        currentUser={user}
                        handleHidden={() => setShowGameOver(false)}
                        handleRematch={(opp) => rematch(opp)}
                        game={game}
                    />
            }
      </div>

  )


}





export default CheckersGame;
