import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import "./CSS/Profile.css";
import "./CSS/Theme.css";
import { auth, db, logout, allGamePaths, resumableGameTypes, usernameAvailable, getDocIdAndUpdateUserHandle, gameUrlPathForType } from "./firebase";
import { DropdownList } from "./Components/DropdownList"
import {Dropdown, Option} from "./Components/Dropdown"
import TextInput from "./Components/TextInput"
import user_image from "./Assets/avatar.svg";
import UserSingleton from "./UserSingleton"
import AsyncImage from './Components/AsyncImage';

import OngoingGamesList from "./Components/OngoingGamesList"

import HandleView from "./Components/HandleView"
import ConfirmationPopup from "./Components/ConfirmationPopup"

import Game from "./Models/Game"

import edit from "./Assets/edit.png"

function Profile() {

  const [user, loading, error] = useAuthState(auth);

  const [account, setAccount] = useState(undefined);
  const [userData, setUserData] = useState(undefined);

  const [showSupport, setShowSupport] = useState(false)
  const [showFAQ, setShowFAQ] = useState(false)


  const [showHandleModal, setShowHandleModal] = useState(false);
  const [handleAvailable, setHandleAvailable] = useState(true);


  const [handle, setHandle] = useState("")
  const [name, setName] = useState("")
  const [image, setImage] = useState("")
  const [balance, setbalance] = useState("0")
  const [record, setRecord] = useState("0 Wins - 0 Losses")
  const [ongoingGames, setOngoingGames] = useState(undefined)

  const history = useHistory();
  const instance = UserSingleton.getInstance()

    const [showConfirmation, setShowConfirmation] = useState(false)
    const [confirmationTitle, setConfirmationTitle] = useState("Success")
    const [confirmationBody, setConfirmationBody] = useState("Challenge Sent")


    const {getFirestore, query, getDocs, limit, orderBy, collection, doc, where} = require('firebase/firestore')


    useEffect(() => {
        if(loading)return;

        if (!user) return history.replace("/welcome");

        if(userData === undefined){
            return fetchUserData()

        }


        if(account === undefined){
            return fetchAccountData()
        }


        if(ongoingGames === undefined){

            return fetchGames()
        }






        async function fetchUserData(){

            let uData = await instance.getUser(user.uid)

            setUserData(uData);

            setViewableUserData(uData)
        }


        async function fetchAccountData(){

            let aData = await instance.getAccount(user.uid)

            setAccount(aData);
            setViewableAccountData(aData)
        }



        async function fetchGames(){

            let aData = await getOngoingGames()
            setOngoingGames(aData);
        }



    }, [user, loading, userData, account, ongoingGames]);



    const setViewableUserData = (user) =>{


        if(user !== undefined){
            let name = user.name
            let handle = user.handle
            let image = user.image
            let wins = user.wins
            let losses = user.losses

            let recordText = wins + " Wins - " + losses + " Losses"

            setRecord(recordText)
            setImage(image)
            setName(name)
            setHandle(handle)
        }

    }



    const setViewableAccountData = (account) =>{

        if(account !== undefined){
            let balance = account.balance
            setbalance(balance)
        }


    }




    async function getOngoingGames(){

        if(user === undefined){
            return
        }

        var tempGames = []

        for (const path of allGamePaths()){

            const gameRef = collection(db, path);

            const unacceptedQuery = query(gameRef, where("players", 'array-contains', userData.uid), where("accepted", '==' ,true));
            const myGamesQuery = query(gameRef, where("challengerUid", '==', userData.uid), where("accepted", '==' ,false));

            const snapshot = await getDocs(myGamesQuery)
            const snapshot2 = await getDocs(unacceptedQuery)

            snapshot.forEach(doc =>{

                let data = doc.data()
                let game = new Game(data)
                let uid = userData.uid

                //we don't want the games that we didn't start and we haven't accepted to show up here
                tempGames.push(game)
            });

            snapshot2.forEach(doc =>{

              let data = doc.data()
              let game = new Game(data)
              let uid = userData.uid

              //we don't want the games that we didn't start and we haven't accepted to show up here
              tempGames.push(game)

            })


        }

        setOngoingGames(tempGames)


        return tempGames
    }



    const handleUsernameUpdated = async(username) =>{

        let newUserData =  await instance.getUser(user.uid)

        setUserData(newUserData)

        setHandle(newUserData.handle)

        setConfirmationTitle("Success")
        setConfirmationBody("Username Updated")
        setShowConfirmation(true)
    }


    //Tidy little function to replace or append message depending if the inbox already has a message in the array
    function replaceOrAppendRequest(new_game){

        let tempGames = ongoingGames
        const gameIndex = tempGames.findIndex(
            (game) => game.gameId === new_game.gameId
        )
        if(gameIndex === -1){
            //append the game
            tempGames.push(new_game)
        }else{
            tempGames[gameIndex] = new_game
        }

        setOngoingGames(tempGames)
    }



    const profile = (uid) =>{

        console.log("Profile Selected: ", uid)
    }

    const resumeGame = (game) =>{

        let type = game.type
        let id = game.gameId

        let path = gameUrlPathForType(type, id)
        history.push(path)
    }



    return (

        <div className="account">


                <AsyncImage src={image === "" ? user_image : image} className="user__image" alt="Profile Image"/>


                <div className="account__container">

                    <div className="profile__info">

                        <div className="handles">
                            <div className="large__text">{name !== "" ? name : ""}</div>

                            <div className="horizontal__flex__center">
                                    <div className="medium__text__gray">{handle !== "" ? "@"+handle : ""}</div>
                                    <img className="edit__button" src={edit} onClick={() => setShowHandleModal(true)}/>
                            </div>

                        </div>


                        <div className="balance">
                            <div className="right__medium__text__gray">${balance}</div>
                            <button className="white__background__theme__button" onClick={() => history.replace("/balance")}>Add/Withdrawal</button>
                        </div>
                    </div>


                    <div className="record">
                        <div className="medium__text__gray">{record}</div>
                        <div className="small__theme__background__yellow__button" onClick={() => history.replace("/history")}>view history</div>
                     </div>

                    <div className="line"/>
                    <div className="medium__text__gray">Ongoing Games</div>

                    <div className="ongoing__profile__games__container">
                        <div className={(ongoingGames === undefined && ongoingGames === []) ? "large__text" : "hidden"}>No Ongoing Games</div>
                        {(ongoingGames !== undefined && ongoingGames !== []) && <OngoingGamesList data={ongoingGames} handleProfileSelected={(uid) => profile(uid)} handleResumeSelected={(game) => resumeGame(game)}/>}
                    </div>

                </div>

                {showHandleModal &&
                   <HandleView
                        userData={userData}
                        backdrop={true}
                        message=""
                        handleHidden={() => setShowHandleModal(false)}
                        updateHandle={(handle) => handleUsernameUpdated(handle)}
                    />}


                {showConfirmation &&
                    <ConfirmationPopup
                    handleClose={() => setShowConfirmation(false)}
                    positive="Close"
                    title={confirmationTitle}
                    body={confirmationBody}/>
                }

        </div>
    );
};

export default Profile;
