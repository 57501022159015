

function Game(data){
    
    
  let game = {

  
    accepted : false,
    betAmount : 0,
    betId : "",
    challengerHandle : "",
    challengerImage : "",
    challengerUid : "",
    customMessage : "",
    expires : new Date(),
    gameComplete : false,
    gameData : {},
    gameId : "",
    lastPlayed : "",
    maxPlayers : 2,
    minAppVersion : 1,
    name : "",
    noWager : false,
    opponentHandle : "",
    opponentImage: "",
    opponentUid : "",
    players : [],
    randomOpponent : false,
    shotClock : 0,
    trashTalk : "",
    turnReminderSent : false,
    type : 0,
    gameStarted:"",
    vsComputer : false,
    winner : "",

  }
    
  if(data !== undefined){
    
    
    
      
    game.accepted = data.accepted
    game.betAmount = data.betAmount
    game.betId = data.betId
    game.challengerHandle = data.challengerHandle
    game.challengerImage = data.challengerImage
    game.challengerUid = data.challengerUid
    game.customMessage = data.customMessage
    game.expires = data.expires
    game.gameComplete = data.gameComplete
    game.gameData = data.gameData
    game.gameId = data.gameId
    game.lastPlayed = data.lastPlayed
    game.maxPlayers = data.maxPlayers
    game.minAppVersion = data.minAppVersion
    game.name = data.name
    game.noWager = data.noWager
    game.opponentHandle = data.opponentHandle
    game.opponentImage = data.opponentImage
    game.opponentUid = data.opponentUid
    game.players = data.players
    game.randomOpponent = data.randomOpponent
    game.shotClock = data.shotClock
    game.trashTalk = data.trashTalk
    game.turnReminderSent = data.turnReminderSent
    game.type = data.type
    game.vsComputer = data.vsComputer
    game.winner = data.winner
    game.gameStarted = data.gameStarted


  }
    
    
    return game
    
    


};

export default Game;
    
    
