import React, {Component, useState, useEffect} from 'react';

function SnowmanLetter({guessedLetters, key, item, onSelected}){

  const [guessed, setGuessed] = useState(guessedLetters)


  useEffect(() =>{

    setGuessed(guessedLetters)

  }, [item, guessedLetters]);


  function handleClick() {
    // checks to see if the letter is enabled to be selected or deselected
    if(guessed === undefined){

      return
    }
     if(!guessed.includes(item.toLowerCase())){
          // calls "onLetterClick" from parent Board component
         onSelected(item.toLowerCase());
     }
  }

    // uses classnames module to put two classnames on a component
    const className = () => {

      if(guessed === undefined) return 'letter__not__guessed'
      if(guessed.includes(item.toLowerCase())) return 'letter__guessed'

      return 'letter__not__guessed'
    }


    return (
      <div onClick={() => handleClick()} className={className()}>
        <div className="bold__black__text">
            {item}
        </div>
      </div>
    )

};

export default SnowmanLetter;
