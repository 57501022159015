import styled from "styled-components";
import Modal from 'react-overlays/Modal';
import "../CSS/HandleView.css"
import React, { Component } from "react";
import {usernameAvailable, getDocIdAndUpdateUserHandle} from '../firebase'


        const HandleBackdrop = styled("div")`
            position: fixed;
            z-index: 1040;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000;
            opacity: 0.5;
            `;
        
        
        const HandleModal = styled(Modal)`
            position: fixed;
            width: 50vw;
            z-index: 1040;
            top: ${() => 40}%;
            left: ${() => 25}%;
            align-items:center;
            align-content:center;
            border: 1px solid #e5e5e5;
            background-color: var(--main);
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
            padding: 20px;
            border-radius: 15px;

            @media only screen and (max-width: 768px) {
                position: fixed;
                width: 88vw;
                z-index: 1040;
                top: ${() => 10}%;
                left:${0}%;
                padding-top:20px;
                padding-bottom:20px;
                border-radius: 15px;
                background-color: var(--main);
                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        }
        `;
        const renderBackdrop = (things) => <HandleBackdrop {...things} />;

export default class HandleView extends Component{

    constructor(props){
        super(props)
        
        
        this.handleHidden = props.handleHidden
        this.updateHandleSuccess = props.updateHandle
        
        this.state = {
            userData:props.userData,
            backdropDismiss:props.backdrop,
            message:props.message,
            handleAvailable:false,
            userHandle:"",
            userHandleMessage:"",
            checking:false
        }
        
        
        console.log(this.state)
    }

    
    render(){
        
        
        var tempHandle = "";

    
        const updateHandle = (han) =>{
            tempHandle = han
        }

        
        
        const hide = () =>{
            this.handleHidden()
        }
        
            
        const checkUserHandle = async() =>{
        
            this.setState({message:"Checking...", checking:true})

            if(tempHandle === ""){
                this.setState({message:"Username not available", handleAvailable:false, checking:false})
                return
            }
        
        
            let available = await usernameAvailable(tempHandle)

            console.log(tempHandle, " Available: ", available)

            if(available === true){
                this.setState({message:"Available, updating username", handleAvailable:true})
                
            }else{
                this.setState({message:"Username not available", handleAvailable:false, checking:false})
            }

            if(available === true){

                console.log(this.state.userData.name, "  ", this.state.userData.uid)

                await getDocIdAndUpdateUserHandle(tempHandle, this.state.userData.name, this.state.userData.uid)

                this.updateHandleSuccess()
                this.handleHidden(tempHandle)
            }
        }
        
        
        
        
        
        return (
                <HandleModal
                    show={true}
                    onHide={hide}
                    backdrop={this.state.backdropDismiss}
                    renderBackdrop={renderBackdrop}
                    aria-labelledby="modal-label"
                >
            
                <div className="center__handle__select__content">

                    <div className="card__title">Choose a Handle</div>
    
                    <div className={this.state.handleAvailable ? "handle__available" : "handle__not__available"}>{this.state.message}</div>

                    <input
                        rows={1}
                        placeholder="Aa"
                        className={this.state.checking ? "hidden" : "input__textbox"}
                        maxLength={60}
                        overflow-x='scroll'
                        onChange={(e) => updateHandle(e.target.value)}
                    />

                    <button className={this.state.checking ? "hidden" : "white__background__theme__button" }  onClick={checkUserHandle}>Check</button>
                        
                </div>
                </HandleModal>
        
        
        );

    }


}

