
import { db, auth, logout, getFCMToken} from "./firebase";
import { onAuthStateChanged } from "firebase/auth"
import { useAuthState } from "react-firebase-hooks/auth";
import UserModel from './Models/UserModel'
import Handle from './Models/Handle'
import Account from './Models/Account'




class PrivateUserSingleton {
    
    constructor() {    
    
        this.userData = undefined;
        this.userAccount = undefined;
        this.userHandle = undefined;

        this.userCache = {};
    }
    
    
    
//    EDBD947E-396A-4E31-8B3B-AC241F26B4D7
    start(uid) {
        this.attachAuthListener()
        this.attatchUserListener(uid)
        this.attatchHandleListener(uid)
        this.attatchAccountListener(uid)
        
        console.log("Starting LISTENERS")

    }
    

    setUserData(data){
        this.userData = data
    }
    
    setAccount(data){
        this.userAccount = data
    }
    
    setHandle(data){
        this.userHandle = data
    }
    
    clearUserCache(){
        this.userCache = {}
    }
    
    

    
    
    removeListeners(){
        
        if(this.accountListener !== undefined){
            this.accountListener();
        }
        
        if(this.userListener !== undefined){
            this.userListener();
        }
        
        if(this.handlePathListener !== undefined){
            this.handlePathListener();
        }

    }


    logout() {
        
        this.clearUserCache()
        this.removeListeners()
        
        this.setUserData(undefined)
        this.setAccount(undefined)
        this.setHandle(undefined)
    }
    
    
    async updateToken(token){
        
        if(token === undefined){
            return false
        }
        
        if(this.userData.token !== token){
            console.log("Updating TOKEN: ", token)

            const {doc, updateDoc} = require('firebase/firestore')

            let path = doc(db, "Users", this.userData.uid)
            let write = await updateDoc(path, {token: token})
        }
        

        return true
    }
    
    

    async attachAuthListener(){
        
        
        if(this.authListener !== undefined){
            return
        }

        this.authListener = onAuthStateChanged(auth, (user) => {
            
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                
                console.log("AUTH STATE CHANGED -- LOGGED IN")

                this.attatchAccountListener(uid)
                this.attatchUserListener(uid)
                this.attatchHandleListener(uid)
                
                
            } else {
                console.log("AUTH STATE CHANGED -- LOGGED OUT")
                
                this.logout()
                
            }
        });
 
    }
    
    
  //this attatches a listener to listen when user data changes
   async attatchUserListener(id) {
       
        if(this.userListener !== undefined){
            return
        }
        const {doc, collection, onSnapshot} = require('firebase/firestore')
        

        
       
        let userRef = doc(db, "Users", id)
        try {
            
            this.userListener = await onSnapshot(userRef, (document) => {

                const data = document.data();
            
                if(document !== undefined && data !== undefined){
//                    console.log("User Fetched Via Listener: ", data)
                    
                    let user = new UserModel(data)
                    
                    this.setUserData(user)
                    this.userCache[id] = user

                }else{

                    this.setUserData(undefined)
                }                
                
            });                   
                                                   
        } catch (err) {
            console.error(err);
            this.setUserData(undefined)
        }
    };
    
    

    
  //this attatches a listener to listen when user data changes
    async attatchAccountListener(id){
        
        if(this.accountListener !== undefined){
          return;
        }
        const {doc, collection, onSnapshot} = require('firebase/firestore')
      
        let accountsRef = doc(db, "Accounts", id)
        
        try {
            this.accountListener = onSnapshot(accountsRef, (document) => {
          
                const data = document.data();
                if(document !== undefined && data !== undefined){
//                    console.log("User Account Fetched: ", data)
                    let account = new Account(data)
                    
                    this.setAccount(account) 
                }
            });                                 
                                                   
        } catch (err) {
          console.error(err);
        }
    
  };
    
    
    async attatchHandleListener(id){
        
        if(this.handlePathListener !== undefined){
          return
        }
        
        const {query, where, limit, collection, onSnapshot} = require('firebase/firestore')
        
        const handleRef = collection(db, "Handles");
    
        const q = query(handleRef, where("uid", "==", id), limit(1));
        try {
            this.handlePathListener = await onSnapshot(q, (qSnap) => {
                
                
                qSnap.forEach(change =>{
                    
                    
                    if(change !== undefined && change.doc !== undefined)    {
                    
                   
                        const data = change.doc.data();
//                        console.log("HANDLE LISTENER DATA: ", data)
                        
                        if(data !== undefined){
                            console.log("User Handle Fetched: ", data)
                            let handle = new Handle(data)
                            this.setHandle(handle)

                        }
                    }
                })
            });                                 
                                                   
        } catch (err) {
            console.error(err);
        }
    };
        
    

    
   async fetchUserOnce(id) {
  
        const {doc, getDoc} = require('firebase/firestore')
       
        let usersRef = doc(db, "Users", id)
       
       
        let document = await getDoc(usersRef)

        const data = document.data();

            
        if(doc !== undefined && data !== undefined){
            console.log("User Fetched Once: ", data)
            let user = new UserModel(data)
            return user
        }  else{

            return new UserModel(undefined)
        }                                                      
    };
    
    
    
    
    
   async fetchAccountOnce(id) {
       
        const {doc, getDoc} = require('firebase/firestore')
  
        let accountsRef = doc(db, "Accounts", id)
       
        try{
            let document = await getDoc(accountsRef)
            
            let data = document.data()
            if(doc !== undefined && data !== undefined){
                console.log("Account Fetched Once: ", data)

                let account = new Account(data)
                return account
            }  else{

                return new Account(undefined)
            }  
            
        }catch (err) {
            console.error(err);
            return new Account(undefined)
        }

                                                    
    };
    
    
    
    
        
    async fetchHandleOnce(id){
            const {where, limit, collection, query, getDocs} = require('firebase/firestore')
        
            const handleRef = collection(db, "Handles");
            const q = query(handleRef, where("uid", "==", id), limit(1));
        
        try{
            let qSnap = await getDocs(q)
            qSnap.forEach(change =>{
                
                
                if(change !== undefined && change.doc !== undefined)    {
                    const data = change.doc.data();
                    if(data !== undefined){
                        console.log("Handle Fetched Once: ", data)
                        
                        var handle = new Handle(data);
                        return handle
                    }
                    
                }  else{

                    return new Handle(undefined)
                }

            })    
                
        }catch (err) {
            console.error(err);
            return new Handle(undefined)
        }
                               
    };
    


        
    
    /*
        LOAD Games
    */
    async queryRecentGames(uid, size){
        
        if(uid === undefined){
            return
        }
        const {where, limit, orderBy, collection, query, getDocs} = require('firebase/firestore')
        
        const archiveRef = collection(db, "Archives");
    
        const q = query(archiveRef, where("players", 'array-contains', uid), orderBy('gameOverTimestamp', 'desc'), limit(size));
        
        var arrayToAdd = []
        
        try{
            
            let querySnapshot = await getDocs(q)
            querySnapshot.forEach(doc => {
                
                let data = doc.data()
                let id = doc.id
                arrayToAdd.push(data)

            })
            
        }catch (err) {
            console.error(err);
            return new Handle(undefined)
        }
        
        

        
        
        return arrayToAdd
    }  
    
    
    
    async getUser(id){
        
        if(this.userData !== undefined){
            return this.userData;
        }else{
            return await this.fetchUserOnce(id)
        }
    }
    
    
    
    async getAccount(id){
        
        if(this.userAccount !== undefined){
            return this.userAccount;
        }else{

            return await this.fetchAccountOnce(id)
        }
    }
    
    async getHandle(id){

        if(this.userHandle !== undefined){
            return this.userHandle;
        }else{
            
            return await this.fetchHandleOnce(id)
        }
    }
    
    
        
    
    async getUserDataFor(id){
        
        if(id === undefined || id === ""){
            return new UserModel(undefined)
        }

        
        if(this.userCache[id] === undefined){

            let user = await this.fetchUserOnce(id)
            this.userCache[id] = user
            return user;
        }else{

            return this.userCache[id]
        }
        
    };
    
    
    
    async getFriends(user){
        
        console.log("GET FRIENDS")
        
        if(user === undefined || user.friends){
            return []
        }
    
        
        let friendsList = user.friends
        
        
        var users = []
        for(const uid of friendsList){

            let user = await this.getUserDataFor(uid)
            users.push(user)
        }
        
        
        return users
    }
    
}


class UserSingleton {
    constructor() {
        throw new Error('Use Singleton.getInstance()');
    }
    static getInstance() {
        if (!UserSingleton.instance) {
            UserSingleton.instance = new PrivateUserSingleton();
        }
        return UserSingleton.instance;
    }

    
    
}
export default UserSingleton;
