import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import "./CSS/Home.css";
import "./CSS/Theme.css";
import Switch from "./Components/Switch";
import {getFCMToken, archivesPath, auth, db, logout, usernameAvailable, getDocIdAndUpdateUserHandle, allGameTypes, gamesWithTurnTimers, submitChallenge, getFriends, removeFriend, addFriendRequest, gameUrlPathForType, currentPlayableGameTypes } from "./firebase";

import { DropdownList } from "./Components/DropdownList"
import {Dropdown, Option} from "./Components/Dropdown"
import TextInput from "./Components/TextInput"
import RecentGamesList from "./Components/RecentGamesList"
import UserSingleton from "./UserSingleton"
import ScrollBox from "./Components/ScrollBox"
import FriendSelectView from "./Components/FriendSelectView"
import GAMETYPES from "./Models/game__types.json"

import GameCard from "./Components/GameCard"
import ProfileView from "./Components/ProfileView"
import ChallengeView from "./Components/ChallengeView"
import HandleView from "./Components/HandleView"
import ConfirmationPopup from "./Components/ConfirmationPopup"
import SetTurnTimerPopup from "./Components/SetTurnTimerPopup"
import Loading from "./Components/Loading"

import styled from "styled-components";
import Modal from 'react-overlays/Modal';
import Chess from './Assets/chess.png'
import Checkers from './Assets/checkers.png'
import Trivia from './Assets/trivia.png'
import WordUp from './Assets/wordup.png'
import Match4 from './Assets/connect4.png'
import Snowman from './Assets/snowman.png'
import XsOs from './Assets/tictactoe.png'




function Home() {

    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserData] = useState(undefined);
    const [account, setAccount] = useState(undefined);
    const [worldButtonSelected, setWorldButtonSelected] = useState(true)

    const [showHandleModal, setShowHandleModal] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);

    const history = useHistory();

    const [viewingProfile, setViewingProfile] = useState(undefined);
    const [viewingProfileRecentGames, setViewingProfileRecentGames] = useState(undefined);

    const [friendData, setFriendData] = useState(undefined);
    const [worldData, setWorldData] = useState(undefined);




    /**************************************
    *
    * START
    *    --CHALLENGE POPUP STATE VARIABLES
    *************************************/


    const [showChallengeModal, setShowChallengeModal] = useState(false);
    const [directUserChallenge, setDirectUserChallenge] = useState(false)
    const [startShowScrollbox, setStartShowScrollbox] = useState(false)
    const [showTurnTimer, setShowTurnTimer] = useState(false)
    const [allowOpponentChange, setAllowOpponentChange] = useState(false)
    const [gameTypeSelected, setGameTypeSelected] = useState(false)


    const [showConfirmation, setShowConfirmation] = useState(false)
    const [confirmationTitle, setConfirmationTitle] = useState("Success")
    const [confirmationBody, setConfirmationBody] = useState("Challenge Sent")

    const [showGetReadyPopup, setShowGetReadyPopup] = useState(false)
    const [getReadyTitle, setGetReadyTitle] = useState("Challenge Sent!")
    const [getReadyBody, setGetReadyBody] = useState("You can play your turn while waiting for your opponent to accept the challenge")
    const [selectedChallenge, setSelectedChallenge] = useState(undefined)

    const {query, limit, collection, onSnapshot, orderBy, getDocs} = require('firebase/firestore')

    /**************************************
    *
    * END
    *    --CHALLENGE POPUP STATE VARIABLES
    *************************************/

    const instance = UserSingleton.getInstance()



    useEffect(() => {

        if(loading)return;
        if (!user) return history.replace("/welcome");


        const queryParams = new URLSearchParams(window.location.search)
        if(queryParams !== undefined){

            const rematch = queryParams.get("rematch")
            const type = queryParams.get("type")
            const opponent = queryParams.get("opp")


            if(rematch !== null){
               setGameTypeSelected(type)

                let opponentData = fetchUserDataForChallenge(opponent)

            }

        }

        if (userData === undefined){
            console.log("Fetching User Data")
            return fetchMyUserData()
        }

        if(worldData === undefined){

            return fetchWorldGameData()
        }

        if(friendData === undefined){

            return fetchFriendGameData()
        }



        async function fetchUserDataForChallenge (id){
            let data = await instance.getUser(id)
            setViewingProfile(data)
            handleRematch(data)
            return data

        }

        async function fetchMyUserData(){

            let data = await instance.getUser(user.uid)
            let accountData = await instance.getAccount(user.uid)

            let token = await getFCMToken()

            if(token === 'request'){

                Notification.requestPermission()

            }else if(token !== 'error'){

                instance.updateToken(token)
            }


            if(data.handle === ""){
                setShowHandleModal(true)
            }

            setAccount(accountData)
            setUserData(data)
        }


        async function fetchWorldGameData(){

            if (userData !== undefined) {

                console.log("Fetching Game Data")

                let games = await startQuery(false)
                setWorldData(games)
            }
        }

        async function fetchFriendGameData(){

            if (userData !== undefined) {

                let games = await startQuery(true)
                setFriendData(games)
            }
        }

    }, [user, loading, userData, worldData]);








    /*
        LOAD Games
    */
    const startQuery = async (friends) => {

        console.log("Gettings Friends? ", friends)


        const friendRequestRef = collection(db, archivesPath);

        const friendQuery = query(friendRequestRef, orderBy('gameOverTimestamp', 'desc'),limit(50));
        const worldQuery = query(friendRequestRef, orderBy('gameOverTimestamp', 'desc'), limit(50));


        const finalQuery = friends ? friendQuery : worldQuery

        let querySnapshot = await getDocs(finalQuery)

        console.log("Gettings Friends? ", friends)
        console.log("UserData ", userData)


        var recentGames = []

        querySnapshot.forEach(doc => {

            let data = doc.data()
            let id = doc.id

            if(friends){

                if(userData !== undefined && userData.friends !== undefined && (userData.friends.includes(data.challengerUid) || userData.friends.includes(data.opponentUid))){

                    recentGames.push(data)
                }
            }else{
                //world query
                recentGames.push(data)
            }

        }, err => {
            console.log(`Recent Games Query: ${err}`);
        });


        return recentGames

    }




    const handleFriendsSelected = async() =>{
        setWorldButtonSelected(false)

        if(friendData === undefined || friendData.length ===0){

            let games = await startQuery(true)

            console.log("FRIEND GAMES: ", games)
            setFriendData(games)
        }


    }

    const handleWorldSelected = async() =>{
        setWorldButtonSelected(true)


        if(worldData === undefined || worldData.length ===0){

            let games = await startQuery(false)

            console.log("FRIEND GAMES: ", games)
            setWorldData(games)
        }

    }





    const handleFriendRequest = async(uid)=>{

        await addFriendRequest(uid, userData)

        setShowProfileModal(false)

        setConfirmationTitle("Success")
        setConfirmationBody("Friend Request Sent")
        setShowConfirmation(true)
    }



    const handleRemovingFriend = async(uid)=>{

        let user = await removeFriend(uid, userData)
        setUserData(user)

        setShowProfileModal(false)

        setConfirmationTitle("Success")
        setConfirmationBody("Friend Removed")
        setShowConfirmation(true)
    }



    //Handles showing the Profile popup for the selected user
    const profileSelected = async(uid) =>{

        let user = await instance.getUserDataFor(uid)
        let recentData = await instance.queryRecentGames(uid, 5)

        setViewingProfileRecentGames(recentData)
        setViewingProfile(user)

        setShowProfileModal(true)
    }











    /**************************************
    *
    *START
    *
    *
    *    --CHALLENGE POPUP CALLBACKS
    *************************************/

    //handles challenge chosen from the selected users profile
    const handleChallenge = (user)=>{
        setShowProfileModal(false)
        setDirectUserChallenge(true)
        setStartShowScrollbox(true)
        setAllowOpponentChange(false)
        setShowTurnTimer(false)
        setShowChallengeModal(true)

    }

    const handleRematch = (user)=>{
        setShowProfileModal(false)
        setDirectUserChallenge(true)
        setStartShowScrollbox(false)
        setAllowOpponentChange(false)
        setShowTurnTimer(false)
        setShowChallengeModal(true)

    }

    //Handles game selected from scrollbox
    const gameSelected = (type) => {

        console.log(type)


        let current = currentPlayableGameTypes()
        if(!current.includes(Number(type))){
            setConfirmationBody("This game is not yet available")
            setConfirmationTitle("Coming Soon!")
            setShowConfirmation(true)
            return
        }


        setDirectUserChallenge(false)
        setGameTypeSelected(type)
        setAllowOpponentChange(true)
        setStartShowScrollbox(false)

        let turnTimerGames = gamesWithTurnTimers()

        setShowTurnTimer(turnTimerGames.includes(type))


        setShowChallengeModal(true)

    }



    //Handles creating challenge and writing it to the database
    const handleSendChallenge = async(challenge) =>{


        if(challenge.betAmount < account.balance){
            setConfirmationTitle("Oops")
            setConfirmationBody("You do not have enough funds for this challenge, Add funds from your Settings or Profile")

            setShowConfirmation(true)
            return
        }

        let res = await submitChallenge(challenge)
        console.log(res)

        setSelectedChallenge(res.challenge)
        setShowChallengeModal(false)

        if(!res.success){
            setConfirmationTitle("Oops")
            setConfirmationBody("Something went wrong, try again")

            setShowConfirmation(true)
        }


        console.log("SEND CHALLENGE: ", res)

        let type = res.challenge.gameType

        let current = currentPlayableGameTypes()
        //TODO update on game added
        if(current.includes(Number(type))){

            setGetReadyTitle("Success! Ready to start?")

            setGetReadyBody("You can play your round before someone accepts the challenge")
            setShowGetReadyPopup(true)


        }


    }



    const handleUsernameUpdated = () =>{

        setConfirmationTitle("Success")
        setConfirmationBody("Username Updated")
        setShowConfirmation(true)

    }



    const handleGetReadySelected = () =>{


        setShowGetReadyPopup(false)

        if(!selectedChallenge){

            setConfirmationTitle("Oops")
            setConfirmationBody("Something went wrong, try again")

            setShowConfirmation(true)

            return
        }

        console.log("Selected Challenge: ", selectedChallenge)
        let gameId = selectedChallenge.startedGameId
        let type = selectedChallenge.gameType


        let path = gameUrlPathForType(Number(type), gameId)

        history.push(path)


    }


    const empty = () =>{
        //do  nothing
    }

    /**************************************
    *
    *END
    *
    *
    *    --CHALLENGE POPUP CALLBACKS
    *************************************/

  return (
    <div className="home">

      <div className="home__container">

        <h1 className="title__yellow">Start a Game!</h1>

        <ScrollBox
            children={GAMETYPES.map(({name, id}) => (
                <GameCard type={id} name={name} handleSelected={(gt) => gameSelected(gt)}/>
            ))}
            show={true}
        />


        <div className="recent__games__header">

                <div className="bold__white__large__text">Recent Games</div>

                <div className="switch__button__container__home">

                    <button className={worldButtonSelected === false ? "button__selected__home" : "button__not__selected__home"} onClick={handleFriendsSelected}>Friends</button>
                    <button className={worldButtonSelected === true ? "button__selected__home" : "button__not__selected__home"} onClick={handleWorldSelected}>World</button>

                </div>
        </div>



        <div className="home__recent__games__container">

             <div className={(worldButtonSelected && (worldData === undefined || worldData === [])) ? "empty__list__text" : "hidden"}>No Recent Games</div>

            {(worldButtonSelected && worldData !== undefined && worldData !== []) &&
             <RecentGamesList handleProfileSelected={(uid) => profileSelected(uid)} data={worldData} handleCellSelected={() => empty()}  />}

             <div className={(!worldButtonSelected && (friendData === undefined || friendData === [])) ? "empty__list__text" : "hidden"}>No Recent Games</div>

            {(!worldButtonSelected && friendData !== undefined && friendData !== []) &&
             <RecentGamesList handleProfileSelected={(uid) => profileSelected(uid)} data={friendData} handleCellSelected={() => empty()} />}

        </div>

      </div>


        {showChallengeModal &&
            <ChallengeView
                handleHidden={() => setShowChallengeModal(false)}
                myProfile={userData}
                theirProfile={viewingProfile}
                gameType={gameTypeSelected}
                showScrollbox={startShowScrollbox}
                showTurnTimer={showTurnTimer}
                directChallenge={directUserChallenge}
                allowOpponentToggle={setAllowOpponentChange}
                handleChallenge={(challenge) => handleSendChallenge(challenge)} />
        }

      {showHandleModal &&
       <HandleView
            userData={userData}
            backdrop='static'
            handleHidden={() => setShowHandleModal(false)}
            handleUsernameUpdated={(h) => handleUsernameUpdated(h)}
            />
      }


        {showProfileModal &&
       <ProfileView
            hideChallengeButton={false}
            hideFriendRequestButton={false}
            myProfile={userData}
            theirProfile={viewingProfile}
            profileRecentGameData={viewingProfileRecentGames}
            handleChallenge={(user) => handleChallenge(user)}
            handleRequest={(uid) => handleFriendRequest(uid)}
            handleRemoveFriend={(uid) => handleRemovingFriend(uid)}
            handleHidden={() => setShowProfileModal(false)}/>
        }


        {showConfirmation &&
         <ConfirmationPopup
            handleClose={() => setShowConfirmation(false)}
            positive="Close"
            title={confirmationTitle}
            body={confirmationBody}/>
        }

        {showGetReadyPopup &&
         <ConfirmationPopup
            handleClose={handleGetReadySelected}
            positive="I'm Ready!"
            title={getReadyTitle}
            body={getReadyBody}/>
        }
    </div>
  );
}

export default Home;
