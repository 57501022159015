import React from 'react';
import '../CSS/ChallengeListCell.css';
import Bet from '../Models/Bet';
import default_avatar_image from "../Assets/avatar.svg";

import hourglass from '../Assets/hourglass.svg'
import stopwatch from '../Assets/stopwatch.svg'
import dollar from '../Assets/dollar.svg'

import AsyncImage from './AsyncImage';


const ChallengeListCell = ({handleProfileSelected, handleGameAccepted, handleGameDeclined, gameData, myUid}) => {

    let bet = new Bet(gameData)

    let betAmount = bet.amount
    let gameType = bet.gameType
    let shotClock = bet.shotClock

    let opponentUid = bet.opponentUid
    let opponentImage = bet.opponentImage
    let opponentHandle = bet.opponentHandle

    let challengerImage = bet.challengerImage
    let challengerUid = bet.challengerUid
    let challengerHandle = bet.challengerHandle

    let random = bet.randomOpponent

    let expires = bet.expires

    const calculateRemainingTime = () =>{

        let expireSeconds = expires.seconds
        let now = new Date()
        let nowInSeconds = now.getTime()/1000

        let remainingSeconds = expireSeconds - nowInSeconds

        let minutes = Math.trunc(remainingSeconds / 60)
        let hours = Math.trunc(remainingSeconds / 3600)
        let minHourRemainder = Math.trunc(minutes % 60)

        var time = ""

        if(hours === 1){

            time = hours + " hr " + minHourRemainder + " m"

        }else if(hours > 0){

            time = hours + " hrs"
        }else{
            time = minutes + " m"
        }

        return time
    }


    let randomOpponent = bet.randomOpponent

    var displayImage, displayName = ""

    if(randomOpponent || challengerUid !== myUid){

        displayImage = challengerImage
        displayName = challengerHandle

    }else{
        displayImage = opponentImage
        displayName = opponentHandle
    }


    let itsMe = gameData.challengerUid === myUid


    const nameForGameType = (type) => {


        var name;
            switch (type) {
                case "0":
                    name =  "None"
                    break
                case "1":
                    name =  "Snowman"
                    break
                case "2":
                    name =  "X's O's"
                    break
                case "3":
                    name =  "Match 4"
                    break
                case "4":
                    name =  "Word Up"
                    break
                case "5":
                    name =  "Checkers"
                    break
                case "6":
                    name =  "Chess"
                    break
                case "7":
                    name =  "Trivia"
                    break
                default:
                    name =  "None"
                    break
            }
            return name




    }

    var gameName = nameForGameType(String(gameType))



    return(
        <div className="challenge__cell__container">


            <div className="challenge__top__info__view">


                <div className="left__center__aligned">
                    <div className="challenge__cell__profile__image" onClick={() => handleProfileSelected(bet)}>
                        <AsyncImage draggable="false" src={(displayImage !== "" && displayImage !== undefined) ? displayImage : default_avatar_image} className="challenge__cell__profile__image__constraint"/>
                    </div>

                    <div className="challenge__profile__handle__type">
                        <div className="title__yellow">{gameName}</div>
                        <div className="medium__text__gray">@{displayName}</div>
                    </div>
                </div>



                <div className="right__top__aligned">
                    {(itsMe || !randomOpponent) && <button className="theme__background__gray__button"  onClick={() => handleGameDeclined(bet)}>{itsMe ? "Remove" : "Decline"}</button>}
                </div>

            </div>



            <div className="challenge__bottom__info__container">

                <div className="challenge__detail__info">

                    <div className="challenge__details__image__container">
                        <AsyncImage draggable="false" src={dollar} className="challenge__details__image"/>
                    </div>

                    <div className="cell__text">{betAmount}</div>

                </div>

                <div className="challenge__detail__info">

                    <div className="challenge__details__image__container">
                        <AsyncImage draggable="false" src={stopwatch} className="challenge__details__image"/>
                    </div>

                    <div className="cell__text">{shotClock}</div>
                </div>


                <div className="challenge__detail__info">
                    <div className="challenge__details__image__container">
                        <AsyncImage draggable="false" src={hourglass} className="challenge__details__image"/>
                    </div>

                    <div className="cell__text">{calculateRemainingTime()}</div>
                </div>


                <button className={itsMe ? "hidden" : "white__background__theme__button__no__margin"}onClick={() => handleGameAccepted(bet)}>Accept</button>
            </div>


        </div>

    )



}


export default ChallengeListCell;
