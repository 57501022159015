import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import styled from "styled-components";
import Avatar from '../../Assets/avatar.png'

import "./Match4.css";
import "../../CSS/Theme.css";

import ConfirmationPopup from "../../Components/ConfirmationPopup"
import AsyncImage from "../../Components/AsyncImage"
import UserSingleton from "../../UserSingleton"

import Match4Board from './Match4Board'
import GameModel from "../../Models/Game"
import GameOverPopup from "../../Components/GameOverPopup"

import {auth, db, gamePathForType, getArchivedGame} from "../../firebase";


function Match4() {
  //SUBJECT TO CHANGE
  const player1Color = "#0D613B"
  const player2Color = "#DFC22E"
  const emptyColor = "#F6F4EB"

  const boardArraySize = 6 * 7

  const [board, setBoard] = useState(new Array(boardArraySize).fill(emptyColor));

  const [game, setGame] = useState(undefined);
  const [gameState, setGameState ] = useState(undefined);
  const [gameId, setGameId] = useState(undefined);

  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  const instance = UserSingleton.getInstance()

  var gameListener = undefined

  const [showGameOver, setShowGameOver] = useState(false)

  const [showIsTiePopup , setShowIsTiePopup] = useState(false)
  const [showIsTiePopupAction , setShowIsTiePopupAction] = useState(false)

  const confirmationTitle= "Stalemate!"
  const confirmationBody = "There must be a winner. Positions will be swapped and the game restarted."

  const [currentQuestion, setCurrentQuestion] = useState(undefined)
  const [finalResults, setFinalResults] = useState(false)
  const [timeRemaining, setTimeRemaining] = useState(90)

  const {doc, onSnapshot, updateDoc, getDoc} = require('firebase/firestore')


  const [turn, setTurn] = useState("#0D613B")


  useEffect(() => {

    if (loading) return;
    if (!user) history.replace("/welcome");


    const queryParams = new URLSearchParams(window.location.search)
    const paramGameId = queryParams.get("id")
    const archived = queryParams.get("archived")


    if(gameId === undefined && gameListener === undefined && archived === null){

        setGameId(paramGameId)
        console.log("Game Id Set, will attatch listener")
        attachGameListener(paramGameId)

    }else if (gameId === undefined && archived !== null){

        setGameId(paramGameId)
        getArchive(paramGameId)
    }

  },[user, loading, gameId, game])




   async function attachGameListener(gameId){

        if(gameListener !== undefined){
            return
        }

        const gameRef = doc(db, "Connect4Games", gameId);

        gameListener = onSnapshot(gameRef, (doc) => {

            const data = doc.data();

            if(doc !== undefined && data !== undefined){
                setGame(data)
                setGameState(data.gameData)
                checkAndSetUIState(data)
            }else{

               setGame(undefined)
               setGameState(undefined)
            }
        });
    }



    async function getArchive(gameId){

        let game = await getArchivedGame(gameId)

        if(game === false){
            return
        }

        checkAndSetUIState(game)
    }




    function isGameOver(gameData){

        if(gameData === undefined){
            return false
        }


        if(gameData.gameComplete === true){
            return true

        }
    }


    function isMyTurn(currState){

        if(currState[user.uid] === currState.turn){
          return true
        }
    }

   async function checkAndSetUIState(gameData){

        let gameOver = isGameOver(gameData)

        if(gameOver){
            console.log("Game Over, winner is: ", gameData.winner)

            if(gameData.winner === ""){

                //its a stale mate, switch users and start another game.
                if(isMyTurn(gameData)){
                    setShowIsTiePopupAction(true)
                }
                return
            }

            if(gameListener !== undefined){
                gameListener()
            }
            setShowGameOver(true)
        }


        let gameState = gameData.gameData
        let gameId = gameData.gameId
        let betId = gameData.betId
        let type = gameData.type
        let gamePath = gamePathForType(type)
        let uid = user.uid

        console.log('GAME DATA: ', gameData)


        //initialize players
        if(gameState.greenPlayer === undefined && (gameData.gameStarted === "" || gameData.gameStarted === undefined)){
            //we need to setup the game
            //set expires to 10 second countdown
            console.log('Initiating Board')
            var initGameData = {turn:1, greenPlayer:uid, board:new Array(boardArraySize).fill(0), turnsTaken:0}
            initGameData[uid] = 1

            const path = doc(db, gamePath, gameId)
            return updateDoc(path, {gameData:initGameData, gameStarted:uid})


        }else if(gameState.yellowPlayer === undefined && gameState.greenPlayer !== undefined && gameState.greenPlayer !== uid){

            let updateGameState = gameState
            console.log('Adding Yellow Player')

            updateGameState[uid] = 2
            updateGameState.yellowPlayer = uid

            const path = doc(db, gamePath, gameId)

            return updateDoc(path, {gameData:updateGameState})
        }

        console.log('Updating UI with', gameState)


       let board = gameState.board
       if(board !== undefined){

           let color = gameState.turn
           if(color === undefined) return;


            let colorBoard = numbersToColorBoard(board)
            setTurn(color)
            setBoard(colorBoard)

       }
    }





  function myColor(){

      if(game.gameData === undefined){
          return ''
      }

      let color = game.gameData[user.uid]
      if(color !== undefined){
          return color

      }
  }






 async function submitMove(props){

    if(game === undefined) return;
    let gamePath = gamePathForType(game.type)
    const path = doc(db, gamePath, game.gameId)


    let move = game.gameData
    move.board = props.newBoard
    move.turn = props.turn

    var moves = move.turnsTaken + 1
    move.turnsTaken = moves

    let updates  = {gameData: move}

    let overState = getGameOverState(props.win, props.winner)

    if(overState !== 'none'){

        updates.gameComplete = true

        if(overState === 'winner'){
            updates.winner = user.uid
        }else{

            setShowIsTiePopup(true)
        }
    }
    console.log("Submitting Move: ", updates)


    await updateDoc(path, updates, {merge:true})

 }



   function getGameOverState(win, winner){

        if(!win) return 'none'

        if(winner !== 0){
            return 'winner'
        }else{

           return 'draw'

        }
        return 'none'

  }



  async function resetBoardSwitchPlayers(gameState){

        if(game === undefined) return;
        let gamePath = gamePathForType(game.type)
        const path = doc(db, gamePath, game.gameId)

        let initGreen = gameState.greenPlayer
        let initYellow = gameState.yellowPlayer

        gameState[initGreen] = 2
        gameState[initYellow] = 1

        gameState.greenPlayer = initYellow
        gameState.yellowPlayer = initGreen

        gameState.board = new Array(boardArraySize).fill(0);
        gameState.turn = 1

        await updateDoc(path, gameState, {merge:true})
        setShowIsTiePopupAction(false)
    }



  function colorForTurn(){
    if(gameState === undefined)
    {
      return "Green's Turn"
    }
      let color = gameState.turn
      if(color === 1){
          return "Green's Turn"
      }
      return "Yellow's Turn"
  }

  function colorHexForNumber(num){

    if(num === 1)
    {
      return player1Color
    }else{
      return player2Color
    }
  }

  const rematch = (opponentUid) => {

      let string = 'home?rematch=true&type=6&opp=' + opponentUid
      history.replace(string)
  }



  const titleColorForPlayer = (uid) =>{
      if(game === undefined){
          return ''
      }

      if(gameState === undefined){
          return ''
      }

      if(gameState[uid] === 1){
        return "Green"
      }
      return "Yellow"
  }





  function numbersToColorBoard(board){

    var newBoard = []
    for(const item of board){
      if(item === 0){
        newBoard.push(emptyColor)
      }else if(item === 1){
        newBoard.push(player1Color)
      }else if(item === 2){
        newBoard.push(player2Color)
      }
    }
    return newBoard
  }

  return (
      <div className="chess">

             <div className="top__info__view">

                <div className="vertical__auto">
                    <div className="medium__text">{game && titleColorForPlayer(game.challengerUid)}</div>
                    <div className="opponent__info__container">
                        <AsyncImage className="profile__image" src={ (game && game.challengerImage !== "") ? game.challengerImage : Avatar} />
                        <div className="handle__text">{game && game.challengerHandle}</div>
                        <div className="spacer__horizontal"/>

                    </div>
                </div>


                <div className="vertical__auto">
                   <div className="medium__text">{game && titleColorForPlayer(game.opponentUid)}</div>
                    <div className="opponent__info__container">
                        <AsyncImage className="profile__image" src={(game && game.opponentImage !== "") ? game.opponentImage : Avatar} />
                        <div className="handle__text">{game && game.opponentHandle}</div>
                        <div className="spacer__horizontal"/>

                    </div>
                </div>

            </div>

            <div className="horizontal__flex__center">
                <div className="large__yellow">{colorForTurn()}</div>
            </div>


            <div className="board__frame">
                {game && gameState && <Match4Board tTurn={turn} tBoard={board} activePlayer={gameState && gameState[user.uid] && gameState[user.uid]} onMove={(props) => submitMove(props)} />}
            </div>


            {(showIsTiePopupAction && game) &&
                <ConfirmationPopup
                    handleClose={() => resetBoardSwitchPlayers()}
                    positive="Reset"
                    title={confirmationTitle}
                    body={confirmationBody}
                />
            }

            {(showIsTiePopup && game) &&
                <ConfirmationPopup
                    handleClose={() => setShowIsTiePopup(false)}
                    positive="Ok"
                    title={confirmationTitle}
                    body={"Your Opponent will see a similar message and reset the board."}
                />
            }

            {(showGameOver && gameState) &&
                <GameOverPopup
                    currentUser={user}
                    handleHidden={() => setShowGameOver(false)}
                    handleRematch={(opp) => rematch(opp)}
                    game={game}
                />
            }


      </div>

  )


}

export default Match4;
