import React from 'react';
import '../CSS/FriendsList.css';
import FriendsListCell from './FriendsListCell';




const FriendsList = ({handleSelected, handleHidden, data}) => {
    
    const final = [];

    for(let user of data){

        if(user !== undefined ){
            final.push(<FriendsListCell key={user.uid} data={user} profileSelected={(user) => handleSelected(user)}/>);
        } 
    }


    return (
    
        <div className="friends__table__container">
            {final}
        </div>
    )
    
}



export default FriendsList;