import styled from "styled-components";
import Modal from 'react-overlays/Modal';
import React,{ Component} from 'react'

import "../CSS/Theme.css"
import "../CSS/GameOverPopup.css"
import user_image from "../Assets/avatar.svg";
import Bet from "../Models/Bet"
//import React, { useEffect, useState } from "react";
import ScrollBox from "./ScrollBox"
import GAMETYPES from "../Models/game__types.json"

import Switch from "./Switch"

import Chess from '../Assets/chess.png'
import Checkers from '../Assets/checkers.png'
import Trivia from '../Assets/trivia.png'
import WordUp from '../Assets/wordup.png'
import Match4 from '../Assets/connect4.png'
import Snowman from '../Assets/snowman.png'
import XsOs from '../Assets/tictactoe.png'

import TriviaResults from '../Games/Trivia/TriviaResults'

import {gamesWithTurnTimers, getFriends, postTrashTalk} from '../firebase'
import UserSingleton from '../UserSingleton'
import Loading from './Loading'


const GameOverBackdrop = styled("div")`
            position: fixed;
            z-index: 1040;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000;
            opacity: 0.5;
        `;


const GameOverViewModal = styled(Modal)`
                    position: fixed;
                    width: 40vw;
                    max-height:90%;
                    z-index: 1040;
                    top: ${() => 5}%;
                    left: ${() => 30}%;
                    border-radius: 15px;
                    background-color: var(--main);
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
                    padding-top:10px;
                    padding-bottom:30px;
                    padding-left:2.5vw;
                    padding-right:2.5vw;
                    overflow-y:auto;

                    @media only screen and (max-width: 768px) {
                        position: fixed;
                        width: 90vw;
                        max-height:99%;
                        z-index: 1040;
                        top: ${() => 1}%;
                        left: 2.5vw;
                        padding-top:20px;
                        padding-bottom:20px;
                        border-radius: 15px;
                        background-color: var(--dark__main);
                        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
                        overflow-y:auto;

                }
            `;
const renderBackdrop = (things) => <GameOverBackdrop {...things} />;


export default class GameOverPopup extends Component {

    constructor(props){
        super(props)

        this.handleHidden = props.handleHidden
        this.handleRematch = props.handleRematch
        this.currentUser = props.currentUser
        this.game = props.game


        let challengerUid = props.game.challengerUid

        if(challengerUid === props.currentUser.uid){
            this.oppositeUid = props.game.opponentUid
        }else{
            this.oppositeUid = props.game.challengerUid
        }


        var ttSent = false
        if(props.game.trashTalk !== ""){
            ttSent = true
        }


        this.state = {
            sending: false,
            sent:ttSent
        }

    }




    render(){


        // function imageForType(type){
        //
        //     var image;
        //     switch (type) {
        //         case "0":
        //             image =  "None"
        //             break
        //         case "1":
        //             image =  Snowman
        //             break
        //         case "2":
        //             image =  XsOs
        //             break
        //         case "3":
        //             image =  Match4
        //             break
        //         case "4":
        //             image =  WordUp
        //             break
        //         case "5":
        //             image =  Checkers
        //             break
        //         case "6":
        //             image =  Chess
        //             break
        //         case "7":
        //             image =  Trivia
        //             break
        //         default:
        //             image =  "None"
        //             break
        //     }
        //     return image
        // }
        //
        //
        //
        // function nameForType(type){
        //
        //     var image;
        //     switch (type) {
        //         case "0":
        //             image =  "None"
        //             break
        //
        //         case "1":
        //             image =  "Snowman"
        //             break
        //
        //         case "2":
        //             image =  "X's O's"
        //             break
        //
        //         case "3":
        //             image =  "Match 4"
        //             break
        //
        //         case "4":
        //             image = "Word Up"
        //             break
        //
        //         case "5":
        //             image =  "Checkers"
        //             break
        //
        //         case "6":
        //             image =  "Chess"
        //             break
        //
        //         case "7":
        //             image =  "Trivia"
        //             break
        //
        //         default:
        //             image =  "None"
        //             break
        //     }
        //
        //     return image
        //
        //   }
        //
        //

          function scoreTextForType(type){
              console.log("Score Text Type: ", type)
              var str = "";
              switch (type) {
                  case 0:
                      str =  ""
                      break

                  case 1:
                      str = wordText()
                      break

                  case 2:
                      str =  ""
                      break

                  case 3:
                      str =  ""
                      break

                  case 4:
                      str = scoreText()
                      break

                  case 5:
                      str =  ""
                      break

                  case 6:
                      str =  ""
                      break

                  case 7:
                      str =  scoreText()
                      break

                  default:
                      str =  ""
                      break
              }

              return str

            }



        //Handles hiding/showing views and setting the new game type when a new game is selected in the challenge popup
        const challengeGameSelected = (type) =>{

            let gamesWithTimer = gamesWithTurnTimers()

            var updates = {}

            if(gamesWithTimer.includes(type)){

                updates.showTurnTimer = true
            }else{
                updates.showTurnTimer = false
            }


            if(type === '7'){
                updates.gameType = type
                updates.showScrollbox = false
                this.setState(updates)
            }
        }



        const imWinner = () =>{

            console.log("WINNER: ", this.game.winner)
            if(this.game.winner === this.currentUser.uid && this.game.winner !== ""){

                return true
            }

            return false
        }


        const isTie = () =>{

            if(this.game.winner === "" && this.game.gameComplete === true){

                return true;
            }


            return false
        }


        var message = '';
        var setMessage = (m) => {
            message = m
        }



        var get24HoursFromNow = () =>{

            var tomorrow = new Date()
            // add 1 day to today
            tomorrow.setDate(new Date().getDate() + 1)

            return tomorrow
        }


        const prepareAndSendChallenge = () =>{

            this.handleRematch(this.oppositeUid)
        }



        const hideChallengeView = () => {

            this.handleHidden()
        }


        const getResultText = () => {

            if(imWinner()){
                return "WINNER!"

            }else if(isTie()){

                return "TIE, There must be a winner!"
            }else{

                return "LOSE!"

            }
        }



        const scoreText = () => {

            var s = ''


            let myData = this.game.gameData[this.currentUser.uid]
            if(myData === undefined){
              console.log("MyData undefined")
                return ""
            }

            let myScore = myData.score

            if(myScore !== undefined){
                s = "Score: " + myScore
            }

            let theirData = this.game.gameData[this.oppositeUid]
            if(theirData === undefined){
                return ""
            }


            let theirScore = theirData.score
            if(theirScore !== undefined){

                s = s + " - " + theirScore

            }

            return s.toString()
        }

        const wordText = () => {

            
            return "Word: " + this.game.gameData.word

        }


        const resultsForType = () =>{


            if(this.game.type === 7){
                return <TriviaResults game={this.game} oppositeUid={this.oppositeUid} currentUid={this.currentUser.uid}/>

            }


            return <></>


        }

        const sendTrashTalk = async() =>{

            this.setState({sending:true})

            console.log("Sending Trash Talk", message)
            let senderId = this.currentUser.uid
            let receiverId = this.oppositeUid
            let gameId = this.game.gameId

            let data = {
                senderId:senderId,
                receiverId:receiverId,
                gameId:gameId,
                message:message

            }

            console.log("Data: ", data)

            let tt = await postTrashTalk(data)
            console.log("TT SENT: ", tt )
            this.setState({sending:false, sent:true})


        }



        const hideConfirmation = () => {this.setState({showConfirmation:false})}

            return (
                <div>
                <GameOverViewModal

                    show={true}
                    onHide={hideChallengeView}
                    renderBackdrop={renderBackdrop}
                    aria-labelledby="modal-label"
                    >

                    <div className="center__gameover__content">

                        <div className="top__close__gameover__view">
                            <div className="bold__white__large__text">{this.game.name}</div>
                            <div className="bold__gray__large__text">Game Over!</div>

                            <button className="white__background__theme__button__no__margin" onClick={hideChallengeView}>Close</button>
                        </div>

                        <div className="horizontal__flex__center">
                            <div className="title__yellow">{getResultText()}</div>
                        </div>


                        <div className="horizontal__flex__center">
                            <div className="large__text">{scoreTextForType(this.game.type)}</div>
                        </div>

                        <div className="spacer__vertical"/>
                        <div className={(imWinner()) ? "message__box" : "hidden"}>
                                {!this.state.sent && <div className="bold__gray__large__text">Talk some Trash!</div>}
                                {this.state.sent && <div className="large__text" >Trash Talk Sent</div>}
                                {!this.state.sent && <div className="horizontal__flex__center">
                                    <input
                                        row_count="1"
                                        length="400"
                                        wrapping='off'
                                        className="input__textbox"
                                        placeholder="Aa"
                                        onChange={(e) => setMessage(e.target.value)}
                                    />

                                    {(!this.state.sending && !this.state.sent) && <div className="white__background__theme__button" onClick={sendTrashTalk}>Send</div>}

                                </div>}
                            {this.state.sending && <Loading text="Sending Trash Talk..."/> }

                        </div>

                        {
                        resultsForType()
                        }

                        <div className={isTie() ? "hidden" : "horizontal__justify"}>

                            <div className="spacer__vertical"/>
                                {!this.state.sending && <button className="white__background__theme__button" onClick={prepareAndSendChallenge}>Rematch!</button>}

                            </div>

                        </div>



                    </GameOverViewModal>



                </div>


            );


        }
}
