import { useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import "./CSS/Theme.css";



import faqsPDF from "./Assets/faqs.pdf"



function Faq() {
    
    
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

      function onDocumentLoadSuccess({ numPages }) {
        console.log("NUM PAGES: ", numPages)
        setNumPages(numPages);
      }

           
      return (

            <div className="body__flex__fill">
                <iframe className="iframe__fill" src="https://docs.google.com/document/d/e/2PACX-1vSAKWUk9HC2zB-WyGKgLxp6fP-mJHXtQtPq17nAU8OKpROa4XjfPBp9WwBe7QyhhVUkA4HTUblm1HPb/pub?embedded=true
                "/>


            </div>
      );
    }

  
export default Faq;  