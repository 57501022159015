import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import "./CSS/ManageBalance.css";
import "./CSS/Theme.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import {getFCMToken, auth, db, logout, getPayPalClientId, acceptedStates, transferFunds, addFunds } from "./firebase";
import Loading from "./Components/Loading"
import { DropdownList } from "./Components/DropdownList"
import {Dropdown, Option} from "./Components/Dropdown"
import UserSingleton from "./UserSingleton"

import ConfirmationPopup from "./Components/ConfirmationPopup"



function Home() {
    
    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserData] = useState(undefined);
    const [account, setAccount] = useState(undefined);

    const history = useHistory();
                             
    const [showPaypalCheckout, setShowPaypalCheckout] = useState(false)
    const [withdrawalFunds, setWithdrawalFunds] = useState(false)
    
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [confirmationTitle, setConfirmationTitle] = useState("Success")
    const [confirmationBody, setConfirmationBody] = useState("Funds Added")
    const [actionAmount, setActionAmount] = useState("")
    
//    const {query, limit, collection, onSnapshot, orderBy, getDocs} = require('firebase/firestore')
    const [success, setSuccess] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(false);
    const [clientId, setClientId] = useState("");
    const [loadingPaypal, setLoadingPaypal] = useState(false);
    const [loadingText, setLoadingText] = useState("Loading Payment Options");

    
    const [streetAddress, setStreetAddress] = useState("");
    const [state, setState] = useState("Choose State");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [appt, setAppt] = useState("");
    const [fullName, setFullName] = useState("");
    
    
    
    const instance = UserSingleton.getInstance()
  
    
    
    useEffect(() => {

        if(loading)return;
        if (!user) return history.replace("/");
        
        
        if (userData === undefined){
            console.log("Fetching User Data")
            return fetchMyUserData()   
        }
        
        
        async function fetchMyUserData(){
            
            let data = await instance.getUser(user.uid)
            let accountData = await instance.getAccount(user.uid)
            let token = await getFCMToken()
            
            if(token === 'request'){
                Notification.requestPermission()
            }else if(token !== 'error'){
                instance.updateToken(token)
            }
            
            setStreetAddress(accountData.address)
            setState(accountData.state)
            setCity(accountData.city)
            setZipcode(accountData.zip)
            setAppt(accountData.appt)
            setFullName(accountData.mailingName)
            
            setAccount(accountData)
            setUserData(data)
        }
        


    }, [user, loading, userData]);
  
    

    
    // creates a paypal order
     const createOrder = (data, actions) => {
       return actions.order
         .create({
           purchase_units: [
             {
               description: "Add to Balance",
               amount: {
                 currency_code: "USD",
                 value: actionAmount,
               },
             },
           ],
           // not needed if a shipping address is actually needed
           application_context: {
             shipping_preference: "NO_SHIPPING",
           },
         })
         .then((orderID) => {
           setOrderID(orderID);
           return orderID;
         });
     };

     // check Approval
     const onApprove = (data, actions) => {
       return actions.order.capture().then(function (details) {
         const { payer } = details;
         setSuccess(true);
           
           return successfulPaypalTransaction(actionAmount)
       });
     };
     //capture likely error
     const onError = (data, actions) => {
       setErrorMessage("An Error occured with your payment ");
     };

    
    
    const successfulPaypalTransaction = async(amount) => {
        
        
        let success = await addFunds({uid:user.uid, amount:actionAmount})
        if(success === true){
            setConfirmationTitle("Success!")
            setConfirmationBody("Funds have been added")

            setShowSuccess(true)  
            return
            
        }else {

            setConfirmationTitle("Oops")
            setConfirmationBody("Please enter a valid number greater than $10")

            setShowConfirmation(true)
            return
        }
    }
    
    
    
    const showCheckout = async() =>{
            
        setLoadingPaypal(true)
        setWithdrawalFunds(false);
        let amount = actionAmount.replace(/,/g, '');


        if(amount === undefined || isNaN(amount) || amount < 10){

            setConfirmationTitle("Oops")
            setConfirmationBody("Please enter a valid number greater than $10")

            setLoadingPaypal(false)
            
            setShowConfirmation(true)
            
            return
        }
        

        let clientId = await getPayPalClientId()
        setClientId(clientId)
        
        setLoadingPaypal(false)
        
        setShowPaypalCheckout(true)
    }
    
    
        
    const showWithdrawal = async() =>{
        
        let amount = actionAmount.replace(/,/g, '');

        if(amount === undefined || isNaN(amount)){

            setConfirmationTitle("Oops")
            setConfirmationBody("Please enter a valid number greater than $10")

            setShowConfirmation(true)
            return
            
        }else if(amount < 10){

            setConfirmationTitle("Oops")
            setConfirmationBody("The amount must be at least $10")

            setShowConfirmation(true)  
            return
            
        }else if(amount > account.balance){

            setConfirmationTitle("Oops")
            setConfirmationBody("The amount must be less than your account balance")

            setShowConfirmation(true)  
            return
            
        }
        
        setShowPaypalCheckout(false)
        setWithdrawalFunds(true)
    }
    
    
    const updateAmount = (value) =>{
        setActionAmount(value)
    }
    
    
    const setName = (value) =>{
        setFullName(value)
    
    }    
    
    const setNewStreetAddress = (value) =>{

        setStreetAddress(value)
    
    }    
    const setNewCity = (value) =>{
        setCity(value)
    
    
    }  
    
    const setNewState = (value) =>{

        setState(value)
    
    } 
    const setNewZipcode = (value) =>{
        setZipcode(value)
    }
    const setNewAppt = (value) =>{
        setAppt(value)
    
    }
    
    
    
    const sendCheck = async() =>{
        //check all info is correct, then write a payout firebase or show error message
        
        let data = {uid:user.uid, amount:actionAmount, mailingName:fullName, address:streetAddress, state:state, city:city, appt:appt, zip:zipcode}
        
        setWithdrawalFunds(false)
        setLoadingText("Sending Information")
        setLoadingPaypal(true)
        let res = await transferFunds(data)
        
        if(res.success === true){

            setLoadingPaypal(false)
            setShowSuccess(true)
            
        }else{

            setConfirmationTitle("Oops")
            setConfirmationBody(res.message)
            
            setLoadingPaypal(false)
            setWithdrawalFunds(true)
            
            setShowConfirmation(true)
        }
    }
     

  return (
    <div className="manage__balance">
                                 
        <div className="manage__balance__container">
      
            <h1 className="title__yellow">Balance: ${account && account.balance}</h1> 
      
            <div className="balance__actions__row">
      
                <div className="spacer__horizontal"/>
                <div className="title__gray"> $ </div>
                <input
                    row_count="1"
                    length="7"
                    wrapping='off'
                    resize='none'
                    className='input__textbox'
                    value={actionAmount}
                    placeholder="Amount in ($)"
                    onChange={(e) => updateAmount(e.target.value)}
                />
                <div className="spacer__horizontal"/>
                {(showPaypalCheckout || !withdrawalFunds) && <div className="dark__theme__background__white__button" onClick={showWithdrawal}>Withdrawal</div>}

                <div className="spacer__horizontal"/>
                {(!showPaypalCheckout || withdrawalFunds) && <div className="white__background__theme__button" onClick={showCheckout}>Add</div>}

            </div>
      
        </div>
      
        <div className="spacer__vertical"/>
      
        <div className="spacer__vertical"/>
            
        {withdrawalFunds && <div className="address__info__container">
            
                    <div className="medium__text__gray">Full Name</div>
                    <input
                        className="input__textbox"
                        placeholder="Aa"
                        row_count="1"
                        length="100"
                        defaultValue={(account !== undefined && account.mailingName !== undefined) ? account.mailingName : ""}
                        wrapping='off'
                        onChange={(e) => setName(e.target.value)}
                    />
        
                    <div className="spacer__vertical"/>
        
                    <div className="medium__text__gray">Street Address</div>
                    <input
                        className="input__textbox"
                        placeholder="Aa"
                        length="500"
                        row_count="1"
                        defaultValue={(account !== undefined && account.address !== undefined) ? account.address : ""}
                        wrapping='off'
                        onChange={(e) => setNewStreetAddress(e.target.value)}
                    />
                            
                            
                    <div className="spacer__vertical"/>
                
                    <div className="medium__text__gray">Appartment</div>
                    <input
                        className="input__textbox"
                        placeholder="Aa"
                        length="7"
                        row_count="1"
                        defaultValue={(account !== undefined && account.appt !== undefined) ? account.appt : ""}
                        wrapping='off'
                        onChange={(e) => setNewAppt(e.target.value)}
                    />

                    <div className="spacer__vertical"/>
                    <div className="medium__text__gray">City</div>
                    <input
                        className="input__textbox"
                        placeholder="Aa"
                        length="100"
                        row_count="1"
                        defaultValue={(account !== undefined && account.city !== undefined) ? account.city : ""}
                        wrapping='off'
                        onChange={(e) => setNewCity(e.target.value)}
                    />
                    
                     <div className="spacer__vertical"/>       
                    <div className="horizontal__flex__center">
                        <Dropdown 
                            onChange={(e) => setNewState(e.target.value)}>        
                            <Option selected value={(account !== undefined && account.state !== undefined) ? account.state : "Choose State"}/>
                            <DropdownList items={acceptedStates}  type="states"/>

                        </Dropdown>

                        <input
                            className="input__textbox"
                            placeholder="Zipcode"
                            row_count="1"
                            length="7"
                            wrapping='off'
                            defaultValue={(account !== undefined && account.zip !== undefined) ? account.zip : ""}
                            onChange={(e) => setNewZipcode(e.target.value)}
                        />
                    </div>


                    
                    <div className="spacer__vertical"/>
            
                    {<div className="white__background__theme__button" onClick={sendCheck}>Send Check</div>}
            </div>}
      
        {loadingPaypal && <Loading text={loadingText}/> }
         
        {(showPaypalCheckout && clientId !== "") && <PayPalScriptProvider options={{ "client-id": clientId }}>
              
            <PayPalButtons
                style={{ layout: "vertical" }}
                createOrder={createOrder}
                onApprove={onApprove}
            />
               
        </PayPalScriptProvider>}
      
        {showSuccess && 
         <ConfirmationPopup
            handleClose={() => history.replace("/profile")}
            positive="Close"
            title="Success"
            body="You will receive a check in 4-6 weeks."/>
        }     

        {showConfirmation && 
         <ConfirmationPopup
            handleClose={() => setShowConfirmation(false)}
            positive="Close"
            title={confirmationTitle}
            body={confirmationBody}/>
        }          
    </div>
  );
}

export default Home;
