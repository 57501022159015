import styled from "styled-components";
import Modal from 'react-overlays/Modal';
import "../CSS/SupportPopupView.css"
import TextInput from "./TextInput"
import Switch from "./Switch"
    
const SupportBackdrop = styled("div")`
            position: fixed;
            z-index: 1040;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000;
            opacity: 0.5;
            `;
        
        
const ViewSupportModal = styled(Modal)`
            position: fixed;
            width: 40vw;
            z-index: 1040;
            top: ${() => 20}%;
            left: ${() => 30}%;
            border-radius: 15px;
            background-color: var(--main);
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
            padding-top:10px;
            padding-bottom:30px;
            padding-left:2.5vw;
            padding-right:2.5vw;

            @media only screen and (max-width: 768px) {
                position: fixed;
                width: 90vw;
                z-index: 1040;
                top: ${() => 10}%;
                left: 2.5vw;
                padding-top:20px;
                padding-bottom:20px;
                border-radius: 15px;
                background-color: var(--main);
                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        }
    `;

    
const renderBackdrop = (props) => <SupportBackdrop {...props} />;
    
const SupportPopupView = ({show, user, handleHidden, handleRequest}) => {
    
    
    var message = ""
    const textChanged = (text) =>{
        console.log(text)
        message = text
    
    }
    
    const handleSubmit = () =>{

        var request = { uid:user.uid, email:user.email, name: user.name, message:message}
        handleRequest(request)
    
    }
    
  
    return (
        
        <div>

            <ViewSupportModal
                show={show}
                onHide={() => handleHidden()}
                renderBackdrop={renderBackdrop}
                aria-labelledby="modal-label"
            >
                    
            <div className="center__support__popup__content">

                    <div className="card__title">Support Request</div>                                      
                    <div className="medium__text">{user !== undefined ? user.email : ""}</div>                                      
                    <TextInput
                        row_count="10"
                        length="500"
                        handleChange={(e) => textChanged(e.target.value)}
                    />
                    
                            

                    <button className="white__background__theme__button" onClick={() => handleSubmit()}>Submit Request</button>                               
                    
            </div>
            </ViewSupportModal>
         </div>
    );
}
    
export default SupportPopupView;