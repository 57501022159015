import React from 'react';
import '../CSS/OngoingGames.css';

import OngoingGameCell from './OngoingGameCell';

const OngoingGamesList = ({handleProfileSelected, handleResumeSelected, data, myUid}) => {
    
    const final = [];
    
    for(let game of data){

        final.push(<OngoingGameCell key={game.gameId} data={game} resumeSelected={(game) => handleResumeSelected(game)}  profileSelected={(uid) => handleProfileSelected(uid)} myUid={myUid}/>);
    }
    
    return (
    
        <div className="ongoing__table__container">
            {final}
        </div>
    )
    
}



export default OngoingGamesList;