import React, {Component, useState, useEffect} from 'react';

function Letter({activeLetters, key, item, onClick, enabledLetters}){
    
  const [letter, setLetter] = useState(item)
  const [active, setActive] = useState(activeLetters)
  const [enabled, setEnabled] = useState(enabledLetters)
  
  
  useEffect(() =>{
        
    setLetter(item)  
    setEnabled(enabledLetters)
    setActive(activeLetters)

  }, [item, activeLetters, enabledLetters]);    
    
  
  function handleClick() {
    // checks to see if the letter is enabled to be selected or deselected
        
     if(enabled.includes(letter.key) || enabled.length === 0){
          // calls "onLetterClick" from parent Board component
         onClick(letter);
     }    
  }
      
    // uses classnames module to put two classnames on a component
    
    const className = () => {
        
        if(active === 1)return 'activeLetter'
        if(active.includes(item.key)) return 'activeLetter'
    
        return 'letters'
    }
    
    
    return (
      <div onClick={() => handleClick()} className={className()}>
        <div className="text">
            {letter.letter}
        </div>
      </div>
    )
  
};

export default Letter;

