import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import UserSingleton from "./UserSingleton"
import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import logo from "./Assets/white_cowboy.svg";

import "./CSS/Splash.css";
import "./CSS/Theme.css";

function Splash() {

    const [user, loading, error] = useAuthState(auth);
    const history = useHistory();
    const instance = UserSingleton.getInstance()

      useEffect(() => {

        if(loading)return;
        if (user) {
            history.replace("/home");
        }
      }, [user, loading]);
    
    
    
    const gotoRegister = () =>  {

        history.replace("/register");

    }

    const gotoLogin = () => {

        history.replace("/login");

    }

  return (
      
    <div className="splash">

      
        <div className="splash__container">
      
            <img className="splash__logo" draggable="false"  src={logo} alt="Pony Up Logo"/>    
            <div className="spacer__vertical"/>
            
            <div className="title__yellow"> Welcome to Pony Up! </div>
            <div className="spacer__vertical"/>

            <div className="large__text"> Challenge your friends! </div>
            <div className="spacer__vertical"/>
            <div className="spacer__vertical"/>

            <div className="horizontal__flex__center">
      
                <button className="white__background__theme__button" onClick={gotoRegister}> Create Account </button>  
                <div className="spacer__horizontal"/>
                <button className="dark__theme__background__gray__button" onClick={gotoLogin}> Login </button>
            </div>
      
        
        </div>
    </div>
  );
}

export default Splash;
