import styled from "styled-components";
import Modal from 'react-overlays/Modal';
import "../CSS/ConfirmationPopup.css"
import React, { Component } from "react";
import logo from "../Assets/logo.svg";

export default class ConfirmationPopup extends Component{

    constructor(props){
        super(props)
        
        
        
        this.state = {
            show:props.show,
            positive: props.positive,
            negative:props.negative,
            title:props.title,
            body:props.body
        
        }
        this.handleClose = props.handleClose
    }
    
    render(){
        const ConfirmationBackdrop = styled("div")`
                position: fixed;
                z-index: 1040;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: #000;
                opacity: 0.5;
                `;


        const ViewConfirmationModal = styled(Modal)`
                position: fixed;
                width: 20vw;
                z-index: 1040;
                top: ${() => 25}%;
                left: ${() => 40}%;
                border-radius: 15px;
                background-color: var(--main);
                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
                padding-top:30px;
                padding-bottom:30px;
                align-content: center;
                align-items: center;
                text-align: center;

                padding-left:2.5vw;
                padding-right:2.5vw;

                @media only screen and (max-width: 768px) {
                    position: fixed;
                    width: 90vw;
                    z-index: 1040;
                    top: ${() => 25}%;
                    left: 2.5vw;
                    padding-top:20px;
                    padding-bottom:20px;
                    border-radius: 15px;
                    align-content: center;
                    align-items: center;
                    text-align: center;
                    background-color: var(--main);
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
            }
        `;


        const renderBackdrop = (props) => <ConfirmationBackdrop {...props} />;
        
        const close = () =>{

            this.handleClose()
        }
        
        
        return (
        
            <div>

                <ViewConfirmationModal
                    show={true}
                    onHide={() => close()}
                    renderBackdrop={renderBackdrop}
                    aria-labelledby="modal-label"
                >

                <div className="center__confirmation__popup__content">
                        <img className="pony__logo" src={logo}/>

                        <div className="card__title">{this.state.title}</div>                                      
                        <div className="medium__text">{this.state.body}</div>                                      

                        <div className="horizontal__buttons">
                            <button className={this.state.negative !== undefined ? "white__background__red__button"  : "hidden"} onClick={close}>{this.state.negative}</button>                               
                            <button className={this.state.positive !== undefined ? "white__background__theme__button" : "hidden"} onClick={close}>{this.state.positive}</button>                               

                        </div>

                </div>
                </ViewConfirmationModal>
             </div>
        );
        
    
    }


}

//const ConfirmationPopup = ({show, positive, negative, title, body, handleClose}) => {
//    
//    
//    const ConfirmationBackdrop = styled("div")`
//            position: fixed;
//            z-index: 1040;
//            top: 0;
//            bottom: 0;
//            left: 0;
//            right: 0;
//            background-color: #000;
//            opacity: 0.5;
//            `;
//        
//        
//    const ViewConfirmationModal = styled(Modal)`
//            position: fixed;
//            width: 20vw;
//            z-index: 1040;
//            top: ${() => 25}%;
//            left: ${() => 40}%;
//            border-radius: 15px;
//            background-color: var(--main);
//            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
//            padding-top:30px;
//            padding-bottom:30px;
//            align-content: center;
//            align-items: center;
//            text-align: center;
//        
//            padding-left:2.5vw;
//            padding-right:2.5vw;
//
//            @media only screen and (max-width: 768px) {
//                position: fixed;
//                width: 90vw;
//                z-index: 1040;
//                top: ${() => 25}%;
//                left: 2.5vw;
//                padding-top:20px;
//                padding-bottom:20px;
//                border-radius: 15px;
//                align-content: center;
//                align-items: center;
//                text-align: center;
//                background-color: var(--main);
//                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
//        }
//    `;
//
//    
//    const renderBackdrop = (props) => <ConfirmationBackdrop {...props} />;
//    
//    
//  
//    return (
//        
//        <div>
//
//            <ViewConfirmationModal
//                show={show}
//                onHide={() => handleClose()}
//                renderBackdrop={renderBackdrop}
//                aria-labelledby="modal-label"
//            >
//                    
//            <div className="center__confirmation__popup__content">
//
//                    <div className="card__title">{title}</div>                                      
//                    <div className="medium__text">{body}</div>                                      
//                    
//                    <div className="horizontal__buttons">
//                        <button className={negative !== undefined ? "white__background__red__button"  : "hidden"} onClick={() => handleClose()}>{negative}</button>                               
//                        <button className={positive !== undefined ? "white__background__theme__button" : "hidden"} onClick={() => handleClose()}>{positive}</button>                               
//                        
//                    </div>
//                    
//            </div>
//            </ViewConfirmationModal>
//         </div>
//    );
//}
//    
//export default ConfirmationPopup;