import React, { useEffect, useState } from "react";


import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import ChallengesList from "./Components/ChallengesList"

import ProfileView from "./Components/ProfileView"
import ConfirmationPopup from "./Components/ConfirmationPopup"


import UserSingleton from "./UserSingleton"
import {auth, db, logout, allChallengesPath, declineChallenge, acceptChallenge, getLocationData, acceptedStates, gameUrlPathForType, currentPlayableGameTypes } from "./firebase";
import "./CSS/Play.css";

function Play(){

    var axios = require('axios')
    const [user, loading, error] = useAuthState(auth);

    const [friendGames, setFriendGames] = useState(undefined)
    const [worldGames, setWorldGames] = useState(undefined)

    const [viewingProfileRecentGames, setViewingProfileRecentGames] = useState(undefined)

    const [showProfileModal, setShowProfileModal] = useState(false)
    const [viewingProfile, setViewingProfile] = useState(undefined)


    const [userData, setUserData] = useState(undefined)
    const [worldButtonSelected, setWorldButtonSelected] = useState(false)

    const [showChallengeAccepted, setShowChallengeAccepted] = useState(false)
    const [showChallengeView, setShowChallengeView] = useState(false)

    const [showConfirmation, setShowConfirmation] = useState(false)
    const [confirmationTitle, setConfirmationTitle] = useState("Challenge Accepted")
    const [confirmationBody, setConfirmationBody] = useState("Get Ready!")
    const [confirmationButton, setConfirmationButton] = useState("Get Ready!")
    const [currentState, setCurrentState] = useState("")


    const history = useHistory();
    const instance = UserSingleton.getInstance()
    const [acceptedGame, setAcceptedGame] = useState(false)

    const {getFirestore, query, getDocs, limit, orderBy, collection, doc, where} = require('firebase/firestore')

    var myChallengeObserver, challengedMeObserver, worldObserver = undefined

    const [locationGranted, setLocationGranted] = useState(false)
    const [region, setRegion] = useState(false)
    const [stateAvailable, setStateAvailable] = useState(false)

    const [locationNeededPopup, setLocationNeededPopup] = useState(false)
    const [locationTitle, setLocationTitle] = useState("Location Permission")
    const [locationBody, setLocationBody] = useState("We need access to your location to ensure money challenges are available in your region.")
    const [locationButton, setLocationButton] = useState("Close")



    useEffect(() => {
        if(loading)return;

        if (!user) return history.replace("/welcome");



        if(userData === undefined){
            return fetchUserData()
        }

        if(friendGames === undefined){
           return fetchFriendGames()
        }

        if(worldGames === undefined){
           return fetchWorldGames()
        }

        navigator.geolocation.getCurrentPosition(locationAllowed, locationDisallowed);


        async function fetchUserData(){
            let uData = await instance.getUser(user.uid)
            setUserData(uData);
        }


        async function fetchWorldGames(){

            let wGames = await loadWorldGames()
            setWorldGames(wGames)
        }


        async function fetchFriendGames(){
            let fGames = await loadFriendsGames()

            setFriendGames(fGames)
        }




    }, [user, loading, userData,  worldGames, friendGames]);


    /*
        LOAD Games
    */
    const loadFriendsGames = async () => {


        const allChallenges = collection(db, allChallengesPath);

        const friendchallengesQuery = query(allChallenges, where('players', 'array-contains', user.uid), where('accepted', '==', false), where('gameStarted', '!=', user.uid));

        var allFriendChallenges = []

        let snapshot = await getDocs(friendchallengesQuery)

        snapshot.forEach(doc =>{

            let data = doc.data()
            let id = doc.id


            allFriendChallenges.push(data)
        });
        console.log("Loaded Friend Challenges: ", allFriendChallenges)

        return allFriendChallenges

    }


    async function getLocation(){

        const res = await axios.get('https://geolocation-db.com/json/')
        console.log(res.data);

        var state = res.data.state
        var ipAddress = res.data.IPv4

        if(res.data == undefined || res.data == null){
            return ""
        }


        if(state === null){
            var url = 'https://ipapi.co/' + ipAddress + '/json/'
            const new_res = await axios.get(url)
            let data = new_res.data
            state = data.region
            console.log(data.region)
        }


        setCurrentState(state)

        return state
    }



    const loadWorldGames = async () =>{

        const allChallenges = collection(db, allChallengesPath);

        const worldchallengesQuery = query(allChallenges, where('randomOpponent', '==', true), where('accepted', '==', false));

        let snapshot = await getDocs(worldchallengesQuery)

        let allWorldChallenges = []

        snapshot.forEach(doc =>{
            let data = doc.data()
            let id = doc.id


            allWorldChallenges.push(data)

        });
        console.log("Loaded World Challenges: ", allWorldChallenges)

        return allWorldChallenges
    }



    const handleFriendsSelected = async() =>{

        setWorldButtonSelected(false)

        if(friendGames === undefined || friendGames === []){

            let temp = await loadFriendsGames()
            setFriendGames(temp)
        }
    }


    const handleWorldSelected = async() =>{
        setWorldButtonSelected(true)

        if(worldGames === undefined || worldGames === []){

            let temp = await loadWorldGames()
            setWorldGames(temp)
        }
    }





    const locationAllowed = async() =>{

      let state = await getLocation()

      console.log("State: ", state)

      if(!acceptedStates.includes(state) && this.state.betAmount > 0){

          let text = "Local policy doesn't allow Pony Up to support money challenges in the state of " + state + "."


          setLocationBody(text)
          setLocationTitle("Location Permissions")
          setLocationNeededPopup(true)
          setRegion(state)
          setStateAvailable(false)
          setLocationGranted(false)

      }
    }


    const locationDisallowed = (test) =>{

      setRegion("")
      setStateAvailable(false)
      setLocationGranted(false)
      setLocationBody("Enable location from settings to send or accept money challenges.")
      setLocationTitle("Location DISALLOWED")

      setLocationNeededPopup(true)

    }

    const declined = async(game) =>{


        let random = game.randomOpponent


        let declined = await declineChallenge(game)



        if(random){

            const games = worldGames.filter(g => {
                return (g.id != game.id);
            });
            setWorldGames(games)

        }else{

            const games = friendGames.filter(g => {
                return (g.id != game.id);
            });
            setFriendGames(games)
        }
    }



    const accepted = async(game) =>{

        //TODO Check location and bet amount to ensure the game can continue before accepting the challenge

        if(locationGranted === false && region === ""  && game.betAmount > 0){

          await navigator.geolocation.getCurrentPosition(locationAllowed, locationDisallowed);

          return

        }else if(game.betAmount > 0 && stateAvailable === false && region !== ""){

          let text = "Local policy doesn't allow Pony Up to support money challenges in the state of " + region + ".";

          setConfirmationTitle("Oops")

          setConfirmationBody(text)
          setConfirmationButton("Close")

          return
        }



        let amount = game.betAmount
        let account = await instance.getAccount(user.uid)
        if(game.betAmount > account.balance ){
            setConfirmationTitle("Oops")
            setConfirmationBody("You don't have enough money to accept this challenge")
            setConfirmationButton("Close")

            setShowConfirmation(true)

        }else if(game.betAmount > 0 && !locationGranted){
            setConfirmationTitle("Oops")
            setConfirmationBody("You must enable Location to accept money challenges.")
            setConfirmationButton("Close")

            setShowConfirmation(true)

        }


        let accepted = await acceptChallenge(game, userData)

        if(accepted){

            setAcceptedGame(game)
            setConfirmationTitle("Challenge Accepted")
            setConfirmationBody("Get Ready!")
            setConfirmationButton("I'm Ready!")

            setShowChallengeAccepted(true)

        }else{
            setAcceptedGame("")
            setConfirmationTitle("Oops")
            setConfirmationBody("Something went wrong")
            setConfirmationButton("Close")

            setShowConfirmation(true)
            return
        }

        //update the viewing list
        let random = game.randomOpponent

        const games = worldGames.filter(g => {
            return (g.id != game.id);
        });

        if(random){
            setWorldGames(games)
        }else{

            const fgames = friendGames.filter(g => {
                return (g.id != game.id);
            });

            setFriendGames(random)
        }

    }

    const openProfile = async(game) =>{

        var uid = game.challengerUid

        let user = await instance.getUser(uid)
        let recentData = await instance.queryRecentGames(uid, 5)

        setViewingProfileRecentGames(recentData)
        setViewingProfile(user)
        setShowProfileModal(true)
    }



    //TODO ADD GAMES
    const challengeAccepted = () =>{

        let type = acceptedGame.gameType
        let id = acceptedGame.startedGameId


        setShowChallengeAccepted(false)

        let path = gameUrlPathForType(type, id)
        history.push(path)


    }







    return (

        <div className="play">

            <div className="play__container">

                <div className="play__switch__button__container">

                    <button className={worldButtonSelected === false ? "play__button__selected" : "play__button__not__selected"} onClick={handleFriendsSelected}>Friends</button>
                    <button className={worldButtonSelected === true ? "play__button__selected" : "play__button__not__selected"} onClick={handleWorldSelected}>World</button>

                </div>

                <div className="play__games__container">

                        <div className={(!worldButtonSelected && (friendGames === undefined || friendGames.length === 0)) ? "empty__list__text" : "hidden"}>{"No Friend Challenges :("}</div>
                        {
                            (!worldButtonSelected && friendGames !== undefined && friendGames.length > 0) &&
                            <ChallengesList myUid={userData.uid} handleProfile={(game) => openProfile(game)} handleDeclined={(game) => declined(game)} handleAccepted={(game) => accepted(game)} allGames={friendGames}/>
                        }

                        <div className={(worldButtonSelected && (worldGames === undefined || worldGames.length === 0)) ? "empty__list__text" : "hidden"}>{"No World Challenges :("}</div>
                        {
                            (worldButtonSelected && worldGames !== undefined && worldGames.length > 0) &&
                            <ChallengesList myUid={userData.uid} handleProfile={(game) => openProfile(game)} handleDeclined={(game) => declined(game)} handleAccepted={(game) => accepted(game)} allGames={worldGames}/>
                        }

                </div>
            </div>


        {showProfileModal &&
           <ProfileView
                hideChallengeButton={true}
                hideFriendRequestButton={true}
                myProfile={userData}
                theirProfile={viewingProfile}
                profileRecentGameData={viewingProfileRecentGames}
                handleHidden={() => setShowProfileModal(false)}/>
            }


            {showConfirmation &&
                <ConfirmationPopup
                    handleClose={() => setShowConfirmation(false)}
                    positive={confirmationButton}
                    title={confirmationTitle}
                    body={confirmationBody}
                />
            }
            {locationNeededPopup &&
                <ConfirmationPopup
                    handleClose={() => setLocationNeededPopup(false)}
                    positive={locationButton}
                    title={locationTitle}
                    body={locationBody}
                />
            }

            {showChallengeAccepted &&
                <ConfirmationPopup
                    handleClose={() => challengeAccepted()}
                    positive="I'm Ready"
                    title={confirmationTitle}
                    body={confirmationBody}
                />
            }
        </div>

    );
};










export default Play;
