import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Notification = ({title, body, closed}) => {
        toast.info(<Display />, {onClose: () => closed()});
                   
          function Display() {
            return (
                
              <div >
                <h4>{title}</h4>
                <p>{body}</p>
              </div>
            );
          }
    
          return (
            <ToastContainer autoClose={8000} />
           );
}; 

export default Notification