import styled from "styled-components";
import Modal from 'react-overlays/Modal';
import "../CSS/SetBetAmountPopup.css"
import React, { Component, useState } from "react";

const BetAmountBackdrop = styled("div")`
            position: fixed;
            z-index: 1040;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000;
            opacity: 0.5;
            `;
        
        
const BetAmountModal = styled(Modal)`
            position: fixed;
            width: 30%;
            z-index: 1040;
            top: ${() => 25}%;
            left: ${() => 35}%;
            border-radius: 15px;
            background-color: var(--main);
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
            padding-top:30px;
            padding-bottom:30px;
            align-content: center;
            align-items: center;
            text-align: center;
            padding-left:2.5vw;
            padding-right:2.5vw;

            @media only screen and (max-width: 768px) {
                position: fixed;
                width: 90%;
                z-index: 1040;
                top: ${() => 25}%;
                left: 2.5vw;
                padding-top:20px;
                padding-bottom:20px;
                border-radius: 15px;
                align-content: center;
                align-items: center;
                text-align: center;
                background-color: var(--main);
                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        }
    `;
        
        

        
const renderBackdrop = (props) => <BetAmountBackdrop {...props} />;

export default class SetBetAmountPopup extends Component {
    constructor(props){
        super(props)
        
        this.onHide = props.onHide
        this.handleUpdate = props.handleUpdate
        
        this.state = {
            betAmount: props.betAmount,
            noneSelected:false,
            oneSelected:false,
            fiveSelected:false,
            tenSelected:false
        }
        
    
    }
    
    
    updateAmount = e =>{

        e.preventDefault();
        this.setState({ betAmount: e.target.value });
    
    }
    
    

    
    render(){
        
                    
        var setNewAmount = (a) =>{
            this.setState({betAmount: a})
        }
    
        const resetButtons = () =>{
            return {noneSelected:false,oneSelected:false,fiveSelected:false,tenSelected:false}
        }
        
        
        const setNoBet = () =>{
            let reset = resetButtons()
            reset.noneSelected = true
            reset.betAmount = "0"
            this.setState(reset)
        }
        
        
        const setOneDollarBet = () =>{
            let reset = resetButtons()
            reset.oneSelected = true
            reset.betAmount = "1"
            
            this.setState(reset)
        }
        
        const setFiveDollarBet = () =>{
            let reset = resetButtons()
            reset.fiveSelected = true
            reset.betAmount = "5"
            
            this.setState(reset)
        }
        
        
        const setTenDollarBet = () =>{
            let reset = resetButtons()
            reset.tenSelected = true
            reset.betAmount = "10"
            
            this.setState(reset)
        }
        
        
        
        const handleHidden = () =>{

            this.onHide()
        }
        
        const handleUpdateAction = () =>{

            this.handleUpdate(this.state.betAmount)
        }
        
        
        return (

            <div>
                <BetAmountModal
                    show={true}
                    onHide={handleHidden}
                    renderBackdrop={renderBackdrop}
                    aria-labelledby="modal-label"
                >

                <div className="center__bet__amount__popup__content">

                    <div className="card__title">Set a Bet amount (in $USD)</div>                                      

                    <input
                        row_count="1"
                        length="10"
                        wrapping='off'
                        resize='none'
                        className='input__textbox'
                        value={this.state.betAmount}
                        onChange={this.updateAmount}
                    />
                            
                            
                            
                    <div className="switch__button__container__bet">
                        <button className={this.state.noneSelected ? "button__selected__bet" : "button__not__selected__bet"} onClick={setNoBet}>None</button>
                        <button className={this.state.oneSelected ? "button__selected__bet" : "button__not__selected__bet"} onClick={setOneDollarBet}>$1</button>
                        <button className={this.state.fiveSelected ? "button__selected__bet" : "button__not__selected__bet"} onClick={setFiveDollarBet}>$5</button>
                        <button className={this.state.tenSelected ? "button__selected__bet" : "button__not__selected__bet"} onClick={setTenDollarBet}>$10</button>
                            
                    </div>
                    
                    <div className="spacer__vertical"/>
                    <button className="white__background__theme__button" onClick={handleUpdateAction}>Update</button>                               

                </div>
                </BetAmountModal>
             </div>
        );
  }
}

