import React from 'react'
import './CheckersGame.css'
import Cell from './Cell'


export default class Row extends React.Component {
	render() {
		return (
			<div className="row">
				{
					this.props.rowArr.map(function(cell, index) {
						return (
							<Cell rowIndex={this.props.rowIndex} index={index} cell={cell} handlePieceClick={this.props.handlePieceClick} />
						)
					}, this)
				}
			</div>
		)
	}
};

