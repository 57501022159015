import "./CSS/Theme.css";
import privacyPDF from "./Assets/privacy.pdf"
import React, { useEffect, useState } from "react";



function Privacy() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    
    
    function onDocumentLoadSuccess({ numPages }) {
        console.log("NUM PAGES: ", numPages)
        setNumPages(numPages);
      }
  return (

       
        <div className="body__flex__fill">
                <iframe className="iframe__fill" src="https://docs.google.com/document/d/e/2PACX-1vQ4V9tMQVsomeSsPddE1XpWb97edNmGaePCtWgbKQ4lrEQ6RJIPYB69ke394wGNmeWGFRRQRWs_QCpN/pub?embedded=true"/>

        </div>

  );
}

  
export default Privacy;  