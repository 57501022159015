import { Option } from "./Dropdown"
const React = require('react');


function renderList(items) {
    
    var options = []

    if(items === undefined){
        return [];
    }
    
    if (items.length > 0) {
        
        for (var i = 0; i < items.length; i++) {
            
            const item = items[i]
            
            //Do something
            options.push( <Option value={item} /> )
        }
    
        return options
    }
    else return [];
}


export const DropdownList = (props) =>{
    
    var obj_arr = []       
    const items_obj = props.items
    
    const type = props.type
    
    
    if(type === 'fonts'){

        Object.keys(items_obj).forEach(key => {
            let obj = items_obj[key]
            obj_arr.push(obj.label)
        })
        
    }else if(type === 'cards'){

        Object.keys(items_obj).forEach(key => {
        
            let obj = items_obj[key]
            
            
            obj_arr.push(obj.name)
            
            
            
        })
    }else if (type === "states"){
        obj_arr = props.items
    }
                

                

    
    if(items_obj === undefined){
        return ([])
    }
    

    const items = renderList(obj_arr);

    
    return (items)

}

