import React from 'react';
import '../CSS/RequestsList.css';
import RequestsListCell from './RequestsListCell';

const RequestsList = ({uid, handleSelected, handleAccept, handleDecline, data}) => {
    
    const final = [];
    
    for(let req of data){
        final.push(<RequestsListCell key={req.id} myUid={uid} data={req} accepted={(r) => handleAccept(r)} declined={(r) => handleDecline(req)} profileSelected={(user) => handleSelected(user)}/>);
    }
    

    return (
        <div className="request__table__container">
            {final}
        </div>
    )
    
}



export default RequestsList;