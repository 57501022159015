
import React, { useState } from "react";
import "./XsOs.css"

export default function Square({ value, onClick }) {

  return (
    <div className="square" onClick={onClick}>
      {value}
    </div>
  );
}
