import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import styled from "styled-components";
import Avatar from '../../Assets/avatar.png'

import "./XsOs.css";
import "../../CSS/Theme.css";
import XOBoard from "./XOBoard";

import ConfirmationPopup from "../../Components/ConfirmationPopup"
import AsyncImage from "../../Components/AsyncImage"
import UserSingleton from "../../UserSingleton"


import GameModel from "../../Models/Game"
import GameOverPopup from "../../Components/GameOverPopup"

import {auth, db, gamePathForType, getArchivedGame} from "../../firebase";


function XsOs() {

  const [game, setGame] = useState(undefined);
  const [gameState, setGameState ] = useState(undefined);
  const [gameId, setGameId] = useState(undefined);

  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  const instance = UserSingleton.getInstance()

  var gameListener = undefined

  const [showGameOver, setShowGameOver] = useState(false)


  const [showIsTiePopup , setShowIsTiePopup] = useState(false)
  const [showIsTiePopupAction , setShowIsTiePopupAction] = useState(false)

  const [confirmationTitle, setConfirmationTitle] = useState("Tie!")
  const [confirmationBody, setConfirmationBody] = useState("There must be a winner. Positions will be swapped and the game restarted.")

  const [timeRemaining, setTimeRemaining] = useState(90)
  const {doc, onSnapshot, updateDoc, getDoc} = require('firebase/firestore')
//  const [boardWidth, setBoardWidth] = useState(0)
  const [board, setBoard] = useState(['','','','','','','','',''])
  const [turn, setTurn] = useState('x')
  const [myLetter, setMyLetter] = useState('x')


  useEffect(() => {

    if (loading) return;
    if (!user) history.replace("/welcome");
//
//
//    if(window.innerWidth < 768){
//        let phoneWidth = window.innerWidth
//        setBoardWidth(phoneWidth)
//
//    }else{
//        let width = window.innerWidth
//        let marginLeft = (width - 500) / 2
//
//        setBoardWidth(500)
//    }


    const queryParams = new URLSearchParams(window.location.search)
    const paramGameId = queryParams.get("id")
    const archived = queryParams.get("archived")


    if(gameId === undefined && gameListener === undefined && archived === null){

        setGameId(paramGameId)
        console.log("Game Id Set, will attatch listener")
        attachGameListener(paramGameId)

    }else if (gameId === undefined && archived !== null){

        setGameId(paramGameId)
        getArchive(paramGameId)
    }

  },[user, loading, gameId, game])




   async function attachGameListener(gameId){

        if(gameListener !== undefined){
            return
        }

        const gameRef = doc(db, "TicTacToeGames", gameId);

        gameListener = onSnapshot(gameRef, (doc) => {

            const data = doc.data();

            if(doc !== undefined && data !== undefined){
                setGame(data)
                setGameState(data.gameData)
                checkAndSetUIState(data)
            }else{

               setGame(undefined)
               setGameState(undefined)
            }
        });
    }



    async function getArchive(gameId){

        let game = await getArchivedGame(gameId)

        if(game === false){
            return
        }

        checkAndSetUIState(game)
    }




    function isGameOver(gameData){

        if(gameData === undefined){
            return false
        }


        if(gameData.gameComplete === true){
            return true

        }
    }




   async function checkAndSetUIState(gameData){

        let gameOver = isGameOver(gameData)

        if(gameOver){

            console.log("Game Over, winner is: ", gameData.winner)

            if(gameData.winner === ""){

                //its a stale mate, switch users and start another game.

                if(isMyTurn(gameData)){

                    setShowIsTiePopupAction(true)
                }

                return
            }

            if(gameListener !== undefined){
                gameListener()
            }
            setShowGameOver(true)
        }


        let gameState = gameData.gameData
        let gameId = gameData.gameId
        let betId = gameData.betId
        let type = gameData.type
        let gamePath = gamePathForType(type)
        let uid = user.uid

        console.log('GAME DATA: ', gameData)


        //initialize players
        if(gameState.x === undefined && (gameData.gameStarted === "" || gameData.gameStarted === undefined)){
            //we need to setup the game
            //set expires to 10 second countdown
            console.log('Initiating Board')
            var initGameData = {x:uid, board:['','','','','','','','',''], turn:'x'}
            initGameData[uid] = 'x'

            const path = doc(db, gamePath, gameId)
            return updateDoc(path, {gameData:initGameData, gameStarted:uid})


        }else if(gameState.o === undefined && gameState.x !== undefined && gameState.x !== uid){

            let updateGameState = gameState
            console.log('Adding O Player')

            updateGameState[uid] = 'o'
            updateGameState['o'] = uid

            const path = doc(db, gamePath, gameId)
            return updateDoc(path, {gameData:updateGameState})

        }

        console.log('Updating UI with', gameState)


       let board = gameState.board
       if(board !== undefined){

           let turn = gameState.turn

           let myl = gameState[user.uid]

           setMyLetter(myl)
           setTurn(turn)
           setBoard(board)

       }

    }



  function getMyLetter(gameState){

      let letter = gameState[user.uid]

      console.log("My Letter", letter)

      if(letter !== undefined){
          return letter
      }

  }






 async function submitMove(data){

    if(game === undefined) return;
    let gamePath = gamePathForType(game.type)
    const path = doc(db, gamePath, game.gameId)

    let newboard = data.board
    let winState = data.winState

    console.log(newboard, turn)


    var move = gameState



    move.board = newboard
    move.turn = nextPlayer()

    let updates  = {gameData: move}


    if(winState !== 'none'){

        updates.gameComplete = true

        if(winState === 'winner'){
            updates.winner = user.uid

        }else{

            setShowIsTiePopup(true)
        }
    }

    await updateDoc(path, updates, {merge:true})

 }



   async function resetBoardSwitchPlayers(){


        if(game === undefined) return;

        let gamePath = gamePathForType(game.type)
        const path = doc(db, gamePath, game.gameId)

        let newState = {}

        let initX = gameState.x
        let initO = gameState.o

        newState[initX] = 'o'
        newState[initO] = 'x'

        newState.x = initO
        newState.o = initX

        newState.board = ['','','','','','','','','']
        newState.turn = 'x'


        await updateDoc(path, newState, {merge:true})

        setShowIsTiePopupAction(false)

    }




 function nextPlayer (){

    if(turn === 'x'){
        return 'o'
    }


    return 'x'


 }



function isMyTurn(currState){

    if(currState.turn === currState[user.uid]){

        return true
    }

    return false;


}

  const titleLetterForPlayer = (uid) =>{
      if(game === undefined){
          return ''
      }

      if(gameState === undefined){
          return ''
      }

      if(gameState[uid] === 'x'){
        return "X's"
      }
      return "0's"
  }



  function letterForTurn(){

      if(gameState === undefined){
          return ""
      }

      if(gameState.turn === 'x'){
          return "X's Turn"
      }
      return "O's Turn"
  }



  const rematch = (opponentUid) => {

      let string = 'home?rematch=true&type=2&opp=' + opponentUid
      history.replace(string)
  }



  return (
      <div className="xsos">

             <div className="xsos__top__info__view">

                <div className="vertical__auto">
                    <div className="medium__text">{game && titleLetterForPlayer(game.challengerUid)}</div>
                    <div className="opponent__info__container">
                        <AsyncImage className="profile__image" src={ (game && game.challengerImage !== "") ? game.challengerImage : Avatar} />
                        <div className="handle__text">{game && game.challengerHandle}</div>
                        <div className="spacer__horizontal"/>

                    </div>
                </div>


                <div className="vertical__auto">
                   <div className="medium__text">{game && titleLetterForPlayer(game.opponentUid)}</div>
                    <div className="opponent__info__container">
                        <AsyncImage className="profile__image" src={(game && game.opponentImage !== "") ? game.opponentImage : Avatar} />
                        <div className="handle__text">{game && game.opponentHandle}</div>
                        <div className="spacer__horizontal"/>

                    </div>
                </div>
            </div>


            <div className="horizontal__flex__center">
                <div className="large__yellow">{gameState && letterForTurn()}</div>
            </div>


            <div className="board__frame">
                <XOBoard board={board} turn={turn} currentUser={user} currentPlayerLetter={myLetter} turnCallback={(move) => submitMove(move)} />
            </div>

            {showIsTiePopupAction &&
                <ConfirmationPopup
                    handleClose={() => resetBoardSwitchPlayers()}
                    positive="Reset"
                    title={confirmationTitle}
                    body={confirmationBody}
                />
            }

            {showIsTiePopup &&
                <ConfirmationPopup
                    handleClose={() => setShowIsTiePopup(false)}
                    positive="Ok"
                    title={confirmationTitle}
                    body={"Your Opponent will see a similar message and reset the board."}
                />
            }

            {(showGameOver && game) &&
                <GameOverPopup
                    currentUser={user}
                    handleHidden={() => setShowGameOver(false)}
                    handleRematch={(opp) => rematch(opp)}
                    game={game}
                />
            }


      </div>

  )


}

export default XsOs;
