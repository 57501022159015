import React from 'react';
import '../CSS/RecentGames.css';
import RecentGameCell from './RecentGameCell';

const RecentGamesList = ({handleProfileSelected, data, handleCellSelected}) => {
    
    const final = [];
    
    for(let game of data){

        final.push(<div className="recent__cell__underline" key={game.gameId}><RecentGameCell key={game.gameId}  profileSelected={(value)=> handleProfileSelected(value)} data={game} cellSelected={(val) => handleCellSelected(val)}/></div>);
    }
    
    return (
    
        <div className="recent__table__container">
            {final}
        </div>
    )
}



export default RecentGamesList;