
import React from 'react';
import PropTypes from 'prop-types';
import '../CSS/GameCard.css';


import Chess from '../Assets/chess.png'
import Checkers from '../Assets/checkers.png'
import Trivia from '../Assets/trivia.png'
import WordUp from '../Assets/wordup.png'
import Match4 from '../Assets/connect4.png'
import Snowman from '../Assets/snowman.png'
import XsOs from '../Assets/tictactoe.png'
import AsyncImage from './AsyncImage'
function GameCard({type, name, handleSelected }) {
    

    
    
  function imageForType(){
      
    var image;
    switch (type) {
        case "0":
            image =  "None"
            break
            
        case "1":
            image =  Snowman
            break
            
        case "2":
            image =  XsOs
            break
            
        case "3":
            image =  Match4
            break
            
        case "4":
            image =  WordUp
            break
            
        case "5":
            image =  Checkers
            break

        case "6":
            image =  Chess
            break
            
        case "7":
            image =  Trivia
            break
            
        default:
            image =  "None"
            break
    }
      
    return image
  
  }
    
  
  return (
    <div className="card__container" onClick={() => handleSelected(type)}>
        <div className="image">
            <AsyncImage src={imageForType()} className="image__constraint"/>
        </div>
        <div className="overlay"/>
        <div className="overlay__text">{name}</div>
        
    </div>
  );
}


export default GameCard;