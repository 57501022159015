import React from 'react';
import '../CSS/Switch.css';

const Switch = ({identifier, isOn, handleToggle}) => {
    
  return (
    <div>
      <input
        className="react-switch-checkbox"
        checked={isOn}
        onChange={handleToggle}
        id={identifier}
        type="checkbox"
      />
      <label
        style={{ background: isOn && '#FFDA3C' }}
        className="react-switch-label"
        htmlFor={identifier}
      >
        <span className={`react-switch-button`} />
      </label>
    </div>
  );
};

export default Switch;