import React from 'react'

const TextInput = ({length, text, numbers, row_count, wrapping, handleChange}) => {

    const len = Number(length)
    const r = Number(row_count)

    
    if(text === undefined || text === ""){
        return (
            <textarea name="body"
                rows={r}
                placeholder={"Aa"}
                className="input__textbox"
                maxLength={len}
                inputmode={numbers !== undefined ? "decimals" : "text"}
                onChange={handleChange}
            />
        )
    }


    if(wrapping === 'off'){

        return (
            <textarea name="body"
                rows={r}
                placeholder={"Aa"}
                className="input__textbox"
                maxLength={len}
                overflow-x='scroll'
                defaultValue={text}
                inputmode={numbers !== undefined ? "decimals" : "text"}
                onChange={handleChange}
        
            />
        );
       
    }
    return (
        <textarea name="body"
            rows={r}
            placeholder={"Aa"}
            className="input__textbox"
            maxLength={len}
            defaultValue={text}
            inputmode={numbers !== undefined ? "decimals" : "text"}
            onChange={handleChange}
        
        />
    );

};



export default TextInput;