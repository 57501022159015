import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";


export const NavigationFrame = styled.div`
    width:100vw;
    background: transparent;
    justify-content: space-between;

`;

export const DesktopNav = styled.div`

    height: 85px;
    width:100vw;
    display: flex;
    align-items: center;
    z-index: 51;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    @media screen and (max-width: 768px) {
        height: 50px;
        position: fixed;
        top: 0;
        left: 0;
        
    }
`;




export const DesktopNavLogo = styled(Link)`
  cursor: pointer;
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
  align-items: center;
  padding: 15px;
  display:flex;

    @media screen and (max-width: 768px) {
        height: 50px;
        padding:2px;
        position: fixed;
        top: 0;
        left: 0;
        
    }
`;



export const DesktopNavLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 40px;
  cursor: pointer;
  &.active {
    color:black;
  }
  &:hover {
    color: #808080;
  }
`;



export const DesktopNavProfile = styled(Link)`

    cursor: pointer;
    color: #fff;
    text-decoration: none;
    align-items: center;
    width:50px;
    height:50px;
    border: 1px solid #fff;
    border-radius: 30.5px;
    overflow: hidden;
    margin-right:20px;
`;



export const DesktopNavMenu = styled.div`
  display: flex;
  align-items: center;
  padding-right:25px;

  @media screen and (max-width: 768px) {
    display: none;
    visibility:hidden;
}

`;


export const DesktopNavBtn = styled.nav`
  border-radius: 4px;
  padding: 10px;
  color: #fff;
  outline: none;
  border: 1px solid #fff;
  cursor: pointer;
  overflow: hidden;
  justify-content: space-evenly;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left:10px;
  margin-right:10px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }
`;




export const DesktopNavBtnLink = styled(Link)`
  border-radius: 4px;
  background: transparent;
  padding: 10px;
  color: #fff;
  outline: none;
  border: 1px solid #fff;
  cursor: pointer; 
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 14px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #000;
    color: #808080;
  }
`;







//Mobile Nav Bar 

export const MobileNav = styled.div`
    display: none;

  @media screen and (max-width: 768px) {
      background: #06361A;
      height: 50px;
      width: 100vw;
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      z-index: 51;
      padding-top:5px;
      padding-bottom: 2rem;
      justify-content: space-evenly;
    }
    
`;



export const MobileNavLogo = styled.div`

    display:flex;
    cursor: pointer;
    color: #fff;
    font-size: 1.5rem;
    text-decoration: none;
    align-items: center;

`;



export const MobileNavLink = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  &.active {
    color:#808080;
  }
  padding:0px;
  &:hover {
    color: #808080;
  }
`;


export const MobileNavMenu = styled.div`
    display:flex;
    align-items: center;
    width: 100vw;
    justify-content: space-around;
`;


export const MobileNavBtn = styled.nav`
  border-radius: 4px;
  color: #fff;
  outline: none;
  border: 1px solid #fff;
  cursor: pointer;
  justify-content: space-evenly;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:active {
    background: #808080;
    color: #808080;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }
`;


export const MobileNavBtnLink = styled(Link)`
  border-radius: 4px;
  background: transparent;
  padding:5px;
  cursor: pointer;
  justify-content: space-evenly;
  align-items:center;
  text-align: center;
  text-size:14px;
  color:white;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:active {
    background: #808080;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #808080;
  }
`;


export const MobileNavBtnContainer = styled.div`
    border-radius: 6px;
    background: transparent;
    width:32;
    height:32;
    justify-content: space-evenly;
    align-items:center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
;`