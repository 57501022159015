import React, {Component, useState, useEffect} from 'react';
//import ScoreBoard from './ScoreBoard';
import SnowmanLetter from './SnowmanLetter';
import "./SnowmanGame.css"

// Base component for Boggle Board
function SnowmanKeyboard({board, onSelect}){
  // loads shuffled letters from dice library
    const all_letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P","Q", "R", "S", "T", "U", "V", "W","X", "Y", "Z",]
    const [guesses, setGuesses] = useState(board)


    useEffect(() =>{
        setGuesses(board)
    }, [board]);



  const onLetterClick = (letter) => {


      if(guesses === undefined) return
      if (!guesses.includes(letter.toLowerCase())) {

        onSelect(letter)
      }

    }




    function resetLetters() {
      // resets all the letters to enabled and clears currentWord and currentKeys
      setGuesses([])

    }





    var letterNodes = all_letters.map((lettr) => {
              // creates nodes of Letter components from each letter in the letters array
        return (
            <SnowmanLetter
              guessedLetters={guesses}
              onSelected={() => onLetterClick(lettr)}
              item={lettr}
              key={lettr}>
            </SnowmanLetter>
        );
    });


    return (
      <div className="keyboard__grid">
        {letterNodes}
      </div>
    )

}

export default SnowmanKeyboard;
