import "./CSS/Theme.css";
import termsPDF from "./Assets/terms.pdf"

import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";





function Privacy() {
    
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    
    
    function onDocumentLoadSuccess({ numPages }) {
        console.log("NUM PAGES: ", numPages)
        setNumPages(numPages);
      }
  return (

       
        <div className="body__flex__fill">
            <iframe className="iframe__fill" src="https://docs.google.com/document/d/e/2PACX-1vTLHYv2F4TfpKO_tZrHxWN19axetIlYgvQVxgjpgqkW3PATNthelLBFgGIc5ZZmSf0KThAKf_j84DOF/pub?embedded=true"/>

        </div>

  );
}

  
export default Privacy;  