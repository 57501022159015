import React from 'react'
import './CheckersGame.css'


////cell is passed a single item in a row, and renders it out, it also calls it's grand-parent's swapper function on click 
export default class Cell extends React.Component{
	render() {
		return(
			<div className={'cell cell-'+this.props.cell} >
				<div onClick={this.props.handlePieceClick} data-row={this.props.rowIndex} data-cell={this.props.index} className="gamePiece"></div>
			</div>
		)
	}
};


