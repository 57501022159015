import React, { useEffect, useRef,  useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useHistory } from "react-router-dom";
import logo from "../../Assets/logo.svg";
import UserSingleton from "../../UserSingleton"

import ConfirmationPopup from "../../Components/ConfirmationPopup"
import GameOverPopup from "../../Components/GameOverPopup"
import ChooseWordPopup from "./ChooseWordPopup"
import Keyboard from "./Keyboard"

import snowman1 from "../../Assets/snowman_1.png"
import snowman2 from "../../Assets/snowman_2.png"
import snowman3 from "../../Assets/snowman_3.png"
import snowman4 from "../../Assets/snowman_4.png"
import snowman5 from "../../Assets/snowman_5.png"
import snowman6 from "../../Assets/snowman_6.png"
import snowman7 from "../../Assets/snowman_7.png"
import snowman8 from "../../Assets/snowman_8.png"
import snowmanLose from "../../Assets/snowman_lose.png"


import GameModel from "../../Models/Game"

import {auth, db, gamePathForType, getArchivedGame} from "../../firebase";

import "./SnowmanGame.css";
import "../../CSS/Theme.css";


const image_array = [snowman1, snowman2, snowman3, snowman4, snowman5, snowman6, snowman7, snowman8, snowmanLose]


function SnowmanGame() {

  const [game, setGame] = useState(undefined);
  const [gameState, setGameState ] = useState(undefined);
  const [gameId, setGameId] = useState(undefined);

  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  const instance = UserSingleton.getInstance()
  var gameListener;

  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showGameOver, setShowGameOver] = useState(false)
  const [showChooseWordPopup, setShowChooseWordPopup] = useState(false)

  const [confirmationTitle, setConfirmationTitle] = useState("Ready?")
  const [confirmationBody, setConfirmationBody] = useState("Guess the letters to complet the word. You have 7 wrong guesses before you lose");
  const [confirmationButton, setConfirmationButton] = useState("Start!");

  const {doc, onSnapshot, updateDoc, getDoc} = require('firebase/firestore');




  useEffect(() => {

    if (loading) return;
    if (!user) history.replace("/login");

    const queryParams = new URLSearchParams(window.location.search)
    const paramGameId = queryParams.get("id")
    const archived = queryParams.get("archived")

    if(gameId === undefined && gameListener === undefined && archived === null){

        setGameId(paramGameId)
        attachGameListener(paramGameId)

    }else if (gameId === undefined && archived !== null){

        setGameId(paramGameId)
        getArchive(paramGameId)

    }


  }, [user, loading, gameId, game]);


  async function attachGameListener(gameId){

        if(gameListener !== undefined){
            return
        }

        const gameRef = doc(db, "SnowmanGames", gameId);


        gameListener = onSnapshot(gameRef, (doc) => {

            const data = doc.data();

            if(doc !== undefined && data !== undefined){

               let game = new GameModel(data)

               setGame(game)
               setGameState(game.gameData)

               checkAndSetUIState(game)

            }else{

               setGame(undefined)
               setGameState(undefined)

            }
        });
    }




  async function getArchive(gameId){

        console.log("RETRIEVING ARCHIVAL")
        let game = await getArchivedGame(gameId)

        if(game === false){
            return
        }

        setGame(game)
        setGameState(game.gameData)

        checkAndSetUIState(game)
  }


  function isGameOver(data){

        if(data === undefined){
            return false
        }

        return data.gameComplete
  }


  // async function setGameOver(gameData){
  //
  //
  //
  //
  //
  //       let gamePath = gamePathForType(gameData.type)
  //       const path = doc(db, gamePath, gameData.gameId)
  //       await updateDoc(path, {winner: newwinner, gameComplete:true})
  //   }



    async function checkAndSetUIState(game){

        if(!game) return

        let gameState = game.gameData

        if(!gameState) return

        let gameOver = isGameOver(game)

        if(gameOver){

            console.log("Game Over, winner is: ", game.winner)

            if(gameListener !== undefined){
                gameListener()

            }
            setShowGameOver(true)

            return
        }


        let gameId = game.gameId

        let type = game.type
        let gamePath = gamePathForType(type)


        if(gameState === undefined || gameState.word === undefined || gameState.word === ""){
            setShowChooseWordPopup(true)

        }else if(gameState.word !== undefined && (game.gameStarted === "" || game.gameStarted === undefined) && user.uid !== game.challengerUid){

            setShowConfirmation(true)
        }


    }



    const initializeGame = async(word) => {

      setShowChooseWordPopup(false)

      //we need to setup the game
      let uid = user.uid
      //set expires to 10 second countdown
      let type = game.type
      let gamePath = gamePathForType(type)

      let initialUserState = {count:0, guesses:[], word:word.toLowerCase()}

      const path = doc(db, gamePath, gameId)

      await updateDoc(path, {gameData:initialUserState})

    }


    // const initGuesser = async() => {
    //
    //   console.log("-- Init Guesser --")
    //
    //   let gamePath = gamePathForType(1)
    //   //expires set to 12 hours in the future
    //
    //   let startedPath = 'gameData.' + user.uid
    //
    //   let updates = {}
    //   updates[startedPath] = 1
    //   const path = doc(db, gamePath, gameId)
    //   await updateDoc(path, updates)
    //
    //   setShowConfirmation(false)
    //
    // }

    const startGame = async() => {
        //start the game here
      console.log("-- Starting Game --- ", game)

      if(game === undefined) return
      let type = game.type
      let gamePath = gamePathForType(type)

      var timeObject = new Date();
        //expires set to 12 hours in the future
      timeObject = new Date(timeObject.getTime() + 1000 * 43200);
      console.log(timeObject);

      let turnPath = 'gameData.turn'
      let startedPath = 'gameData.' + user.uid

      let updates = {}

      updates.expires = timeObject
      updates[turnPath] = user.uid
      updates[startedPath] = 1
      updates.lastPlayed = game.challengerUid

      const path = doc(db, gamePath, game.gameId)

      if(game.gameStarted === "" || game.gameStarted === undefined){
            updates.gameStarted = user.uid
      }
      console.log("Updating Game path")

      await updateDoc(path, updates)
      setShowConfirmation(false)

  }




  //argument is expire time in seconds
  const isExpired = (expires) =>{

      let now = new Date().getTime() / 1000

      let timeRemaining = expires - now

      if(now > expires){

        return true;
      }

      return false

  }




  const submitGuess = async(letter) =>{

    if(game === undefined || game.gameStarted !== user.uid) return

    var currentCount = gameState.count
    let word = gameState.word
    let guesses = gameState.guesses


    let indexes = getAllOccurancesOf(letter.toLowerCase(), word.toLowerCase())
    if(indexes.length === 0){
      currentCount += 1
    }

    let ltr = letter.toLowerCase()
    guesses.push(ltr)

    let type = game.type
    let gamePath = gamePathForType(type)

    let updates = {}

    let state = gameState

    updates["gameData.count"] = currentCount
    updates["gameData.guesses"] = guesses
    updates["gameData.word"] = word

    var newwinner = "";
    var wordRevealed = checkFullWordRevealed()

    //determine if winner
    if(currentCount > 6){
        updates.gameComplete = true
        updates.winner = game.challengerUid
    }else if(wordRevealed){
        updates.gameComplete = true
        updates.winner = game.opponentUid
    }

    console.log("Submitting: ",updates)

    const path = doc(db, gamePath, gameId)

    await updateDoc(path, updates)

  }




  function getAllOccurancesOf(letter, word){
    var indexes = []

    for (const i in word){

       if(word[i] === letter){
         indexes.push('')
       }
    }

    return indexes

  }

  const oppositeUid = () => {

    var theirUid = ""
    for(const uid of game.players){
        if(uid !== user.uid){
           theirUid = uid
        }
    }

    return theirUid
  }


  const rematch = (opponentUid) => {

      let string = 'home?rematch=true&type=7&opp=' + opponentUid
      history.replace(string)
  }


  const LetterSpace = (info) =>{

    let showLetter = gameState.guesses.includes(info.letter)

    return(
      <div className="blank__underline">
        {showLetter ? info.letter : " - " }
      </div>
    )
  }


  function checkFullWordRevealed(){
    if(gameState === undefined) return

    let word = gameState.word
    let guesses = gameState.guesses
    let rows = [];
    for (const char of word) {
      if(!guesses.includes(char)){
        return false
      }
    }
    return true
  }


  function guessedWordLetters(){

    if(gameState === undefined) return
    if(gameState.word === undefined) return

    let wordChar = gameState.word.split('')
    let rows = [];
    for (const char of wordChar) {
      rows.push(<LetterSpace letter={char.toLowerCase()}/>);
    }

    return (<div className="word__container">{rows}</div>)
  }


  return (
    <div className="snowman">

        <div className="snowman__top__info__view">

            <div className="vertical__auto">
                <div className="medium__text">Challenger</div>
                <div className="opponent__info__container">
                    <img className="profile__image" src={game && game.challengerImage} />
                    <div className="handle__text">{game && game.challengerHandle}</div>
                    <div className="spacer__horizontal"></div>
                </div>
            </div>

            <div className="vertical__auto">
               <div className="medium__text">Opponent</div>
                <div className="opponent__info__container">
                    <img className="profile__image" src={game && game.opponentImage} />
                    <div className="handle__text">{game && game.opponentHandle}</div>
                    <div className="spacer__horizontal"></div>

                </div>
            </div>
        </div>


        <div className="game__info__container">

            <div className="large__text">Wrong Guesses: {gameState && gameState.count} </div>

        </div>




        {
        game && gameState && <div className="snowman__game__container">

            <img className="image__container" src={image_array[gameState.count]}/>


            {game && gameState && guessedWordLetters()}

            {game && gameState && <Keyboard board={gameState.guesses} onSelect={(lttr) => submitGuess(lttr)}/>}


        </div>
        }


        {showConfirmation &&
                <ConfirmationPopup
                    handleClose={() => startGame()}
                    positive={confirmationButton}
                    title={confirmationTitle}
                    body={confirmationBody}
                />
            }
        {showGameOver &&
                <GameOverPopup
                    currentUser={user}
                    handleHidden={() => setShowGameOver(false)}
                    handleRematch={(opp) => rematch(opp)}
                    game={game}
                />
            }

            {showChooseWordPopup &&
              <ChooseWordPopup
                  key={Math.random()}
                  onHide={() => setShowChooseWordPopup(false)}
                  word={""}
                  handleUpdate={(word) => initializeGame(word)}/>

              }
    </div>
  );
}

export default SnowmanGame;
