import React, { useEffect, useRef,  useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useHistory } from "react-router-dom";
import logo from "../../Assets/logo.svg";
import UserSingleton from "../../UserSingleton"
import ConfirmationPopup from "../../Components/ConfirmationPopup"
import GameOverPopup from "../../Components/GameOverPopup"

import wrongAnswer from "../../Assets/wrong_answer.svg"
import rightAnswer from "../../Assets/correct_answer.svg"

import dice from './dice.json';

import GameModel from "../../Models/Game"

import {auth, db, gamePathForType, getArchivedGame} from "../../firebase";

import "./WordUp.css";
import WordUpBoard from './WordUpBoard';

import "../../CSS/Theme.css";

function WordUp() {

  const [game, setGame] = useState(undefined);
  const [gameState, setGameState ] = useState(undefined);
  const [gameId, setGameId] = useState(undefined);

  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  const instance = UserSingleton.getInstance()

  var gameListener = undefined
  var timer = undefined

  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showGameOver, setShowGameOver] = useState(false)

  const [expired, setExpired] = useState(false)

  const [confirmationTitle, setConfirmationTitle] = useState("Ready?")
  const [confirmationBody, setConfirmationBody] = useState("You have 60 seconds to find as many words as possible.")
  const [confirmationButton, setConfirmationButton] = useState("Start!")

  const [showIsTiePopup , setShowIsTiePopup] = useState(false)
  const [showIsTiePopupAction , setShowIsTiePopupAction] = useState(false)

  const [timeRemaining, setTimeRemaining] = useState("60 seconds")
  const {doc, onSnapshot, updateDoc, getDoc} = require('firebase/firestore')


  useEffect(() => {

    if (loading) return;
    if (!user) history.replace("/login");

    const queryParams = new URLSearchParams(window.location.search)
    const paramGameId = queryParams.get("id")
    const archived = queryParams.get("archived")

    if(gameId === undefined && gameListener === undefined && archived === null){

        setGameId(paramGameId)
        attachGameListener(paramGameId)

    }else if (gameId === undefined && archived !== null){

        setGameId(paramGameId)
        getArchive(paramGameId)
    }


    return () => unmountTimer()

  }, [user, loading, gameId, game]);


  function unmountTimer(){
    if(timer !== undefined){
      clearInterval(timer)

    }
  }



  async function attachGameListener(gameId){

        if(gameListener !== undefined){
            return
        }

        const gameRef = doc(db, "BoggleGames", gameId);

        gameListener = onSnapshot(gameRef, (doc) => {

            const data = doc.data();

            if(doc !== undefined && data !== undefined){

               let game = new GameModel(data)


               setGame(game)
               setGameState(game.gameData)

               checkAndSetUIState(game)
            }else{
               setGame(undefined)
               setGameState(undefined)
            }
        });
  }




  async function getArchive(gameId){

        let game = await getArchivedGame(gameId)

        if(game === false){
            return
        }

        setGame(game)
        setGameState(game.gameData)

        checkAndSetUIState(game)
  }




  function isGameOver(gameData){

        if(gameData === undefined){
            return false
        }

        if(gameData.gameComplete === true){
            return true
        }
  }



  async function checkAndSetUIState(gameData){

        let gameOver = isGameOver(gameData)

        if(gameOver){


            setExpired(true)
            if(gameData.winner !== ''){

                setTimeRemaining("Times Up!")
                setShowGameOver(true)
                console.log("Game Over, winner is: ", gameData.winner)

            }else{

                setTimeRemaining("Times Up!")

                let myUserData = gameData[user.uid]
                if(myUserData.noReset === undefined){
                    setShowIsTiePopupAction(true)
                }
                //return here to maintain the game listener
                return
            }

            if(gameListener !== undefined){
                gameListener()
            }

            return
        }



        let gameState = gameData.gameData
        let gameId = gameData.gameId
        let type = gameData.type
        let gamePath = gamePathForType(type)
        let uid = user.uid

        let userGameState = gameState[uid]


        if(userGameState === undefined && gameData.gameStarted === ""){

            //we need to setup the game
            //set expires to 10 second countdown
            let initialUserState = {expires:0, words:[], score: 0, timerStarted:false, turnComplete:false}
            let initGameData = {}

            initGameData[uid] = initialUserState

            const path = doc(db, gamePath, gameId)
            await updateDoc(path, {gameData:initGameData}, {merge:true})

        }else if(userGameState === undefined){

          //Player  2 has joined
            let initialUserState = {expires:0, words:[], score: 0, timerStarted:false, turnComplete:false}

            let userPath = 'gameData.' + uid
            let updates = {}
            updates[userPath] = initialUserState

            const path = doc(db, gamePath, gameId)
            await updateDoc(path, updates, {merge:true})

        }

        if(!userGameState){
           return
        }


        if(userGameState !== undefined && userGameState.timerStarted === false && timer === undefined){
            //we can show the start the game prompt and return
            setShowConfirmation(true)
            return
        }


        //else update the UI for the ongoing game state
        if(timer === undefined && userGameState !== undefined){
            //no timer and a user game state. This should start the timer
            startTimer(userGameState, gameData.type, gameData.gameId, gameData.gameStarted)
        }else if(userGameState !== undefined && userGameState.timerStarted === true && userGameState.turnComplete && gameData.gameComplete === false){
            checkGameOver(gameData)
        }
  }





  //generate 16 letters from the provided dice options in dice.json
  function loadNewLetters() {
        var letters = []
        var randomFace;
        var randomDie;
        var usedDice = [];
        // loops through 25 rows of dice in dice.json
        do {
          // chooses a random dice to use from the 25
          randomDie = Math.floor(Math.random() * 16);
          // checks to see if the die was used already
          if (usedDice.indexOf(randomDie) == -1) {

            console.log(randomDie, dice)
            usedDice.push(randomDie);
            // chooses a random face for the dice to land on
            randomFace = Math.floor(Math.random() * 6);
            // adds the chosen letter to the letters array as an object
            // if (dice[randomDie][randomFace] == 'q'){
            //   letters.push({letter: "Qu", key: letters.length, enabled: true});
            // } else {
              letters.push({letter: dice[randomDie][randomFace].toUpperCase(),
                            key: letters.length, enabled: true});
            // }
          }
        } while (usedDice.length < 16);

        return letters
  }




  //Returns array of strings, each entry corresponds to the board position, left to right, top to bottom (0,0 is the top left square)
  function getLettersOnly(full){

        var letters = []

        for(const lettr of full){
            letters.push(lettr.letter)
        }
        return letters
  }


  const startGame = async() => {
        //start the game here
        //set users expire time 90 seconds in the future and timerStarted to true,

        let type = game.type
        let id = game.gameId
        let gamePath = gamePathForType(type)

        var timeObject = new Date();
        timeObject = new Date(timeObject.getTime() + 1000 * 61);


        let updates = {}

        let expiresPath = 'gameData.' + user.uid + '.expires'
        let timerStartedPath = 'gameData.' + user.uid + '.timerStarted'

        updates[expiresPath] = timeObject
        updates[timerStartedPath] = true

        const path = doc(db, gamePath, id)


        if(game.gameStarted === "" || game.gameStarted === undefined){

            let boardPath = 'gameData.board'

            let letters = loadNewLetters()
            let simpleLetterArr = getLettersOnly(letters)

            updates.gameStarted = user.uid
            updates[boardPath] = simpleLetterArr
            updates.lastPlayed = user.uid

        }

        await updateDoc(path, updates, {merge:true})

        setShowConfirmation(false)
  }




  async function checkGameOver(fullGame){

    let gameData = fullGame.gameData
    let type = fullGame.type
    let id = fullGame.gameId


    let gamePath = gamePathForType(type)
    const path = doc(db, gamePath, id)

    if(fullGame === undefined || gameData === undefined){
        return {complete:'none', winner:''}
    }

    if(fullGame.opponentUid === undefined  || fullGame.opponentUid === '' ){
        return {complete:'none', winner:''}
    }

    let opponentId = oppositeUid(fullGame)

    if(gameData[opponentId] === undefined){
      return {complete:'none', winner:''}
    }

    let myGameData = gameData[user.uid]
    let theirGameData = gameData[opponentId]



    if(theirGameData.turnComplete === true && myGameData.turnComplete === true){

        var updates = {}

        updates.gameComplete = true

        let winner = determineWinner(fullGame)

        if(winner !== ''){
            updates.winner = winner
        }else{
            updates.winner = ''
            setShowIsTiePopup(true)
        }

        console.log("Updates: ", updates)

        await updateDoc(path, updates, {merge:true})
    }

    return
  }



  function determineWinner(game){

    if(game === undefined)return
    let gameData = game.gameData

    if(gameData === undefined)return

    let myUid = user.uid
    let opponentId = oppositeUid(game)

    let theirScore = gameData[opponentId].score
    let myScore = gameData[myUid].score

    if(myScore > theirScore){
        return myUid
    }else if(theirScore > myScore){
        return opponentId
    }else{
        return ''
    }
  }


  const updateTimer = async(gameStateData, type, gameId, gameStarted) =>{

     let now = new Date().getTime() / 1000

      if(gameStateData === undefined){
          return
     }

      let expires = gameStateData.expires.seconds
      let timeRemaining = expires - now

      if(now > expires){

        if(timer !== undefined){
            clearInterval(timer)
        }

        setTimeRemaining("Times Up!");
        setExpired(true)

        let gamePath = gamePathForType(type)
        const path = doc(db, gamePath, gameId)

        let userCompletePath = 'gameData.' + user.uid + '.turnComplete'
        let noResetPath = 'gameData.' + user.uid + '.noReset'

        let updates = {}
        updates[userCompletePath] = true

        if(gameStarted === user.uid){
          updates[noResetPath] = true
        }

        await updateDoc(path, updates, {merge:true})

        return

      }

      let str = Math.trunc(timeRemaining) + " seconds"
      setTimeRemaining(str)
  }

  const startTimer = (gameStateData,type, gameId) => {
        timer = setInterval(() => {
            updateTimer(gameStateData,type, gameId);
        }, 1000)
    }



  //argument is expire time in seconds
  const isExpired = (userGameState) =>{

      if(userGameState === undefined || userGameState.expires === undefined){
        return false
      }
      let expires = userGameState.expires.seconds

      let now = new Date().getTime() / 1000
      let timeRemaining = expires - now

      if(now > expires){
        return true
      }

      return false

  }




  function oppositeUid (gameData) {

    var theirUid = ""
    for(const uid of gameData.players){
        if(uid !== user.uid){
           theirUid = uid
           break;
        }
    }
    return theirUid
  }



  const wordCheck = async(wordinfo) =>{


    if(gameState[user.uid] !== undefined && gameState[user.uid].timerStarted === false && gameState[user.uid].turnComplete === false){
        return
    }

    let gamePath = gamePathForType(game.type)
    const path = doc(db, gamePath, gameId)

    let userPath = 'gameData.' + user.uid
    let myUpdates = gameState[user.uid]

    let total = myUpdates.score + wordinfo.score
    myUpdates.score = total

    var wordList = myUpdates.words
    let word = wordinfo.word

    if(wordList !== undefined){
        wordList.push(word)
    }else{
        wordList = [word]
    }

    myUpdates.words = wordList

    let updates = {}
    updates[userPath] = myUpdates

    await updateDoc(path, updates)

  }



  const rematch = (opponentUid) => {

      let string = 'home?rematch=true&type=7&opp=' + opponentUid
      history.replace(string)
  }


  async function resetGame(){

        if(game === undefined) return;

        let gamePath = gamePathForType(game.type)
        const path = doc(db, gamePath, game.gameId)

        let initState = {expires:0, words:[], score: 0, timerStarted:false, turnComplete:false}
        let opponentUid = oppositeUid(game)

        let userPath1 = 'gameData.' + user.uid
        let userPath2 = 'gameData.' + opponentUid
        let updates = {gameComplete: false, winner:""}

        updates[userPath1] = initState
        updates[userPath2] = initState

        await updateDoc(path, updates, {merge:true})

        setShowIsTiePopupAction(false)
  }


  return (
    <div className="wordup">

        <div className="wordup__top__info__view">

            <div className="vertical__auto">
                <div className="opponent__info__container">
                    <img className="profile__image" src={game && game.challengerImage} />
                    <div className="handle__text">{game && game.challengerHandle}</div>
                    <div className="spacer__horizontal"></div>
                    <div className="large__yellow">{(gameState && gameState[game.challengerUid] && gameState[game.challengerUid].score) ? gameState[game.challengerUid].score : 0}</div>
                </div>
            </div>

            <div className="vertical__auto">
                <div className="opponent__info__container">
                    <img className="profile__image" src={game && game.opponentImage} />
                    <div className="handle__text">{game && game.opponentHandle}</div>
                    <div className="spacer__horizontal"></div>
                    <div className="large__yellow">{(gameState && gameState[game.opponentUid] && gameState[game.opponentUid].score) ? gameState[game.opponentUid].score : 0}</div>
                </div>
            </div>
        </div>


        <div className="game__info__container">

            <div className="horizontal__flex__center">
                <div className="medium__text">Time Remaining:</div>
                <div className="spacer__horizontal"/>
                <div className="large__yellow">{(gameState && gameState[user.uid]) && timeRemaining}</div>
            </div>
        </div>




        {(gameState && gameState.board &&  gameState[user.uid]) &&
             <WordUpBoard
               existingWords={gameState[user.uid].words}
               board={gameState.board}
               expires={expired}
               submitCallback={(data) => wordCheck(data)}
             />
        }

        {(showIsTiePopupAction && game) &&
                <ConfirmationPopup
                    handleClose={() => resetGame()}
                    positive={"Reset"}
                    title={"It's a Tie!"}
                    body={"There must be a winner! Reset the game and take you're opponents money!" }
                />
        }

        {(showIsTiePopup && game) &&
                <ConfirmationPopup
                    handleClose={() => setShowIsTiePopup(false)}
                    positive={"Ok"}
                    title={"It's a Tie!"}
                    body={"There must be a winner!  Your Opponent will see a similar message and reset the board."}
                />
        }


        {showConfirmation &&
                <ConfirmationPopup
                    handleClose={() => startGame()}
                    positive={confirmationButton}
                    title={confirmationTitle}
                    body={confirmationBody}
                />
        }


        {showGameOver &&
                <GameOverPopup
                    currentUser={user}
                    handleHidden={() => setShowGameOver(false)}
                    handleRematch={(opp) => rematch(opp)}
                    game={game}
                />
            }
    </div>
  );
}

export default WordUp;
