import styled from "styled-components";
import React from 'react'
import Modal from 'react-overlays/Modal';
import "../CSS/Theme.css"
import "../CSS/ProfilePopupView.css"
import user_image from "../Assets/avatar.svg";
import ProfileRecentGamesList from "../Components/ProfileRecentGamesList"

import AsyncImage from "./AsyncImage"


    
const ProfileBackdrop = styled("div")`
        position: fixed;
        z-index: 1040;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        opacity: 0.5;

        `;
        
        
const ViewProfileModal = styled(Modal)`
            position: fixed;
            width: 40vw;
            max-height:99%;
            overflow-y:auto;
            z-index: 1040;
            top: ${() => 1}%;
            left: ${() => 30}%;
            border-radius: 15px;
            background-color: var(--main);
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
            padding-top:10px;
            padding-bottom:30px;
            padding-left:2.5vw;
            padding-right:2.5vw;

        @media only screen and (max-width: 768px) {
                position: fixed;
                width: 90vw;
                max-height:99%;
                overflow-y:auto;
                z-index: 1040;
                top: ${() => 1}%;
                left: 2.5vw;
                padding-top:20px;
                padding-bottom:20px;
                border-radius: 15px;
                background-color: var(--main);
                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

    }
`;
const renderBackdrop = (props) => <ProfileBackdrop {...props} />;




export default class ProfileView extends React.Component {

    constructor(props){
        super(props)
              
              
        this.handleHidden = props.handleHidden
        this.hideChallengeButton = props.hideChallengeButton
        this.theirProfile = props.theirProfile
        this.myProfile = props.myProfile
        this.handleRequest = props.handleRequest
        this.handleRemoveFriend = props.handleRemoveFriend
        this.handleChallenge = props.handleChallenge
        this.profileRecentGameData = props.profileRecentGameData
          
        this.state = {
            record:"0 Wins - 0 Losses",
            hideFriendRequestButton:props.hideFriendRequestButton,
            hideChallengeButton:props.hideChallengeButton
        }


    }

    

    
    
    
    
    
    render(){
        
        
            
        const hideProfileView = () =>{

            this.handleHidden()
        }
        
        
        const getRecord = () =>{

            var record = "0 Wins - 0 Losses"
    
            if(this.theirProfile !== undefined){
                record  = this.theirProfile.wins + " Wins - " + this.theirProfile.losses + " Losses"
            }
    
            return record
        
        }
        
        
        
        const checkIsFriend = () =>{

            var isFriend = false
            
            if(this.myProfile !== undefined && this.theirProfile !== undefined && this.myProfile.friends !== undefined && this.myProfile.friends.includes(this.theirProfile.uid)){
                isFriend = true
            }
            
            return isFriend
        }
        
        const checkIsMe = () =>{

            
            var isMe = false
            if(this.myProfile !== undefined && this.theirProfile !== undefined && this.myProfile.uid === this.theirProfile.uid){

                isMe = true
            }
            
            return isMe
        
        }
        
        
        
        const handleFriendRequest = () => {
            
            
            if(checkIsFriend()){
                this.handleRemoveFriend(this.theirProfile.uid)
            }else{

                this.handleRequest(this.theirProfile.uid)
            }
            
        }

        const handleChallengeRequest = () => {

            this.handleChallenge(this.theirProfile)
        }
        
        
        
        return(
        
            <ViewProfileModal
                show={true}
                onHide={hideProfileView}
                renderBackdrop={renderBackdrop}
                aria-labelledby="modal-label"
            >
                    
            <div className="center__profile__content">
                
                <div className="top__close__view">
                    <div> </div>
                    <button className="white__background__theme__button__no__margin" onClick={hideProfileView}>Close</button>
                </div>

                <div className="profile__popup__image__container">
                    <AsyncImage src={(this.theirProfile !== undefined && this.theirProfile.image !== "") ? this.theirProfile.image : user_image} className="profile__popup__user__image" alt="Profile Image"/>
                </div>
    
                <div className="horizontal__name__handle">
                        <div className="title__yellow">{this.theirProfile !== undefined ? this.theirProfile.name : ""}</div>
                        <div className="user__handle__text">@{this.theirProfile !== undefined ? this.theirProfile.handle : ""}</div>

                </div>

                <div className="medium__text__gray">{this.theirProfile !== undefined ? getRecord() : "0 Wins - 0 Losses"}</div>
                <div className="name__text__profile">Recent Games</div>

                <div className="profile__recent__games__container">
                    {(this.profileRecentGameData !== undefined && this.theirProfile !== undefined) ? <ProfileRecentGamesList data={this.profileRecentGameData} uid={this.theirProfile.uid}/> : "No Recent Games :("}
                </div>

                <div className={checkIsMe() ? "hidden" : "horizontal__fill__buttons"}>
                    <button className={this.state.hideFriendRequestButton ? "hidden" : "yellow__background__black__button"} onClick={handleFriendRequest}>{checkIsFriend() ? "Remove Friend" : "Send Friend Request"}</button>
                    <button className={this.state.hideChallengeButton ? "hidden" : "white__background__theme__button"} onClick={handleChallengeRequest}>Challenge!</button>
     
                </div>
            </div>
            </ViewProfileModal>
        
        
        
        
        
        );
    
    }

}
