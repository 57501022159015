import React, { Component, useState, useEffect } from 'react'
import './CheckersGame.css'
import Row from './Row'
import Cell from './Cell'



//game board calls row for each item in the board array
export default function GameBoard({pBoard, turn, viewing, currentUser, currentPlayerColor, turnCallback}) {

    const [boardData, setBoardData] = useState(pBoard)
    const [activePlayer, setActivePlayer] = useState(turn)
    const [viewingPlayer, setViewingPlayer] = useState(viewing)

    useEffect(() =>{

        setBoardData(pBoard)
        setActivePlayer(turn)
        setViewingPlayer(viewing)

    }, [pBoard, turn, viewing]);

    const moveCallback = (data) =>{

        turnCallback(data)
    }



    function handlePieceClick(e) {

        if(canMove() === false) return;

    		var rowIndex = parseInt(e.target.attributes['data-row'].nodeValue);
    		var cellIndex = parseInt(e.target.attributes['data-cell'].nodeValue);

    		if (boardData[rowIndex][cellIndex].indexOf(activePlayer) > -1) {

            console.log("Players Own Piece")

    			//this is triggered if the piece that was clicked on is one of the player's own pieces, it activates it and highlights possible moves
    			var temp = boardData.map(function(row){return row.map(function(cell){return cell.replace('a', '')});}); //un-activate any previously activated pieces
    			temp[rowIndex][cellIndex] = 'a'+temp[rowIndex][cellIndex];
          // setBoardData(temp)
          console.log("POSSIBLE MOVES: ", temp)
    			highlightPossibleMoves(rowIndex, cellIndex, temp);

    		}

    		else if(boardData[rowIndex][cellIndex].indexOf('h') > -1) {

          console.log("Highlighted Square")


    			//this is activated if the piece clicked is a highlighted square, it moves the active piece to that spot.
    			var temp = executeMove(rowIndex, cellIndex, boardData, activePlayer);
    			//is the game over? if not, swap active player
    			setBoardData(temp)


  			if (winDetection(temp, activePlayer)) {

  				console.log(activePlayer+ ' won the game!');

                  let updates = {activePlayer:activePlayer, board:temp}
                  updates.gameOver = true
                  updates.winner = activePlayer
                  moveCallback(updates)


        }else {

          //here we change the active player
  				var aPlayer = (activePlayer == 'r' ? 'b' : 'r');
                  setActivePlayer(aPlayer)
                  let updates = {activePlayer:aPlayer, board:temp}
                  moveCallback(updates)
  			}
		}


	}


	function executeMove(rowIndex, cellIndex, board, activePlayer) {

		var activePiece;
		for (var i = 0; i < board.length; i++) {
			//for each row
			for (var j = 0; j < board[i].length; j++) {
				if (board[i][j].indexOf('a')>-1) {
					activePiece = board[i][j];
				}
			}
		}
		//make any jump deletions
		var deletions = board[rowIndex][cellIndex].match(/d\d\d/g);

		if (typeof deletions !== undefined && deletions !== null && deletions.length > 0) {
			for (var k = 0; k < deletions.length; k++) {
				var deleteCoords = deletions[k].replace('d', '').split('');
				board[deleteCoords[0]][deleteCoords[1]] = '-';
			}
		}
		//remove active piece from it's place
		board = board.map(function(row){return row.map(function(cell){return cell.replace(activePiece, '-')});});
		//unhighlight
		board = board.map(function(row){return row.map(function(cell){return cell.replace('h', '-').replace(/d\d\d/g, '').trim()});});
		//place active piece, now unactive, in it's new place
		board[rowIndex][cellIndex] = activePiece.replace('a', '');
		if ( (activePlayer == 'b' && rowIndex == 7) || (activePlayer == 'r' && rowIndex == 0) ) {
			board[rowIndex][cellIndex]+= ' k';
		}
		return board;
	}


	function highlightPossibleMoves(rowIndex, cellIndex, board) {

		//unhighlight any previously highlighted cells
		var temp = board.map(function(row){return row.map(function(cell){return cell.replace('h', '-').replace(/d\d\d/g, '').trim()});});

		var possibleMoves = findAllPossibleMoves(rowIndex, cellIndex, temp, activePlayer);
    console.log("POSSIBLE MOVES: ", possibleMoves)

		//actually highlight the possible moves on the board
		//the 'highlightTag' inserts the information in to a cell that specifies
		for (var j = 0; j < possibleMoves.length; j++) {
			var buildHighlightTag = 'h ';
			for (var k = 0; k < possibleMoves[j].wouldDelete.length; k++) {
				buildHighlightTag += 'd'+String(possibleMoves[j].wouldDelete[k].targetRow) + String(possibleMoves[j].wouldDelete[k].targetCell)+' ';
			}
			temp[possibleMoves[j].targetRow][possibleMoves[j].targetCell] = buildHighlightTag;
		}


		setBoardData(temp)
	}


	function findAllPossibleMoves(rowIndex, cellIndex, board, activePlayer) {

		var possibleMoves = [];
		var directionOfMotion = [];
		var leftOrRight = [1,-1];
		var isKing = board[rowIndex][cellIndex].indexOf('k') > -1;

			directionOfMotion.push(-1);


		//if it's a king, we allow it to both go forward and backward, otherwise it can only move in it's color's normal direction
		//the move loop below runs through every direction of motion allowed, so if there are two it will hit them both
		if (isKing) {
			directionOfMotion.push(directionOfMotion[0]*-1);
		}

		//normal move detection happens here (ie. non jumps)
		//for each direction of motion allowed to the piece it loops (forward for normal pieces, both for kings)
		//inside of that loop, it checks in that direction of motion for both left and right (checkers move diagonally)
		//any moves found are pushed in to the possible moves array
		for (var j = 0; j < directionOfMotion.length; j++) {
			for (var i = 0; i < leftOrRight.length; i++) {
				if (
					typeof board[rowIndex+directionOfMotion[j]] !== 'undefined' &&
					typeof board[rowIndex+directionOfMotion[j]][cellIndex + leftOrRight[i]] !== 'undefined' &&
					board[rowIndex+directionOfMotion[j]][cellIndex + leftOrRight[i]] == '-'
				){
					if (possibleMoves.map(function(move){return String(move.targetRow)+String(move.targetCell);}).indexOf(String(rowIndex+directionOfMotion[j])+String(cellIndex+leftOrRight[i])) < 0) {
						possibleMoves.push({targetRow: rowIndex+directionOfMotion[j], targetCell: cellIndex+leftOrRight[i], wouldDelete:[]});
					}
				}
			}
		}

		//get jumps
		var jumps = findAllJumps(rowIndex, cellIndex, board, directionOfMotion[0], [], [], isKing, activePlayer);

		//loop and push all jumps in to possibleMoves
		for (var i = 0; i < jumps.length; i++) {
			possibleMoves.push(jumps[i]);
		}
		return possibleMoves;
	}



	function findAllJumps(sourceRowIndex, sourceCellIndex, board, directionOfMotion, possibleJumps, wouldDelete, isKing, activePlayer) {
		//jump moves
		var thisIterationDidSomething = false;
		var directions = [directionOfMotion];
		var leftOrRight = [1, -1];
		if (isKing) {
			//if it's a king, we'll also look at moving backwards
			directions.push(directions[0]*-1);
		}
		//here we detect any jump possible moves
		//for each direction available to the piece (based on if it's a king or not)
		//and for each diag (left or right) we look 2 diag spaces away to see if it's open and if we'd jump an enemy to get there.
		for (var k = 0; k < directions.length; k++) {
			for (var l = 0; l < leftOrRight.length; l++) {
				let lor = leftOrRight[l]
				if (
					typeof board[sourceRowIndex+directions[k]] !== 'undefined' &&
					typeof board[sourceRowIndex+directions[k]][sourceCellIndex+leftOrRight[l]] !== 'undefined' &&
					typeof board[sourceRowIndex+(directions[k]*2)] !== 'undefined' &&
					typeof board[sourceRowIndex+(directions[k]*2)][sourceCellIndex+(leftOrRight[l]*2)] !== 'undefined' &&
					board[sourceRowIndex+directions[k]][sourceCellIndex+leftOrRight[l]].indexOf((activePlayer == 'r' ? 'b' : 'r')) > -1 &&
					board[sourceRowIndex+(directions[k]*2)][sourceCellIndex+(leftOrRight[l]*2)] == '-'
				){
					if (possibleJumps.map(function(move){return String(move.targetRow)+String(move.targetCell);}).indexOf(String(sourceRowIndex+(directions[k]*2))+String(sourceCellIndex+(leftOrRight[l]*2))) < 0) {
						//this eventual jump target did not already exist in the list
						var tempJumpObject = {
							targetRow: sourceRowIndex+(directions[k]*2),
							targetCell: sourceCellIndex+(leftOrRight[l]*2),
							wouldDelete:[
								{
									targetRow:sourceRowIndex+directions[k],
									targetCell:sourceCellIndex+leftOrRight[l]
								}
							]
						};
						for (var i = 0; i < wouldDelete.length; i++) {
							tempJumpObject.wouldDelete.push(wouldDelete[i]);
						}
						possibleJumps.push(tempJumpObject);
						thisIterationDidSomething = true;
					}
				}
			}
		}

		//if a jump was found, thisIterationDidSomething is set to true and this function calls itself again from that source point, this is how we recurse to find multi jumps
		if(thisIterationDidSomething) {
			for (var i = 0; i < possibleJumps.length; i++) {
				var coords = [possibleJumps[i].targetRow, possibleJumps[i].targetCell];
				var children = findAllJumps(coords[0], coords[1], board, directionOfMotion, possibleJumps, possibleJumps[i].wouldDelete, isKing, activePlayer);
				for (var j = 0; j < children.length; j++) {
					if (possibleJumps.indexOf(children[j]) < 0) {
						possibleJumps.push(children[j]);
					}
				}
			}
		}
		return possibleJumps;
	}




	function winDetection(board, activePlayer) {
		var enemyPlayer = (activePlayer == 'r' ? 'b' : 'r');
		var result = true;
		for (var i = 0; i < board.length; i++) {
			for (var j = 0; j < board[i].length; j++) {
				if (board[i][j].indexOf(enemyPlayer) > -1) {
					result = false;
				}
			}
		}
		return result;
	}
	function cloneBoard (board) {
        var output = [];
        for (var i = 0; i < board.length; i++) output.push(board[i].slice(0));
        return output;
    }

    function canMove(){

        let color = currentPlayerColor.charAt(0)

        if(activePlayer === color){
            return true

        }

        return false

    }


    var rowIndex;
    return (
			<div className="container">
				<div className={'board'+viewingPlayer}>
					{
						boardData.map(function(row, index) {
							return (<Row rowArr={row} handlePieceClick={handlePieceClick.bind(this)} rowIndex={index}/>)
						},this)
					}
				</div>

			</div>
		);

};
