import React from 'react';
import '../CSS/ChallengeList.css';
import ChallengeListCell from './ChallengeListCell';

const ChallengesList = ({handleProfile, handleDeclined, handleAccepted, allGames, myUid}) => {
    
    const final = [];
    
    for(const game of allGames){
        if(game !== undefined){
            final.push(<ChallengeListCell myUid={myUid} handleProfileSelected={(game) => handleProfile(game)} handleGameAccepted={(game) => handleAccepted(game)} handleGameDeclined={(game) => handleDeclined(game)} key={game.gameId} gameData={game}/>);
        }
    }
    
    return (
    
        <div className="challenge__table__container">
            {final}
        </div>
    )
    
}



export default ChallengesList;