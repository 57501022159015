

function UserModel(data) {
    
    let user = {
                
            deviceOs:"web",
            email : "",
            friends : [],
            handle : "",
            image : "",
            lastLogin : new Date(),
            losses : 0,
            name: "",
            saveLocationHistory : true,
            sellDataOptOut : false,
            showFriendsBets : true,
            showWorldBets : true,
            token:"",
            totalGamesPlayed:0,
            uid:"",
            wins:0,
    }
    
    if(data !== undefined && data !== null){
        
        
        if(data.lastLogin != null && data.lastLogin !== undefined){
            let last = data.lastLogin
            let seconds = last.seconds

            let last_date = new Date(seconds)
            user.lastLogin = last_date
        
        }


        user.deviceOs = data.deviceOs
        user.email = data.email
        user.friends = data.friends
        user.handle = data.handle
        user.image = data.image
        user.losses = data.losses
        user.name = data.name
        user.saveLocationHistory = data.saveLocationHistory
        user.sellDataOptOut = data.sellDataOptOut
        user.showFriendsBets = data.showFriendsBets
        user.showWorldBets = data.showWorldBets
        user.token = data.token
        user.totalGamesPlayed = data.totalGamesPlayed
        user.uid = data.uid
        user.wins = data.wins
    }
    
    
    return user;

}



export default UserModel;