import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { auth, signInWith, sendResetEmail, updateUserEmail } from "./firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import UserSingleton from "./UserSingleton"
import ConfirmationPopup from "./Components/ConfirmationPopup"

import logo from "./Assets/white_cowboy.svg";

import "./CSS/Login.css";
import "./CSS/ManageLogin.css";

function ManageLogin() {
    
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
    
  const [changingEmail, setChangingEmail] = useState(true)
  const [changingPassword, setChangingPassword] = useState(false)
  
  const [userRecentlyLoggedIn, setUserRecentlyLoggedIn] = useState(false)
  
  
  const [showConfirmation, setShowConfirmation] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [confirmationTitle, setConfirmationTitle] = useState("Success")
    const [confirmationBody, setConfirmationBody] = useState("Challenge Sent")
    
  const [user, loading, error] = useAuthState(auth);
  const instance = UserSingleton.getInstance()
  const history = useHistory();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
      
      setEmail(user.email)
    

      
  }, [user, loading]);
    
    
    const toggleViews = () =>{

        if(changingEmail === true){
            setChangingEmail(false)
            setChangingPassword(true)
        }else{

            setChangingEmail(true)
            setChangingPassword(false)
        }
    }
    
    const passwordResetView = () =>{
        
        return <><input
          type="text"
          className="reset__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <button
          className="reset__btn"
          onClick={() => resetPasswordAction()}
        >
          Send password reset email
        </button></>
        
    }

    
    const emailChangeLoginView= () =>{

        return <>      
            <div className="title" > Login to change email </div>
            
            <input
              type="text"
              className="manage__login__textBox"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail Address"
            />
            <input
              type="password"
              className="manage__login__textBox"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
                  
            <button
              className="reset__btn"
              onClick={() => loginUser()}
            >
              Login
            </button>
        </>
    
    }
    
    
    
    const emailChangeView = () =>{
        
        return <>
            <input
              type="text"
              className="reset__textBox"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="New E-mail Address"
            />
            <button
              className="reset__btn"
              onClick={() => changeEmailAction()}
            >
          Change Email
        </button></>
        
    }
          
          
          
    const loginUser = async() =>{

        let response = await signInWith(email, password)
        if(response.success === true){
            setEmail("")
            setUserRecentlyLoggedIn(true)
        }else{
            
            setConfirmationTitle("Oops!")
            setConfirmationBody(response.message)
            setShowConfirmation(true)
        }
    }
          
          
          
    const changeEmailAction = async() => {
        
        let response = await updateUserEmail(email, user.uid)
        
        if(response.success === true){

            setConfirmationTitle("Success!")
            setConfirmationBody("Email has been updated")
            setShowSuccess(true)

            
        }else{
            
            setConfirmationTitle("Oops!")
            setConfirmationBody(response.message)
            setShowConfirmation(true)
        }
        

    }
    
    const resetPasswordAction = async() =>{

        let response = await sendResetEmail(email)
        if(response.success === true){

            setConfirmationTitle("Success!")
            setConfirmationBody("A password reset email has been sent")
            setShowSuccess(true)
        }else{
            
            setConfirmationTitle("Oops!")
            setConfirmationBody("Something went wrong")
            setShowConfirmation(true)
        }

    }
    
    const successClose = () => {
        history.replace("/account")

    }

  return (
    <div className="manage__login">
      

        <div className="manage__login__container">

              
        {(changingEmail && !userRecentlyLoggedIn) && emailChangeLoginView()}
        {(changingEmail && userRecentlyLoggedIn) && emailChangeView()}
        {changingPassword && passwordResetView()}
     
        <div className="small__theme__background__yellow__button" onClick={() => toggleViews()}>{changingEmail ? "Reset Password" : "Change Email"}</div>

        {showConfirmation && 
                <ConfirmationPopup 
                handleClose={() => setShowConfirmation(false)}
                positive="Close"
                title={confirmationTitle}
                body={confirmationBody}/>
            }
                
        {showSuccess && 
                <ConfirmationPopup 
                handleClose={() => successClose()}
                positive="Close"
                title={confirmationTitle}
                body={confirmationBody}/>
            }

      </div>
    </div>
  );
}

export default ManageLogin;
