import React from 'react';
import '../CSS/RecentGameCell.css';
import Game from '../Models/Game';
import AsyncImage from './AsyncImage';
import default_avatar_image from "../Assets/avatar.svg";


const RecentGameCell = ({profileSelected, data, cellSelected}) => {

    let game = new Game(data)

    let betAmount = game.betAmount
    let name = game.name

    let winner = game.winner
    let opponentUid = game.opponentUid
    let opponentImage = game.opponentImage
    let opponentName = game.opponentHandle

    let challengerImage = game.challengerImage
    let challengerUid = game.challengerUid
    let challengerName = game.challengerHandle


    var winningName = ""
    var losingName = ""

    if(winner === challengerUid){
        winningName = challengerName
        losingName = opponentName
    }else if(winner === opponentUid){
        winningName = opponentName
        losingName = challengerName
    }

    let trashTalk = game.trashTalk

    const selectedCell = () =>{

        console.log("SELECTED CELL: ", game)
        cellSelected(game)
    }

    function handleChallengerProfileSelected(event) {
        event.stopPropagation(); // USED HERE!

        profileSelected(challengerUid)
    }
    function handleOpponentProfileSelected(event) {
        event.stopPropagation(); // USED HERE!
        profileSelected(opponentUid)
    }


    let recap = winningName + " won $" + betAmount + " from " + losingName + " in " + name

    return(
        <div className="recent__cell__container" onClick={selectedCell}>


                <div className="vs__container" >

                    <div className="user__info__container">
                        <div className="recent__image__profile">
                            <AsyncImage draggable="false" src={challengerImage !== "" ? challengerImage : default_avatar_image} className="recent__profile__image__constraint" onClick={handleChallengerProfileSelected}/>
                        </div>
                        <div className="challenger__name">{challengerName}</div>
                    </div>

                    <div className="vs__text">VS</div>

                    <div className="user__info__container" >
                        <div className="opponent__name">{opponentName}</div>
                        <div className="recent__image__profile">
                            <AsyncImage draggable="false" src={opponentImage !== "" ? opponentImage : default_avatar_image} className="recent__profile__image__constraint" onClick={handleOpponentProfileSelected}/>
                        </div>
                    </div>

                </div>

                <div className="recap__text">{recap}</div>
                <div className="trash__talk">{trashTalk}</div>
        </div>

    )

}


export default RecentGameCell;
