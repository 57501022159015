import React from 'react';
import '../CSS/OngoingGameCell.css';
import Game from '../Models/Game';
import default_avatar_image from "../Assets/avatar.svg";
import hourglass from '../Assets/hourglass.svg'
import stopwatch from '../Assets/stopwatch.svg'
import dollar from '../Assets/dollar.svg'
import AsyncImage from "./AsyncImage"



const OngoingGameCell = ({profileSelected, resumeSelected, data, myUid}) => {
    
    let game = new Game(data)
    
    let betAmount = game.betAmount
    let name = game.name
    let type = game.type
    
    let winner = game.winner
    let opponentUid = game.opponentUid
    let opponentImage = game.opponentImage
    let opponentHandle = game.opponentHandle
    
    let challengerImage = game.challengerImage
    let challengerUid = game.challengerUid
    let challengerHandle = game.challengerHandle
    

    let expires = game.expires
    
    const calculateRemainingTime = () =>{
        
        let expireSeconds = expires.seconds
        let now = new Date()
        let nowInSeconds = now.getTime()/1000

        let remainingSeconds = expireSeconds - nowInSeconds
        
        let minutes = Math.trunc(remainingSeconds / 60)
        let hours = Math.trunc(remainingSeconds / 3600)        
        let minHourRemainder = Math.trunc(minutes % 60)
        
        var time = ""
        
        if(hours === 1){
            
            time = hours + " hr " + minHourRemainder + " m"
            
        }else if(hours > 0){
            
            time = hours + " hours"
        }else{
            time = minutes + " mins"
        }
        
        return time
    }
    
    
    var displayImage, displayName, displayUid = ""
    
    if(challengerUid !== myUid){
        displayUid = challengerUid
        displayImage = challengerImage
        displayName = challengerHandle
 
    }else{
        displayUid = opponentUid
        
        displayImage = opponentImage
        displayName = opponentHandle
    }
    
    
    const nameForGameType = () => {

    
        var name;
            switch (type) {
                case 0:
                    name =  "None"
                    break
                case 1:
                    name =  "Snowman"
                    break
                case 2:
                    name =  "X's O's"
                    break
                case 3:
                    name =  "Match 4"
                    break
                case 4:
                    name =  "Word Up"
                    break
                case 5:
                    name =  "Checkers"
                    break
                case 6:
                    name =  "Chess"
                    break
                case 7:
                    name =  "Trivia"
                    break
                default:
                    name =  ""
                    break
            }
            return name

    }
    
    
    let itsMe = game.challengerUid === myUid

    return(
        <div className="ongoing__game__cell__container">
        
            <div className="top__ongoing__info__view">
                <div className="ongoing__profile__image" onClick={() => profileSelected(displayUid)}>
                    <AsyncImage draggable="false" src={displayImage !== "" ? displayImage : default_avatar_image} className="ongoing__profile__image__constraint"/>
                </div>
        
                <div className="ongoing__game__handle">
                    <div className="title__yellow">{nameForGameType()}</div>
                    <div className="medium__text__gray">@{displayName}</div>
        
                </div>

            </div>

        
            <div className="bottom__ongoing__info__view">
        
                <div className="ongoing__detail__info">
                
                    <div className="ongoing__details__image__container">
                        <AsyncImage draggable="false" src={dollar} className="ongoing__details__image"/>
                    </div>
        
                    <div className="large__text">{betAmount}</div>
                </div>
                
        
                <div className="ongoing__detail__info">
                    <div className="ongoing__details__image__container">
                        <AsyncImage draggable="false" src={hourglass} className="ongoing__details__image"/>
                    </div>
        
                    <div className="large__text">{calculateRemainingTime()}</div>
                </div>

                <button className="white__background__theme__button__no__margin" onClick={() => resumeSelected(game)}>Resume</button>

        
            </div>
        
            
        
        
        </div>
    
    )
    
    
    
}


export default OngoingGameCell;