import styled from "styled-components";
import Modal from 'react-overlays/Modal';
import "./ChooseWordPopup.css"
import React, { Component, useState } from "react";
import Dictionary from '../Wordup/dict.json';

const ChooseWordBackdrop = styled("div")`
            position: fixed;
            z-index: 1040;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000;
            opacity: 0.5;
            `;


const ChooseWordModal = styled(Modal)`
            position: fixed;
            width: 30%;
            z-index: 1040;
            top: ${() => 25}%;
            left: ${() => 35}%;
            border-radius: 15px;
            background-color: var(--main);
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
            padding-top:30px;
            padding-bottom:30px;
            align-content: center;
            align-items: center;
            text-align: center;
            padding-left:2.5vw;
            padding-right:2.5vw;

            @media only screen and (max-width: 768px) {
                position: fixed;
                width: 90%;
                z-index: 1040;
                top: ${() => 25}%;
                left: 2.5vw;
                padding-top:20px;
                padding-bottom:20px;
                border-radius: 15px;
                align-content: center;
                align-items: center;
                text-align: center;
                background-color: var(--main);
                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        }
    `;




const renderBackdrop = (props) => <ChooseWordBackdrop {...props} />;

export default class ChooseWordPopup extends Component {

    constructor(props){
        super(props)

        this.onHide = props.onHide
        this.handleUpdate = props.handleUpdate

        this.state = {
            word: props.word,
            isValid:false,
        }

    }


    updateWord = e =>{

        e.preventDefault();
        this.setState({ word: e.target.value});

    }




    render(){

        const checkAgainstDictionary = (word) =>{
              let lowerCaseWord = word.toLowerCase()
              if (Dictionary.indexOf(lowerCaseWord) != -1) {
                this.setState({isValid:true})
                return true
              }
              this.setState({isValid:false})
              return false
        }

        var setNewWord = (a) =>{
            this.setState({word: a})
        }

        const handleHidden = () =>{

            this.onHide()
        }

        const handleUpdateAction = (word) =>{

            let valid = checkAgainstDictionary(this.state.word)

            console.log("Word: ", this.state.word, " valid? ", valid)
            if(valid){

              this.handleUpdate(this.state.word)
            }
        }



        return (

            <div>
              <ChooseWordModal
                  show={true}
                  onHide={handleHidden}
                  renderBackdrop={renderBackdrop}
                  aria-labelledby="modal-label">

                <div className="center__choose__word__popup__content">

                    <div className="card__title">Enter Word</div>

                    <input
                        row_count="1"
                        length="10"
                        wrapping='off'
                        resize='none'
                        className='input__textbox'
                        value={this.state.word}
                        placeholder="Aa"
                        onChange={this.updateWord}
                    />

                    <div className="spacer__vertical"/>
                    {this.state.isValid && this.state.word && <div className="large__yellow">Invalid Word</div>}
                    <button className="white__background__theme__button" onClick={() => handleUpdateAction()}>Check</button>

                </div>
                </ChooseWordModal>
             </div>
        );
  }
}
