import React from 'react';
import '../CSS/RequestsListCell.css';
import '../CSS/Theme.css';
import UserModel from '../Models/UserModel';
import FriendRequest from '../Models/FriendRequest';
import default_avatar_image from "../Assets/avatar.svg";
import AsyncImage from "./AsyncImage"


const RequestsListCell = ({myUid, profileSelected, declined, accepted, data}) => {
    
    let request = new FriendRequest(data)

    let user = new UserModel(request.user)
    
    let handle = user.handle
    let uid = user.uid
        
    let name = user.name
    let image = user.image
    
    
    
    var iSent = false;
    if(request.requestor === myUid){
       iSent = true;
    }
    

    return(
        <div className="request__cell__container">
        
        
            <div className="left__info">
                <div className="request__profile__image" onClick={() => profileSelected(user)}>
                    <AsyncImage draggable="false" className="request__profile__image__constraint" src={image !== "" ? image : default_avatar_image}/>
                </div>

                <div className="name__handle__container">
                    <div className="large__text">{name}</div>
                    <div className="medium__text__gray">@{handle}</div>
                </div>
            </div>


            <div className="vertical__flex__center">
                <button className="theme__background__gray__button" onClick={() => declined(request)}>{iSent ? "Remove" : "Decline"}</button>
                <button className={iSent ? "hidden" : "white__background__theme__button"} onClick={() => accepted(request)}>Accept</button>
            </div>
        
        </div>
    )

}


export default RequestsListCell;