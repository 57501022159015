import React, {Component, useState, useEffect} from 'react';
//import ScoreBoard from './ScoreBoard';
import Dictionary from './dict.json';
import Letter from './Letter';
import "./WordUp.css"

// Base component for Boggle Board
function WordUpBoard({board, existingWords, expires, submitCallback}){
  // loads shuffled letters from dice library

    const [letters, setLetters] = useState(board)

    const [currentWord, setCurrentWord] = useState("")


    const [isExpired, setIsExpired] = useState(expires)
    const [activeKeys, setActiveKeys] = useState([])
    const [enabledKeys, setEnabledKeys] = useState([])

    const [scoredWords, setScoredWords] = useState(existingWords)



    useEffect(() =>{

        let fullBoard = lettersToBoardArray(board)
        setLetters(fullBoard)
        setScoredWords(existingWords)
        setIsExpired(expires)

    }, [board, currentWord, expires, existingWords]);


    const moveCallback = (data) =>{
        submitCallback(data)
    }

    function lettersToBoardArray(board){
        var letters = []

        for(const letter of board){
                letters.push({letter: letter.toUpperCase(), key: letters.length, enabled: true});

        }
        return letters
    }


function onLetterClick (letter) {



    var key = letter.key;
    var newCurrentWord = "";
    var newActive = []
    // checks to see if the letter was highlighted blue (was active) or not
    // when it was clicked


    if (activeKeys.includes(letter.key)) {
      // removes the letter/key from currentWord and currentKeys.

      activeKeys.pop()

      var word = ""
      for(const l of newActive){
            newCurrentWord = newCurrentWord + l.letter
            word = word + l.letter
      }

    } else {

      newCurrentWord = currentWord + letter.letter;
      activeKeys.push(letter.key)

    }



    var enableKeys = []

    // all letters are set to enabled if there are no active/highlighted letters
    if (activeKeys.length === 0) {
      setEnabledKeys(enableKeys)

    } else {
      // checks to see where the letter is located using the key value and sets
      // keepEnabled to surrounding letters

      switch (key % 4) {
        case 0:
          // letter is on the left
          enableKeys = [key, key-4, key-3, key+1, key+4, key+5];
          break;
        case 3:
        // letter is on the right
          enableKeys = [key, key-1, key-4, key-5, key+3, key+4];
          break;
        default:
          // letter is in the middle
         enableKeys = [key, key-1, key-5, key-4, key-3, key+1, key+3,
                         key+4, key+5];
      }
      setEnabledKeys(enableKeys)

    }

    console.log("Word: ", newCurrentWord)

    setCurrentWord(newCurrentWord)

  }




  function resetLetters() {
    // resets all the letters to enabled and clears currentWord and currentKeys
    setCurrentWord("")
    setActiveKeys([])
    setEnabledKeys([])

  }





  function getWordValue(word){
        var score = 0;
        // sets the score based on how long the submitted word is
          switch (word.length) {
            case 1:
              score = 0;
              break;
            case 2:
              score = 0;
              break;
            case 3:
              score = 1;
              break;
            case 4:
              score = 2;
              break;
            case 5:
              score = 3;
              break;
            case 6:
              score = 4;
              break;
            case 7:
              score = 5;
              break;
            case 8:
              score = 6;
              break;
            default:
              score = 11;
          }

      return score
  }


  function scoreWord() {

        console.log("SCORE WORD: ", currentWord)
        if(currentWord === undefined){
            return 0;
        }

        var lowerCaseWord = currentWord.toLowerCase();
        var currentScoredWords = scoredWords
        // checks to see if the submited word is in dict.json and has not already been submited

        console.log(currentScoredWords)

        const includes = currentScoredWords.includes(lowerCaseWord)

        if (Dictionary.indexOf(lowerCaseWord) != -1 && !includes) {

          let score = getWordValue(lowerCaseWord);
          moveCallback({word:lowerCaseWord, score:score})
        }
        resetLetters()
    }



    var letterNodes = letters.map((lettr) => {
              // creates nodes of Letter components from each letter in the letters array
        return (
            <Letter activeLetters={activeKeys} enabledLetters={enabledKeys} onClick={(data) => onLetterClick(data)} item={lettr} key={lettr.key}></Letter>
        );
    });


   const wordList = () =>{

    var str = ""
    var pos = 0
    for(const word of scoredWords){

        if(pos === 0){

          str = word

        }else {
          str = str + ', ' + word

        }
        pos += 1
    }
    return str
   }

  return (
      <div>
          <div className="wordup__board">
            <div className="innerBoard" >
              {letterNodes}
            </div>
          </div>

          <div>
            <div className="spacer__vertical"/>

              <div className="horizontal__center__space__between">

                <div className="large__yellow">{currentWord && currentWord.toUpperCase()}</div>

                <div className={!isExpired ? "white__background__theme__button" : "hidden"} onClick={() => scoreWord()}>Submit Word</div>
              </div>
              <div className="spacer__vertical"/>

              <div className="words__list">

                <div className={!isExpired ? "word__list__text": "large__gray"}> {wordList()} </div>

              </div>

            </div>

      </div>
    )

}

export default WordUpBoard;
