import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { auth, signInWith } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import UserSingleton from "./UserSingleton"

import logo from "./Assets//white_cowboy.svg";

import "./CSS/Login.css";
import "./CSS/Theme.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const instance = UserSingleton.getInstance()
  const history = useHistory();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
      
    if (user) {
        history.replace("/home"); 
    }
      
  }, [user, loading]);

  return (
    <div className="login">
      

        <img className="splash__logo" draggable="false"  src={logo} alt="Pony Up Logo"/>    
      
      <div className={user ? "hidden" : "login__container"}>
      
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
              
        <button
          className="login__btn"
          onClick={() => signInWith(email, password)}
        >
          Login
        </button>

        <div>
          <Link to="/reset">Forgot Password</Link>
        </div>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </div>
    </div>
  );
}

export default Login;
