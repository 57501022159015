import React from 'react';
import '../CSS/FriendsListCell.css';
import UserModel from '../Models/UserModel';
import default_avatar_image from "../Assets/avatar.svg";
import AsyncImage from "./AsyncImage"

const FriendsListCell = ({profileSelected, key, handleHidden, data}) => {
    
    

    
    let user = new UserModel(data)
    
    let name = user.name
    let image = user.image
    let handle = user.handle
    let uid = user.uid
    

    return(
        <div className="friends__cell__container" onClick={() => profileSelected(data)}>
        
            <div className="left__info">
                <div className="friends__image__profile">
                    <AsyncImage draggable="false" src={image !== "" ? image : default_avatar_image}  className="friends__image__profile__constraint" />
                </div>
                <div className="medium__text">{name}</div>
            </div>

            <div className="medium__text">@{handle}</div>

        
        </div>
    
    )
    
    
    
}


export default FriendsListCell;