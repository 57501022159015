import '../CSS/SearchBar.css'
import React, { Component, useState } from "react";



export default class SearchBar extends Component {



    constructor(props){
        super(props)
        
        this.state = {
            inputVal:'',
            placeholder:props.placeholder
        }
        
        this.handleChange = props.handleChange
        
    }
    
    
    updateText = e =>{
        e.preventDefault();
        this.setState({inputVal:e.target.value});
        this.handleChange(e.target.value)
    }

    render(){
        
        const onClear = () => {
            // @ts-ignore (us this comment if typescript raises an error)
            this.setState({inputVal:''});
            this.handleChange('')
            
        };
        
        
        
        return (
        
            <div className="search__bar__container">

                <input 
                    rows={1}
                    placeholder={this.state.placeholder}
                    className="search__bar"
                    maxLength={70}
                    overflow-x='scroll'
                    resize='none'
                    value={this.state.inputVal}
                    onChange={this.updateText}
                />

                <button className="dark__theme__background__gray__button" onClick={() => onClear()}>clear</button>
                
            </div>
        )
    
    
    }
    

}
