import UserModel from './UserModel'

function FriendRequest(data){
    
    
var request = {
    requestor:"",
    requestee:"",
    accepted:false,
    handle:"",
    name:"",
    image:"",
    id:"",
    user: new UserModel(undefined)
    
}
    
  if(data !== undefined){

    request.handle = data.handle
    request.image = data.image
    request.name = data.name
    request.requestor = data.requestor
    request.requestee = data.requestee
    request.accepted = data.accepted
    request.id = data.id
    request.user = data.user
      
  }
    return request;

};


export default FriendRequest;