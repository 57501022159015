import React from 'react';
import '../CSS/ProfileRecentGames.css';
import ProfileRecentGameCell from './ProfileRecentGameCell';

const ProfileRecentGamesList = ({uid, data}) => {
    
    const final = [];
    
    for(let game of data){

        final.push(<ProfileRecentGameCell key={game.gameId} data={game} uid={uid}/>);
    }
    
    return (
    
        <div className="profile__recent__table__container">
            {final}
        </div>
    )
}



export default ProfileRecentGamesList;