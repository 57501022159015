import styled from "styled-components";
import Modal from 'react-overlays/Modal';
import "../CSS/SetTurnTimerPopup.css"
import React, { Component, useState } from "react";
const TurnTimerBackdrop = styled("div")`
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
`;
        
        
const TurnTimerModal = styled(Modal)`
    position: fixed;
    width: 30%;
    z-index: 1040;
    top: ${() => 25}%;
    left: ${() => 37}%;
    border-radius: 15px;
    background-color: var(--main);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    padding-top:30px;
    padding-bottom:30px;
    align-content: center;
    align-items: center;
    text-align: center;
    padding-left:2.5vw;
    padding-right:2.5vw;

    @media only screen and (max-width: 768px) {
        position: fixed;
        width: 90%;
        z-index: 1040;
        top: ${() => 25}%;
        left: 2.5vw;
        padding-top:20px;
        padding-bottom:20px;
        border-radius: 15px;
        align-content: center;
        align-items: center;
        text-align: center;
        background-color: var(--main);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }`;

    
const renderBackdrop = (props) => <TurnTimerBackdrop {...props} />;





export default class SetTurnTimerPopup extends Component {

    constructor(props){
        
        super(props)
        
        this.handleUpdate = props.handleUpdate
        this.onHide = props.onHide
        
        this.state = {
            turnTimer: props.turnTimer,
        }
    };
    
    
    updateTimer = e =>{
        e.preventDefault();
        this.setState({ turnTimer: e.target.value });
    
    };
    
    render(){
        
        const setNewTimer = (a) =>{
            this.setState({turnTimer:a})
        }
    
        
        const handleHidden = () =>{

            this.onHide()
        }
        
        const handleUpdateAction = () =>{

            this.handleUpdate(this.state.turnTimer)
        }
        

        return (

            <div>

                <TurnTimerModal
                    show={true}
                    onHide={handleHidden}
                    renderBackdrop={renderBackdrop}
                    aria-labelledby="modal-label"
                >

                <div className="center__turn__timer__popup__content">

                    <div className="card__title">Set turn timer (in minutes)</div>                                      

                        <input
                            row_count="1"
                            length="7"
                            wrapping='off'
                            className='input__textbox'
                            value={this.state.turnTimer}
                            onChange={this.updateTimer}
                            />

                        <div className="spacer__vertical"/>
                        <button className="white__background__theme__button"  onClick={handleUpdateAction}>Update</button>                               

                    </div>
                </TurnTimerModal>
             </div>
        );

    
    }

}
