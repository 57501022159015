import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useHistory } from "react-router-dom";
import {db} from "./firebase"
import logo from "./Assets/white_cowboy.svg";



import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "./firebase";
import "./CSS/Register.css";
import "./CSS/Theme.css";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();

  const register = () => {
    if (!name) {
        alert("Please enter name");
        return
    }

    
      
    if (!email) {
        alert("Please enter a valid email");
        return
    }
      
      
    if (!password) {
        alert("Please enter a valid password");
        return
    }
    registerWithEmailAndPassword(name, email, password);
  };
    
    

  
  

  useEffect(() => {
    if (loading) return;
    if (user) history.replace("/welcome");
  }, [user, loading]);

  return (
    <div className="register">
      
      
      <img className="splash__logo" draggable="false"  src={logo} alt="Pony Up Logo"/>    
      
      
      <div className="register__container">
        <input
          type="text"
          className="register__textBox"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Full Name"
        />
    

        <input
          type="text"
          className="register__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="register__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button className="register__btn" onClick={register}>
          Register
        </button>



        <div>
          Already have an account? <Link to="/">Login</Link> now.
        </div>
      </div>
    </div>
  );
}

export default Register;
