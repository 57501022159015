import "./CSS/App.css";
import "./CSS/Theme.css";
import React, { useEffect, useState } from "react";

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";
import Play from "./Play";
import Home from "./Home";
import Profile from "./Profile";
import ManageBalance from "./ManageBalance";
import ManageLogin from "./ManageLogin";
import Splash from "./Splash";
import Settings from "./Settings";
import Trivia from "./Games/Trivia/Trivia";
import WordUp from "./Games/Wordup/WordUp";
import ChessGame from "./Games/Chess/ChessGame";
import XsOs from "./Games/XsOs/XsOs";
import Match4 from "./Games/Match4/Match4";
import SnowmanGame from "./Games/Snowman/SnowmanGame";
import CheckersGame from "./Games/Checkers/CheckersGame";
import Privacy from "./Privacy";
import Terms from "./Terms";
import Faq from "./Faq";
import Friends from "./Friends";
import History from "./History";
import Navbar from './Components/Navbar'


import Notification from "./Notification"

import { auth, db, logout, onMessageListener, getFCMToken, isSafari } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import  UserSingleton  from "./UserSingleton"


function App() {


    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserData] = useState(undefined);
    const [userAccount, setUserAccount] = useState(undefined);
    const [notification, setNotification] = useState({title: '', body: ''});


    const instance = UserSingleton.getInstance()



    onMessageListener().then(payload => {
        setNotification({title: payload.notification.title, body: payload.notification.body})
        console.log(payload);
    }).catch(err =>
        console.log('failed: ', err)
    );



    useEffect(() => {

        if (loading) return;
        if (!user) return  <Redirect exact path="/welcome" component={Splash}/>;



        instance.start(user.uid)

    }, [user, loading]);


    const close = () =>{
        setNotification({title:"", body:""})

    }


    return (

        <div className="app">
            {(notification.title !== "") && <Notification closed={() => close()} title={notification.title} body={notification.body}> </Notification>}

            <Router>

                {user && <Navbar/>}

                <Switch>

                    <Route path="/home"><Home/></Route>
                    <Route path="/play"><Play/></Route>
                    <Route path="/register"><Register/></Route>
                    <Route path="/reset"><Reset/></Route>
                    <Route path="/friends"><Friends/></Route>
                    <Route path="/profile"><Profile/></Route>
                    <Route path="/trivia"><Trivia/></Route>
                    <Route path="/chess"><ChessGame/></Route>
                    <Route path="/checkers"><CheckersGame/></Route>
                    <Route path="/wordup"><WordUp/></Route>
                    <Route path="/xsos"><XsOs/></Route>
                    <Route path="/match4"><Match4/></Route>
                    <Route path="/snowman"><SnowmanGame/></Route>
                    <Route path="/history"><History/></Route>
                    <Route path="/account"><Settings/></Route>
                    <Route path="/balance"><ManageBalance/></Route>
                    <Route path="/managelogin"><ManageLogin/></Route>
                    <Route path="/faq"><Faq/></Route>
                    <Route path="/privacy"><Privacy/></Route>
                    <Route path="/terms"><Terms/></Route>
                    <Route path="/login"><Login/></Route>
                    <Route path="/welcome"><Splash/></Route>
                    <Route path="/"><Splash/></Route>

                </Switch>
            </Router>

        </div>
    );
}

export default App;
