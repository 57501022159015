

function Handle(data){
    
    
var handle = {
    handle:"",
    image:"",
    name:"",
    searchables:[],
    uid:"",
    
}
    
  if(data !== undefined){

    handle.handle = data.handle
    handle.image = data.image
    handle.name = data.name
    handle.searchables = data.searchables
    handle.uid = data.uid
      
  }
    return handle;

};


export default Handle;