
function Bet(data){
    
    
    var bet = {
        //doc id
        id:"",
        noWager:false,
        amount:0,
        challengerUid:"",
        opponentUid:"",
        accepted:false,
        randomOpponent:false,
        gameType:0,
        expires:new Date(),
        challengerHandle:"",
        challengerImage:"",
        opponentHandle:"",
        opponentImage:"",
        startedGameId:"",
        customMessage:"",
        gameStarted:"",
        shotClock:3600,
        players:[]
    
        


    }
    
      if(data !== undefined){

        bet.id = data.id
        bet.noWager = data.noWager
        bet.amount = data.amount
        bet.gameStarted = data.gameStarted
        bet.challengerUid = data.challengerUid
        bet.opponentUid = data.opponentUid
        bet.accepted = data.accepted
        bet.randomOpponent = data.randomOpponent
        bet.gameType = data.gameType
        bet.expires = data.expires
        bet.challengerHandle = data.challengerHandle
        bet.challengerImage = data.challengerImage
        bet.opponentHandle = data.opponentHandle
        bet.opponentImage = data.opponentImage
        bet.startedGameId = data.startedGameId
        bet.customMessage = data.customMessage
        bet.shotClock = data.shotClock
        bet.players = data.players

      }

    return bet;

};


export default Bet;