import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";

import "./CSS/Settings.css";
import "./CSS/Theme.css";

import { auth, db, logout } from "./firebase";
import { DropdownList } from "./Components/DropdownList"
import {Dropdown, Option} from "./Components/Dropdown"
import TextInput from "./Components/TextInput"
import SupportPopup from "./Components/SupportPopupView"
import ConfirmationPopup from "./Components/ConfirmationPopup"
import UserSingleton from "./UserSingleton"
import Switch from "./Components/Switch";


function Settings() {
    
  const [user, loading, error] = useAuthState(auth);

  const [infoChanged, setInfoChanged] = useState(false);

  const [account, setAccount] = useState(undefined);
  const [userData, setUserData] = useState(undefined);
    
  const [showSupport, setShowSupport] = useState(false)
  
  
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
  const [confirmationTitle, setConfirmationTitle] = useState("Success!")
  const [confirmationBody, setConfirmationBody] = useState("Request Sent")
  const [showSupportPopup, setShowSupportPopup] = useState(false)
  
  const [pdf, setPdf] = useState(undefined)
  
  const [friends, setFriends] = useState(false)
  const [world, setWorld] = useState(false)
  const [location, setLocation] = useState(false)
  const [email, setEmail] = useState("")
  const [balance, setBalance] = useState("0")
  
  
  const [showFAQ, setShowFAQ] = useState(false)
  const history = useHistory();
  const instance = UserSingleton.getInstance()
                           
  
  useEffect(() => {
        if(loading)return;

        if (!user) return history.replace("/welcome");
        
        async function fetchUserData(){
            let uData = await instance.getUser(user.uid)
            console.log("SETTINGS User: " , uData)
            setUserData(uData);
            setViewableUserData(uData)
        }
        
        async function fetchAccountData(){
            let aData = await instance.getAccount(user.uid)
            console.log("SETTINGS Account: " , aData)
            setAccount(aData);
            setViewableAccountData(aData)
        }

        
        if(user !== undefined){
            fetchUserData()
            fetchAccountData()
        }

    }, [user, loading]);

    
  const setViewableUserData = (user) =>{
      
      if(user !== undefined){
          setEmail(user.email)
          setLocation(user.saveLocationHistory)
          setFriends(user.showFriendsBets)
          setWorld(user.showWorldBets)
         
       }
  }                
  
  
  const setViewableAccountData = (account) =>{
      
       if(account !== undefined){

          setBalance(account.balance)
       }
  }
  
  
  
  
  const logoutUser = async() =>{
      
    await logout()

  }

                                                      
                                       
    const toggleLocationSwitch = async(isOn) =>{

        setLocation(isOn)                               
                                       
        if(userData !== undefined){

            await db.collection("Users").doc(userData.uid).update({saveLocationHistory:isOn})                                                     
        }
                                            
   }
    
   const toggleFriendSwitch = async(isOn) =>{

        setFriends(isOn)                               
                                       
        if(userData !== undefined){
            await db.collection("Users").doc(userData.uid).update({showFriendsBets:isOn})                                                   
        }               
   } 
   
   
   const toggleWorldSwitch = async(isOn) =>{

        setWorld(isOn)                               
                                       
        if(userData !== undefined){

            await db.collection("Users").doc(userData.uid).update({showWorldBets:isOn})                                                  
        }                 
   }
           
   
    const downloadHistory = () =>{

                                       
    }
                                       
    const downloadAllData = () =>{

                                       
    }
    
    
   const faqAction = () => {
        history.replace("/faq")                                                                                       
    }
   
    const privacyAction = () => {
       history.replace("/privacy")                                        
                                                      
    } 
    
    const termsAction = () => {
        history.replace("/terms")                                                                                                                      
    } 
                                                      
    const contactSupport = async(req) => {
        
        const request = await db.collection("SupportRequests").add(req)  
        
        setShowConfirmationPopup(true)
        setShowSupportPopup(false)                                  
        
    }
    

                                                      
    return (
                                                      
        <div className="settings">

          <div className="settings__container">

             <div className="top__container">
                <div className="title__yellow">{userData !==  undefined ? userData.name : "Account"}</div>
                <button className="white__background__theme__button" onClick={logoutUser}>Logout</button>

            </div>                               


            <div className="balance__container">
                <div className="bold__white__large__text">Balance </div>

                <div className="edit__balance">
                    <div className="medium__text__gray">${account !== undefined ? account.balance : ""}</div>    
                    <button className="white__background__theme__button"onClick={() => history.replace("/balance")}>Add/Withdrawal</button>

                </div>
            </div>




            <div className="who__sees__container">

                <div className="bold__white__large__text">Who Sees my bet amounts</div>

                <div className="edit__who__sees">       

                    <div className="medium__text__gray">Everyone</div> 
                    <Switch 
                        identifier="world__switch"
                        isOn={world}
                        handleToggle = {() => toggleWorldSwitch(!world)}
                    />                                         
                </div>


                <div className="edit__who__sees">       

                    <div className="medium__text__gray">Friends</div>
                    <Switch 
                        identifier="friends__switch"
                        isOn={friends}
                        handleToggle = {() => toggleFriendSwitch(!friends)}
                    />                        

                </div>
            </div>


            <div className="email__container">
                <div className="bold__white__large__text">Email and Login</div>

                <div className="edit__email">
                    <div className="medium__text__gray">{userData !== undefined ? userData.email : ""}</div>
                    <button className="white__background__theme__button" onClick={() => history.replace("/managelogin")}>Manage</button>
                </div>

            </div>


            {false && <div className="location__container">
                <div className="bold__white__large__text">Location</div>   

                <div className="edit__location">
                    <div className="medium__text__gray">Share my location history</div>
                    <Switch 
                        identifier="locationSwitch"
                        isOn={location}
                        handleToggle = {() => toggleLocationSwitch(!location)}
                    /> 
                </div>

            </div>}

            <div className="bottom__buttons__vertical">
                {false && <button className="theme__background__yellow__button" onClick={() => downloadHistory()}>Download Transaction History</button>}
                {false && <button className="theme__background__white__button" onClick={() => downloadAllData()}>Download All Data</button>}
                <button className="white__background__theme__button" onClick={() => setShowSupportPopup(true)}>Contact Support</button>
            </div>


            <div className="spacer__vertical"/>
            <div className="spacer__vertical"/>

            <div className="bottom__buttons__horizontal">
                <button className="theme__background__gray__button" onClick={() => termsAction()}>Terms</button>
                <button className="theme__background__gray__button" onClick={() => faqAction()}>FAQs</button>
                <button className="theme__background__gray__button" onClick={() => privacyAction()}>Privacy</button>
            </div>


          </div>  



            {<SupportPopup 
                show={showSupportPopup}
                handleHidden={() => setShowSupportPopup(false)}
                handleRequest={(request) => contactSupport(request)}
                user={userData}
                />
            }
            {showConfirmationPopup &&
                <ConfirmationPopup 
                    positive={"Close"}
                    title={confirmationTitle}
                    body={confirmationBody}
                    handleClose={() => setShowConfirmationPopup(false)}
                    />
            }


        </div>

    );
};

export default Settings;