import React from "react";
import "../CSS/Theme.css"

export default function Loading({text, size}) {


  var className = 'dynamic__loading__container'
  if(size !== undefined){
    if(size === 's'){
        className = 'small__loading__container'
    }
  }


  var viewableText = "Loading..."
  if(text !== undefined){
    viewableText = text + "..."
  }

  return (
    <div className={className}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
            <path fill="white" transform="translate(2)" d="M0 12 V20 H4 V12z">
            <animate attributeName="d" values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z" dur="1.2s" repeatCount="indefinite" begin="0" keytimes="0;.2;.5;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8" calcMode="spline"  />
            </path>
          <path fill="white" transform="translate(8)" d="M0 12 V20 H4 V12z">
            <animate attributeName="d" values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z" dur="1.2s" repeatCount="indefinite" begin="0.2" keytimes="0;.2;.5;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8" calcMode="spline"  />
          </path>
          <path fill="white" transform="translate(14)" d="M0 12 V20 H4 V12z">
            <animate attributeName="d" values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z" dur="1.2s" repeatCount="indefinite" begin="0.4" keytimes="0;.2;.5;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8" calcMode="spline" />
          </path>
          <path fill="white" transform="translate(20)" d="M0 12 V20 H4 V12z">
            <animate attributeName="d" values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z" dur="1.2s" repeatCount="indefinite" begin="0.6" keytimes="0;.2;.5;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8" calcMode="spline" />
          </path>
          <path fill="white" transform="translate(26)" d="M0 12 V20 H4 V12z">
            <animate attributeName="d" values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z" dur="1.2s" repeatCount="indefinite" begin="0.8" keytimes="0;.2;.5;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8" calcMode="spline" />
          </path>
        </svg>
        <div className="no__margins__no__padding">{viewableText}</div>
    </div>
  );
}
