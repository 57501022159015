import React, { useState, useEffect, useRef } from "react";
import "./Match4.css";


const player1Color = "#0D613B"
const player2Color = "#DFC22E"
const emptyColor = "#F6F4EB"

const boardSettings = {
  rows: 6,
  columns: 7,
  dropAnimationRate: 50,
  flashAnimationRate: 600,
  colors: {
    empty: emptyColor,
    p1: player1Color,
    p2: player2Color
  }
};

const winTypes = {
  vertical: 0,
  horizontal: 1,
  forwardsDiagonal: 2,
  backwardsDiagonal: 3
};



export default function Match4Board({tBoard, tTurn, activePlayer, onMove}) {


  const [board, setBoard] = useState(tBoard);
  const [currentPlayer, setCurrentPlayer] = useState(tTurn === 1 ? player1Color : player2Color);
  const [win, setWin] = useState(null);
  const [flashTimer, setFlashTimer] = useState(null);
  const [dropping, setDropping] = useState(false);
  const domBoard = useRef(null);



    useEffect(() => {

      setBoard(tBoard)
      console.log("Current Player: ", tTurn === 1 ? player1Color : player2Color)
      setCurrentPlayer(tTurn === 1 ? player1Color : player2Color)

    }, [tBoard, tTurn, dropping, win]);




  /**
   * Helper function to get the index in the board using row and column.
   * @param {number} row - row in board
   * @param {number} column - column in board
   */
  function getIndex(row, column) {
    const index = row * boardSettings.columns + column;
    if (index > boardSettings.rows * boardSettings.colums) return null;
    return index;
  }
  function getRowAndColumn(index) {
    if (index > boardSettings.rows * boardSettings.colums) return null;
    const row = Math.floor(index / boardSettings.columns);
    const column = Math.floor(index % boardSettings.columns);
    return {
      row,
      column
    };
  }


  function createBoard() {
    return new Array(boardSettings.rows * boardSettings.columns).fill(
      emptyColor
    );
  }


  function getFirstPlayerTurn() {
    return boardSettings.colors.p1;
  }


  function colorsToNumberBoard(pboard){
    console.log(pboard)

    var newBoard = []
    if(pboard === undefined)return newBoard
    for(const item of pboard){
      if(item === emptyColor){
        newBoard.push(0)
      }else if(item === player1Color){
        newBoard.push(1)

      }else if(item === player2Color){
        newBoard.push(2)
      }
    }
    return newBoard
  }



  function restartGame() {
    setCurrentPlayer(getFirstPlayerTurn());
    setWin(null);
    setBoard(createBoard());
  }


  function getDomBoardCell(index) {
    if (!domBoard.current) return;
    const board = domBoard.current;
    const blocks = board.querySelectorAll(".board-block");
    return blocks[index];
  }


  function findFirstEmptyRow(column) {

    let { empty } = boardSettings.colors;
    let { rows } = boardSettings;

    for(let i = 0; i < rows; i++){
      if (board[getIndex(i, column)] !== empty) {
        return i - 1;
      }
    }
    return rows - 1;
  }




  async function handleDrop(column) {

    if (dropping || win) return;

    if(tTurn !== activePlayer) return;

    const row = findFirstEmptyRow(column);


    if (row < 0) return;
    setDropping(true);
    await animateDrop(row, column, currentPlayer);
    setDropping(false);

    const newBoard = board.slice();

    newBoard[getIndex(row, column)] = currentPlayer;

    let nextPlayer = currentPlayer === boardSettings.colors.p1 ? 2 : 1;

    console.log("Next Player: ", nextPlayer)
    let numBoard = colorsToNumberBoard(newBoard)


    var updates = {newBoard:numBoard, turn:nextPlayer}


    let w = isWin(newBoard)

    console.log("Win --- Handle Drop: ", w)
    if(w !== null){

      var winner = ""
      winner = w.winner === boardSettings.colors.p1 ? 1 : 2

      updates.win = true
      updates.winner = winner
    }

    handleMove(updates)

    setBoard(newBoard);
    setCurrentPlayer(currentPlayer === boardSettings.colors.p1
          ? boardSettings.colors.p2
          : boardSettings.colors.p1);

    if(w !== null){
      setWin(w)
    }

  }





  async function animateDrop(row, column, color, currentRow) {

    if (currentRow === undefined) {
      currentRow = 0;
    }
    return new Promise(resolve => {
      console.log("Row: ", row, " Current Row: ", currentRow)
      if (currentRow > row) {
        return resolve();
      }
      if (currentRow > 0) {
        let c = getDomBoardCell(getIndex(currentRow - 1, column));
          c.style.backgroundColor = boardSettings.colors.empty;
      }

      let index = getIndex(currentRow, column)
      console.log("Index: ", index)

      let c = getDomBoardCell(index);
      c.style.backgroundColor = color;


      setTimeout(
        resolve(animateDrop(row, column, color, ++currentRow)),
        boardSettings.dropAnimationRate
      );
    });
  }



  /*
   * End game animation.
   */
  // useEffect(() => {
  //
  //   if (!win) {
  //     return;
  //   }
  //   function flashWinningCells(on) {
  //     const { empty } = boardSettings.colors;
  //     const { winner } = win;
  //     for (let o of win.winningCells) {
  //       let c = getDomBoardCell(getIndex(o.row, o.column));
  //       c.style.backgroundColor = on ? winner : empty;
  //     }
  //     setFlashTimer(
  //       setTimeout(
  //         () => flashWinningCells(!on),
  //         boardSettings.flashAnimationRate
  //       )
  //     );
  //   }
  //
  //   flashWinningCells(false);
  // }, [win, setFlashTimer]);

  /**
   * Clears the end game animation timeout when game is restarted.
   */
  useEffect(() => {
    if (!win) {
      if (flashTimer) clearTimeout(flashTimer);
    }
  }, [win, flashTimer]);





  /*
  * Check for win when the board changes.
  */
  function isWin(withBoard) {

    let fowardDiag = isForwardsDiagonalWin(withBoard)
    let backwardDiag = isBackwardsDiagonalWin(withBoard)
    let horizontal = isHorizontalWin(withBoard)
    let vertical = isVerticalWin(withBoard)

    console.log("forward Diag: ", fowardDiag)
    console.log("backward Diag: ", backwardDiag)
    console.log("horizontal: ", horizontal)
    console.log("vertical: ", vertical)

    let winState = fowardDiag || backwardDiag || horizontal ||  vertical

    if(winState){
      console.log("Win State: ", winState)
      var cells = getWinningBoxes(winState.pos, winState.type, withBoard)
      return {winner:withBoard[winState.pos], winningCells:cells}
    }

    console.log("NO win")

    return null
  }


  function getWinningBoxes(start, winType, newBoard) {

        var winningCells = []

         let pos = getRowAndColumn(start);

         while (true) {
           let current = newBoard[getIndex(pos.row, pos.column)];
           if (current === start) {
             winningCells.push({ ...pos });
             if (winType === winTypes.horizontal) {
               pos.column++;
             } else if (winType === winTypes.vertical) {
               pos.row++;
             } else if (winType === winTypes.backwardsDiagonal) {
               pos.row++;
               pos.column++;
             } else if (winType === winTypes.forwardsDiagonal) {
               pos.row++;
               pos.column--;
             }
           } else {
             return winningCells;
           }
         }
  }


  function isHorizontalWin(withBoard) {
         const { rows } = boardSettings;
         const { columns } = boardSettings;


         const { empty } = boardSettings.colors;

         for (var row = 0; row < rows; row++) {

           for (var column = 0; column <= columns - 4; column++) {

             let start = getIndex(row, column);

             if (withBoard === undefined || withBoard[start] === empty) continue;
             let counter = 1;
             for (let k = column + 1; k < column + 4; k++) {
               if (withBoard[getIndex(row, k)] === withBoard[start]) {
                 counter++;
                 if (counter === 4)
                   return {pos: start, type:winTypes.horizontal};
               }
             }
           }
         }

         return null
  }


  function isVerticalWin(withBoard) {

        const { rows } = boardSettings;
        const { columns } = boardSettings;

         const { empty } = boardSettings.colors;

         for (var column = 0; column < columns; column++) {

           for (var row = 0; row <= rows - 4; row++) {

             let start = getIndex(row, column);

             if (withBoard === undefined || withBoard[start] === empty) continue;

             var counter = 1;
             for (let k = row + 1; k < row + 4; k++) {
               if (withBoard[getIndex(k, column)] === withBoard[start]) {
                 counter++;
                 if (counter === 4)
                   return {pos:start, type:winTypes.vertical};
               }
             }
           }
         }

         return null
  }

  function isBackwardsDiagonalWin(withBoard) {
    const { rows } = boardSettings;
    const { columns } = boardSettings;

    const { empty } = boardSettings.colors;

    for (let row = 0; row <= rows - 4; row++) {

      for (let column = 0; column <= columns - 4; column++) {

        let start = getIndex(row, column);
        if (withBoard === undefined || withBoard[start] === empty) continue;

        let counter = 1;
        for (let i = 1; i < 4; i++) {
          if (withBoard[getIndex(row + i, column + i)] === withBoard[start]) {
            counter++;
            if (counter === 4)
              return {pos:start, type:winTypes.backwardsDiagonal};
            }
          }
        }
      }

      return null
  }

  function isForwardsDiagonalWin(withBoard) {
    const { rows } = boardSettings;
    const { columns } = boardSettings;
    const { empty } = boardSettings.colors;


         for (let row = 0; row <= rows - 4; row++) {
           for (let column = 3; column <= columns; column++) {
             let start = getIndex(row, column);
             if (withBoard === undefined || withBoard[start] === empty) continue;
             let counter = 1;
             for (let i = 1; i < 4; i++) {
               if (withBoard[getIndex(row + i, column - i)] === withBoard[start]) {
                 counter++;
                 if (counter === 4)
                   return {pos:start, type:winTypes.forwardsDiagonal};
               }
             }
           }
         }

         return null
  }



  function createDropButtons() {
    const btns = [];
    for (let i = 0; i < boardSettings.columns; i++) {
      btns.push(
        <button
          key={i}
          className="cell drop-button"
          onClick={() => handleDrop(i)}
          style={{
            cursor:'pointer',
            backgroundColor: currentPlayer
          }}
        />
      );
    }
    return btns;
  }



  function getCells(){

    var cells = []
    var i = 0
    if(board === undefined){
        return cells
    }

    for(const c of board){

       cells.push(<button key={"c" + i} className="cell board-block"
             style={{
               backgroundColor: c
             }}
           />)
          i += 1
     }
    return cells
  }


  function getGridTemplateColumns() {
    let gridTemplateColumns = "";
    for (let i = 0; i < boardSettings.columns; i++) {
      gridTemplateColumns += "auto ";
    }
    return gridTemplateColumns;
  }


  function handleMove(props){

      onMove(props)

  }

  return (
    <>
      <div
        className={`board ${
          currentPlayer === boardSettings.colors.p1 ? "p1-turn" : "p2-turn"
        } `}
        ref={domBoard}
        style={{
          gridTemplateColumns: getGridTemplateColumns()
        }}
      >
        {createDropButtons()}
        {getCells()}
      </div>


    </>
  );
}
