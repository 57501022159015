


function Account(data){
    let account = {

    address : "",
    appt : "",
    balance : 0,
    city : "",
    mailingName : "",
    state : "",
    zip : "",
    uid : ""

}
    
  if(data !== undefined){

    account.address = data.address
    account.appt = data.appt
    account.balance = data.balance
    account.city = data.city
    account.mailingName = data.mailingName
    account.state = data.state
    account.zip = data.zip
    account.uid = data.uid
      
  }
    
    return account;

};


export default Account;