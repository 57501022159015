//import firebase from "firebase";
import Game from "./Models/Game";

import {initializeApp} from "firebase/app";
import {getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, updateEmail} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getMessaging, getToken, onMessage  } from "firebase/messaging";
import UserSingleton from "./UserSingleton"
import GameModel from './Models/Game'

import  { enableIndexedDbPersistence, getFirestore, addDoc, collection, getDocs, getDoc, doc, query, where, deleteDoc, updateDoc, setDoc, orderBy, limit } from "firebase/firestore"


//PRODUCTION
//const firebaseConfig = {
//  apiKey: "AIzaSyAXlHr3KjOTk8f6zZvk5ldLRun-YkeAxSo",
//  authDomain: "betcha-1b52b.firebaseapp.com",
//  databaseURL: "https://betcha-1b52b.firebaseio.com",
//  projectId: "betcha-1b52b",
//  storageBucket: "betcha-1b52b.appspot.com",
//  messagingSenderId: "456429218969",
//  appId: "1:456429218969:web:1c2322fe1239a58edadc08",
//  measurementId: "G-Y7WR0D9FMK"
//};

//DEVELOPEMENT SERVER
const firebaseConfig = {
  apiKey: "AIzaSyA4m5nINI-gJwCCpAaSMkHd6MidK6pP_K0",
  authDomain: "pony-up---dev.firebaseapp.com",
  databaseURL: "https://pony-up---dev.firebaseio.com",
  projectId: "pony-up---dev",
  storageBucket: "pony-up---dev.appspot.com",
  messagingSenderId: "984642794864",
  appId: "1:984642794864:web:54e5b1cfd42e7f98ea8bb5",
  measurementId: "G-X0JPQQLG4S"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
enableIndexedDbPersistence(db)

const functions = getFunctions(app);
const auth = getAuth(app);

function isSafari() {
  return (navigator.vendor.match(/apple/i) || "").length > 0
}


var messaging;
const initMessaging = () =>{
    try{
        messaging = getMessaging(app);
    }catch (err){
        console.log(err)
    }
}



// window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Ld679YfAAAAAAHMaLjGm42lHXjOuIvLnWk9_s51'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});




export const onMessageListener = () =>

      new Promise((resolve) => {
        onMessage(messaging, (payload) => {
          resolve(payload);
        });
      });



const instance = UserSingleton.getInstance()

let acceptedStates = ["Alabama","Alaska","California","Colorado","District of Columbia","Florida","Georgia","Hawaii","Idaho","Illinois","Indiana","Iowa","Kansas","Kentucky","Maine","Maryland","Massachusetts","Michigan","Minnesota","Mississippi","Missouri","Nebraska","Nevada","New Hampshire","New Jersey","New Mexico","New York","North Carolina","North Dakota","Ohio","Oklahoma","Oregon","Pennsylvania","Rhode Island","Texas","Utah","Vermont","Virginia","Washington","West Virginia","Wisconsin","Wyoming"]

//State Abbreviations where money bets are acceptable
let acceptedStateAbbrev = [ "AL", "AK", "AS", "CA", "CO", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "ME", "MH", "MD", "MA", "MI", "MN", "MS", "MO", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND",  "OH", "OK", "OR", "PA", "RI", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY" ]




const signInWith = async (email, password) => {
    try {
        let userCredential = signInWithEmailAndPassword(auth, email, password);

        const user = userCredential.user;

        return{success:true}

    } catch (err) {
        console.error(err);
        alert(err.message);
        return{success:false, message:err.message}

    }
};



const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        await writeNewUser(user, 'email', name)

    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};




const sendResetEmail = async (email) => {
    try {
        let auth = getAuth()

        await sendPasswordResetEmail(auth, email);
        return {success:true}
    } catch (err) {
        console.error(err);
        return {success:false, message:err.message}
    }
};



const updateUserEmail = async (email, uid) => {
    try {

        let auth = getAuth()
        await updateEmail(auth.currentUser, email);

        const updatePath = doc(db, usersPath, uid );

        let updates = {email:email}

       await updateDoc(updatePath, updates)

        return {success:true}

    } catch (err) {
        console.error(err);
        return {success:false, message:err.message}

    }
};



const createSearchables = (handle, name) => {

    var searchables = []
    searchables.push(handle)
    searchables.push(name)

    var temp_name = ""
    for (let i = 0; i < handle.length; i++) {
        console.log(handle[i]);

        temp_name = temp_name + handle[i]
        searchables.push(temp_name)
    }


    var temp_handle = ""
    for (let i = 0; i < name.length; i++) {
        console.log(name[i]);

        temp_handle = temp_handle + name[i]
        searchables.push(temp_handle)
    }

    return searchables
}




const getFCMToken = async() =>{

    const vapid_key = 'BHpYW895pDzidsL-B9pXradTNChhPExRjEue8WDDVAb12aw7zm4X-whi66r5GwgvvbPGoslqhHMAXbe9PLOlsWw'

    try{
        let currentToken = await getToken(messaging, { vapidKey: vapid_key })
        if (currentToken) {
            console.log('current token for client: ', currentToken);

            return currentToken

        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            return "request"
        }

    }catch(err) {
            console.log('An error occurred while retrieving token. ', err);
            return "error"


    }
}


const setToken = async(token, uid) => {

    try {
        const updatePath = doc(db, usersPath, uid);

        let updates = {token:token}

        await updateDoc(updatePath, updates)

    }catch(err) {
            console.log('An error occurred while retrieving token. ', err);
            return "error"
    }


}



const usernameAvailable = async(handle) =>{

    console.log("CHECHING HANDLE", handle)

    const handleRef = collection(db, "Handles");

    // Create a query against the collection.
    const q = query(handleRef, where('handle', '==', handle));

    var count = 0;

    try{

        const querySnapshot = await getDocs(q)

        querySnapshot.forEach(doc => {
            console.log(doc.id)
            count = count + 1;
        });


    }catch(err) {
        console.log('An error occurred while retrieving handle. ', err);
        return false
    }



    return count < 1
}




    const getArchivedGame = async(gameId) => {

        const gameRef = doc(db, "Archives", gameId);
        let document = await getDoc(gameRef)

        if(document === undefined || document.data() === undefined){
           return false
        }

        let gameData = document.data()
        let game = new GameModel(gameData)

        if(game){
            return game
        }else{
            return false
        }

    }





const declineChallenge = async(challenge) =>{

    let id = challenge.id

    const toDel = doc(db, allChallengesPath, id);

    let declined = await deleteDoc(toDel)

    if(!declined){

       return false
    }else{

        return true
    }

}


//returns the accepted challenge's game Id
const acceptChallenge = async(challenge, myProfile) =>{


    let id = challenge.id

    const updatePath = doc(db, allChallengesPath, id);

    let updates = {accepted:true, opponentHandle:myProfile.handle, opponentUid:myProfile.uid, opponentImage: myProfile.image}

    try{
        let accepted = await updateDoc(updatePath, updates)

    }catch(err) {
        console.log('An error occurred while retrieving handle. ', err);
        return false
    }


    return true
}




 const getFriends = async(user) =>{

    if(user === undefined){
        return
    }

    let friendsList = user.friends

    if(friendsList === undefined){
        return []
    }
    console.log(friendsList)


    var users = []
    for(const uid of friendsList){

        let user = await instance.getUserDataFor(uid)
        users.push(user)
    }

    console.log("All FRIENDS: ", users)

    return users

}



 const addFriendRequest = async(friendUid, user) =>{

        let request = {

            requestee:friendUid,
            requestor:user.uid,
            accepted:false,
        }

        const frPath = doc(collection(db, friendRequestPath));

        let key = frPath.id

        request.id = key

        await setDoc(frPath, request)

        return request
}




const acceptFriendRequest = async(request) =>{

    const path = doc(db, friendRequestPath, request.id);

    let accept = await updateDoc(path, {accepted:true})


    if(!accept){
       return true
    }

    return false

}


const declineFriendRequest = async(request) =>{

    const path = doc(db, friendRequestPath, request.id);

    let update = await deleteDoc(path)


    if(!update){
       return true
    }

    return false

}



const removeFriend = async(friendUid, user) =>{


    var friends = user.friends

    friends = friends.filter(v => v !== friendUid);


    user.friends = friends

    const path = doc(db, usersPath, user.uid);

    await updateDoc(path, {friends:friends})


    return user
}



const getDocIdAndUpdateUserHandle = async (handle, name, uid) => {

    let searchables = createSearchables(handle, name)

    var handleUpdates = {searchables:searchables, name:name, handle:handle}
    var userUpdates = {name:name, handle:handle}


    var docId = ""
    const handleRef = collection(db, "Handles");

    const q = query(handleRef, where('handle', '==', handle), limit(1));

    let qSnap = await getDocs(q)

    qSnap.forEach(doc =>{
        console.log("DOC ID RETRIEVED: ", doc.id)
        docId = doc.id
    })

    console.log("Handle Doc ID: ", docId)

    let usersPath = doc(db, usersPath, uid)
    await updateDoc(usersPath, userUpdates)

    if(docId !== ""){
        let handlePath = doc(db, handlePath, docId)
        await updateDoc(handlePath, handleUpdates)
    }


    return docId

}



const writeNewUser = async (user, provider, name) =>{

    console.log("Writing new User: ",  user.uid, user.email, name, provider)
    let timestamp = new Date()

    var newUser = {
        deviceOs:"web",
        friends:[],
        handle:"",
        lastLogin: timestamp,
        losses:0,
        wins:0,
        totalGamesPlayed:0,
        token:"",
        image:"",
        sellDataOptOout:false,
        saveLocationHistory:false,
        showFriendsBets:true,
        showWorldBets:true,
        name: name,
        email: user.email,
        uid: user.uid
    }

    var newAccount = {

        address: "",
        appt: "",
        balance: 0,
        city: "",
        credits: "",
        mailingName: "",
        state: "",
        uid: user.uid,
        zip: ""
    }

    var newHandle = {

        handle: "",
        image: "",
        name: 0,
        searchables: [],
        uid:user.uid
    }

    let usersPath = doc(db, usersPath, user.uid)
    await setDoc(usersPath, newUser)

    let handlePath = doc(collection(db, handlePath))
    await setDoc(handlePath, newHandle)

    let accountPath = doc(db, accountsPath, user.uid)
    await setDoc(accountPath, newAccount)

}


    const submitChallenge = async(challenge) =>{

        if(challenge === undefined || challenge === ""){
            return "Challenge is undefined"
        }

        const sendChallenge = httpsCallable(functions, 'sendChallenge');

        let result = await sendChallenge(challenge)
        const data = result.data;

        console.log(challenge)
        console.log("Success", data.success)
        return data
    }



    const getPayPalClientId = async() =>{

        const getppId = httpsCallable(functions, 'getPayPalClientId');

        let result = await getppId()
        const data = result.data;

        return data.client_id
    }


    const transferFunds = async(tData) =>{


        if(tData.uid === undefined || tData.uid === "" || tData.amount === undefined || tData.amount === "" || tData.mailingName === undefined || tData.mailingName === "" || tData.address === undefined || tData.address === "" || tData.city === undefined || tData.city === "" || tData.state === undefined || tData.state === "" || tData.zip === undefined || tData.zip === ""){
            return {success:false, message:"Something is missing, ensure all necassary fields are completed"}
        }


        const sendCheck = httpsCallable(functions, 'transferFunds');

        let result = await sendCheck(tData)
        const data = result.data;

        console.log("Success", data.success)
        return data
    }

    const addFunds = async(tData) =>{


        if(tData.uid === undefined || tData.uid === "" || tData.amount === undefined || tData.amount === "" ){
            return "Something is undefined"
        }


        const addMoney = httpsCallable(functions, 'addFunds');

        let result = await addMoney(tData)
        const data = result.data;

        console.log("Success", data.success)
        return data.success
    }


    const postTrashTalk = async(postData) =>{

        if(postData.senderId === undefined || postData.senderId === "" || postData.receiverId === undefined || postData.receiverId === "" || postData.gameId === undefined || postData.gameId === "" || postData.message === undefined || postData.message === ""){
            return "Somtehing is undefined"
        }

        const postTrashTalk = httpsCallable(functions, 'postTrashTalk');

        let result = await postTrashTalk(postData)
        const data = result.data;

        return data
    }

    const getLocationData = async(ipAddress) =>{

        if(ipAddress === undefined || ipAddress === "" ){
            return "ipAddress is undefined"
        }

        const getLocation = httpsCallable(functions, 'getLocationData');

        let postData = {ipAddress:ipAddress}
        let result = await getLocation(postData)
        const data = result.data;

        return data
    }


    const logout = async() =>{

        signOut(auth).then(() => {
      // Sign-out successful.
            return true
        }).catch((error) => {
          // An error happened.
            return false;
        });

    }


    /*
        LOAD Games
    */
    const startQuery = async (friends, userData) => {

        console.log("Gettings Friends? ", friends)

        const friendRequestRef = collection(db, archivesPath);


        const friendQuery = query(friendRequestRef, orderBy('gameOverTimestamp', 'desc'),limit(50));
        const worldQuery = query(friendRequestRef, orderBy('gameOverTimestamp', 'desc'), limit(50));


        const query = friends ? friendQuery : worldQuery

        let querySnapshot = await getDocs(query)

        console.log("Gettings Friends? ", friends)
        console.log("UserData ", userData)


        var recentGames = []

        querySnapshot.forEach(doc => {

            let data = doc.data()
            let id = doc.id

            if(friends){

                if(userData !== undefined && userData.friends !== undefined && (userData.friends.includes(data.challengerUid) || userData.friends.includes(data.opponentUid))){

                    recentGames.push(data)
                }
            }else{
                //world query
                recentGames.push(data)
            }

        }, err => {
            console.log(`Recent Games Query: ${err}`);
        });


        return recentGames

    }


    //These are the games that can show up in ongoing games from Profile
    const resumableGameTypes =() =>{
        return ['1','2','3','4','5','6','7']
    }

    //These are the games that can show up in ongoing games from Profile
    const allGameTypes =() =>{
        return ['1','2','3','4','5','6','7']
    }

    //Returns the games with turn timers
    const gamesWithTurnTimers = () =>{
        return ['3','5','6']
    }

    //These are the games that can show up in ongoing games from Profile
    const viewableEndGames =() =>{
        return ['2','3','5','6','7']
    }

    //These are the games that can show up in ongoing games from Profile
    const gameDataContainsTimestamps = () => {
        return ['7']
    }

    //Returns all game paths in a static array of the collection names
    const allGamePaths = () => {

        return [chessGamesPath, checkersGamesPath,triviaGamesPath,snowmanGamesPath,xsosGamesPath,connect4GamesPath,boggleGamesPath]
    }

    const currentPlayableGameTypes = () =>{

        return [1,2,3,4,5,6,7]
    }


    //CONSTANTS
    const usersPath = "Users"
    const allChallengesPath = "AllChallenges"
    const handlesPath = "Handles"
    const friendRequestPath = "FriendRequests"
    const accountsPath = "Accounts"
    const archivesPath = "Archives"
    const supportPath = "SupportRequests"
    const simpleNotificationsPath = "SimpleNotifications"
    const transactionsPath = "Transactions"
    const ledgersPath = "Ledgers"
    const chessGamesPath = "ChessGames"
    const checkersGamesPath = "CheckersGames"
    const triviaGamesPath = "TriviaGames"
    const snowmanGamesPath = "SnowmanGames"
    const xsosGamesPath = "TicTacToeGames"
    const connect4GamesPath = "Connect4Games"
    const boggleGamesPath = "BoggleGames"
    const locationHistoryPath = "LocationHistory"




    const gamePathForType = (type) => {
        var path;

        switch (type) {
        case 0:

            break
        case 1:
            path = snowmanGamesPath
            break

        case 2:
            path = xsosGamesPath
            break

        case 3:
            path = connect4GamesPath
            break
        case 4:

            path = boggleGamesPath
            break

        case 5:
            path = checkersGamesPath
            break
        case 6:
            path = chessGamesPath
            break

        case 7:
            path = triviaGamesPath
            break

        default:

            break
        }

        return path
    }


    const gameUrlArchivePathForType = (type, gameId) =>{

        var path = ""
        if(type === 7 || type === '7' ){
            path = '/trivia?id=' + gameId + '&archived=true'

         }else if(type === 6 || type === '6'){
            path = '/chess?id=' + gameId + '&archived=true'

         }else if (type === 5 || type === '5'){

             path = '/checkers?id=' + gameId + '&archived=true'

        }else if (type === 4 || type === '4'){
            path = '/wordup?id=' + gameId + '&archived=true'

        }else if (type === 3 || type === '3'){
            path = '/match4?id=' + gameId + '&archived=true'

        }else if (type === 2 || type === '2'){
            path = '/xsos?id=' + gameId + '&archived=true'

        }else if (type === 1 || type === '1'){
            path = '/snowman?id=' + gameId + '&archived=true'

        }

        return path
    }


    const gameUrlPathForType = (type, gameId) =>{

        var path = ""
        if(type === 7  || type === '7' ){
            path = '/trivia?id=' + gameId

         }else if(type === 6 || type === '6'){
            path = '/chess?id=' + gameId

         }else if (type === 5 || type === '5'){

            path = '/checkers?id=' + gameId

        }else if (type === 4 || type === '4'){
            path = '/wordup?id=' + gameId

        }else if (type === 3 || type === '3'){
            path = '/match4?id=' + gameId

        }else if (type === 2 || type === '2'){
            path = '/xsos?id=' + gameId

        }else if (type === 1 || type === '1'){
            path = '/snowman?id=' + gameId

        }

        return path
    }


export {
    currentPlayableGameTypes,
    gameUrlArchivePathForType,
    gameUrlPathForType,
    usersPath,
    allChallengesPath,
    getLocationData,
    handlesPath,
    updateUserEmail,
    transferFunds,
    addFunds,
    friendRequestPath,
    accountsPath,
    archivesPath,
    supportPath,
    simpleNotificationsPath,
    transactionsPath,
    ledgersPath,
    locationHistoryPath,
    appCheck,
    auth,
    db,
    signInWith,
    registerWithEmailAndPassword,
    sendResetEmail,
    logout,
    usernameAvailable,
    getDocIdAndUpdateUserHandle,
    gamePathForType,
    allGamePaths,
    resumableGameTypes,
    allGameTypes,
    gamesWithTurnTimers,
    viewableEndGames,
    gameDataContainsTimestamps,
    acceptedStateAbbrev,
    acceptedStates,
    submitChallenge,
    addFriendRequest,
    removeFriend,
    getFriends,
    acceptFriendRequest,
    declineFriendRequest,
    acceptChallenge,
    declineChallenge,
    startQuery,
    getFCMToken,
    postTrashTalk,
    getPayPalClientId,
    getArchivedGame
};
