import React, { Component, useState, useEffect } from 'react'
import './XsOs.css'

import Square from './Square'


//game board calls row for each item in the board array
export default function XOBoard({board, turn, currentUser, currentPlayerLetter, turnCallback}) {

    const [boardData, setBoardData] = useState(board)
    const [activeTurn, setActiveTurn] = useState(turn)
    const [currentLetter, setCurrentLetter] = useState(currentPlayerLetter)

    useEffect(() =>{

        setBoardData(board)
        setActiveTurn(turn)
        setCurrentLetter(currentPlayerLetter)

    }, [board, turn, currentPlayerLetter]);



    const moveCallback = (pos) =>{


        if(board[pos] !== ''){
            return
        }

        if(!canMove()) return

        var newBoard = boardData
        newBoard[pos] = turn

        let winState = checkWinState(newBoard)

        console.log('WinState:', winState)

        var winner = '';
        if(winState === 'winner'){
            winner = currentUser.uid
        }

        let data = {board: newBoard, letter: turn, winState:winState, winner:winner}

        turnCallback(data)
    }




    function canMove(){


      let letter = currentLetter

       if(activeTurn === letter){
            return true
       }

        console.log("Turn: ", activeTurn, " Player: ", letter)

        return false
    }




//board positions
//    [0,1,2]
//    [3,4,5]
//    [6,7,8]

    function checkWinState(newBoard){

        if(newBoard[0] === newBoard[1] && newBoard[1] === newBoard[2] && newBoard[0] !== ''){

            //win top row
            return 'winner'
        }else if(newBoard[3] === newBoard[4] && newBoard[4] === newBoard[5] && newBoard[3] !== ''){

            //win middle row
            return 'winner'

        }else if(newBoard[6] === newBoard[7] && newBoard[7] === newBoard[8] && newBoard[6] !== ''){

            //win bottom row
           return 'winner'

        }else if(newBoard[0] === newBoard[3] && newBoard[3] === newBoard[6] && newBoard[0] !== ''){

            //win left collumn
            return 'winner'

        }else if(newBoard[1] === newBoard[4] && newBoard[4] === newBoard[7] && newBoard[1] !== ''){

            //win middle collumn
           return 'winner'

        }else if(newBoard[2] === newBoard[5] && newBoard[5] === newBoard[8] && newBoard[2] !== ''){

            //win right collumn
            return 'winner'

        }else if(newBoard[0] === newBoard[4] && newBoard[4] === newBoard[8] && newBoard[0] !== ''){

            //win diagonal top left to bottom right
            return 'winner'

        }else if(newBoard[6] === newBoard[4] && newBoard[4] === newBoard[2] && newBoard[6] !== ''){

            //win diagonal bottom left to top right
            return 'winner'

        }


        let openPosition = false;
        for(const pos of newBoard){

            if (pos === ''){

                openPosition = true;
                break;
            }

        }


        if(!openPosition){
            return 'full'
        }


        return 'none'

    }


    var rowIndex;
    return (
			<div className="container">
				<div className={'board'}>

                      <div className="board-row">
                        <Square value={boardData[0]} onClick={() => moveCallback(0)}/>
                        <Square value={boardData[1]} onClick={() => moveCallback(1)}/>
                        <Square value={boardData[2]} onClick={() => moveCallback(2)}/>
                      </div>
                      <div className="board-row">
                        <Square value={boardData[3]} onClick={() => moveCallback(3)}/>
                        <Square value={boardData[4]} onClick={() => moveCallback(4)}/>
                        <Square value={boardData[5]} onClick={() => moveCallback(5)}/>
                      </div>
                      <div className="board-row">
                        <Square value={boardData[6]} onClick={() => moveCallback(6)}/>
                        <Square value={boardData[7]} onClick={() => moveCallback(7)}/>
                        <Square value={boardData[8]} onClick={() => moveCallback(8)}/>
                      </div>

				</div>

			</div>
		);

};
